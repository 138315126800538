import React, { useState, useEffect } from 'react'
import { Input, Button, notification, Card, Spin, Radio, Checkbox, Modal, Table } from 'antd'
import { format, parseISO, addHours } from 'date-fns'
import { useSelector } from 'react-redux'
import { getUsuarioLogado } from '../../auth/AuthStore'
import { IStateRedux } from '../../../common/ReduxStore'
import { useHistory, useParams } from 'react-router-dom'
import {
  InformaPagamentoCartaoProvider,
  useInformaPagamentoCartaoState,
  useInformaPagamentoCartaoDispatch,
  informaPagamentoCartao,
  getInfoBoleto,
} from './InformaPagamentoCartaoStore'
import styled from '@emotion/styled'
import IntlNumberInput from 'react-intl-number-input'
import { Show } from '../../../common/Show'
import {
  HourglassOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons'
import { TextFieldInput } from '../../../common/TextField'

const InformaPagamentoCartao = () => {
  const usuarioPossuiPermissao = useValidaPermissao()
  if (!usuarioPossuiPermissao) return null

  return (
    <InformaPagamentoCartaoProvider>
      <Styles.Container>
        <BotaoVoltar />
        <Card title="Informar pagamento via Cartão (crédito ou débito)">
          <Placa />
          <InfoPagamentoPendente />
          <FormularioAutorizacao />
          <StatusPagamento />
          <BotaoInformaAtualizaPagamento />
        </Card>
      </Styles.Container>
      <InformaFeedback />
      <MultiplosPedidosPlaca />
    </InformaPagamentoCartaoProvider>
  )
}

const BotaoVoltar = () => {
  const history = useHistory()
  return (
    <div style={{ marginBottom: 12 }}>
      <Button onClick={() => history.replace('/pagamentos-cartao')}>
        <LeftOutlined /> Voltar para a Lista
      </Button>
    </div>
  )
}
const useValidaPermissao = () => {
  const usuario = useSelector(getUsuarioLogado)
  const isUsuarioHipodromo = useSelector((s: IStateRedux) => s.auth.isUsuarioHipodromo)
  const history = useHistory()
  console.log({ usuario, isUsuarioHipodromo })
  if (!isUsuarioHipodromo && !usuario?.Admin) {
    history.replace('/')
    notification.error({ message: 'Você não tem acesso à essa tela' })
    return false
  }
  return true
}

const Placa = () => {
  const { placa: placaUrlParam } = useParams<{ placa }>()
  const dispatch = useInformaPagamentoCartaoDispatch()
  const state = useInformaPagamentoCartaoState()
  const { formulario } = state
  const { placa } = formulario
  useEffect(() => {
    if (placaUrlParam) {
      setTimeout(() => {
        const newFormulario = { ...formulario, placa: placaUrlParam }
        dispatch({ type: 'alteraFormulario', formulario: newFormulario })
      })
    }
  }, [placaUrlParam])

  function handleChange(e) {
    const newFormulario = { ...formulario, placa: e.target.value }
    dispatch({ type: 'alteraFormulario', formulario: newFormulario })
  }

  return (
    <label>
      Placa
      <Input value={placa} onChange={handleChange} />
    </label>
  )
}

const FormularioAutorizacao = () => {
  const state = useInformaPagamentoCartaoState()
  const { formulario, boleto } = state
  const { autorizacao, tipo, novoValor, data } = formulario
  const dispatch = useInformaPagamentoCartaoDispatch()
  const [changeValor, setChangeValor] = useState(false)
  const { RadioGroup } = FormularioAutorizacao

  function alteraFormulario(e, campo) {
    const newFormulario = { ...formulario, [campo]: e }
    dispatch({ type: 'alteraFormulario', formulario: newFormulario })
  }

  useEffect(() => {
    setChangeValor(false)
  }, [boleto])

  if (!boleto) return null

  return (
    <div>
      <Card title="Dados da autorização" size="small" style={{ margin: '24px 0' }}>
        <div>
          <Checkbox checked={changeValor} onChange={() => setChangeValor(!changeValor)}>
            Alterar <strong>valor</strong> do pagamento
          </Checkbox>
          {!!changeValor && (
            <label>
              Novo Valor
              <IntlNumberInput
                size="large"
                value={novoValor}
                onChange={(e) => {
                  const valor = +e.target.value.replace(/[R$ .]/g, '').replace(',', '.')
                  alteraFormulario(valor, 'novoValor')
                }}
                placeholder="Valor do serviço"
                locale="pt-BR"
                prefix="R$ "
                precision={2}
                className="ant-input"
              />
            </label>
          )}
        </div>

        <div>
          <label style={{ margin: '12px 0 0 0' }}> Tipo </label>
          <div>
            <RadioGroup
              value={tipo}
              onChange={(e) => alteraFormulario(e.target.value, 'tipo')}
              buttonStyle="solid"
            >
              <Radio.Button value="CREDITO">Crédito</Radio.Button>
              <Radio.Button value="DEBITO">Débito</Radio.Button>
            </RadioGroup>
          </div>
        </div>

        <label>
          Número da autorização
          <Input
            value={autorizacao}
            onChange={(e) => alteraFormulario(e.target.value, 'autorizacao')}
          />
        </label>

        <label>
          Data do pagamento
          <TextFieldInput
            value={data}
            mask="99/99/9999"
            onChange={(e) => alteraFormulario(e.target.value, 'data')}
          />
        </label>
      </Card>
    </div>
  )
}

FormularioAutorizacao.RadioGroup = styled(Radio.Group)`
  display: flex;
  & > label {
    margin: 0;
  }
`

const BotaoInformaAtualizaPagamento = () => {
  const state = useInformaPagamentoCartaoState()
  const dispatch = useInformaPagamentoCartaoDispatch()
  const usuario = useSelector(getUsuarioLogado)
  const { pedido, loading } = state

  return (
    <div style={{ textAlign: 'right' }}>
      <Button
        onClick={async () => await informaPagamentoCartao(state, dispatch, usuario.Id)}
        loading={loading}
        type="primary"
      >
        {pedido?.StatusPagamentoCartao ? 'Atualizar' : 'Informar'} Pagamento
      </Button>
    </div>
  )
}

const StatusPagamento = () => {
  const { pedido } = useInformaPagamentoCartaoState()

  if (!pedido?.StatusPagamentoCartao) return null

  const descStatus = {
    PENDENTE: 'Pagamento Pendente!',
    APROVADO: 'Pagamento Aprovado!',
    NEGADO: 'Pagamento Negado!',
  }[pedido.StatusPagamentoCartao]

  const title = {
    PENDENTE: (
      <>
        <HourglassOutlined style={{ color: 'orange' }} /> Status do Pagamento
      </>
    ),
    APROVADO: (
      <>
        <CheckCircleOutlined style={{ color: 'green' }} /> Status do Pagamento
      </>
    ),
    NEGADO: (
      <>
        <ExclamationCircleOutlined style={{ color: '#ff0000' }} /> Status do Pagamento
      </>
    ),
  }[pedido.StatusPagamentoCartao]

  return (
    <Card title={title} style={{ margin: '24px 0' }} size="small">
      <div> {descStatus} </div>
      <Show condition={pedido.StatusPagamentoCartao === 'NEGADO'}>
        Motivo:
        <br />
        <strong>{pedido.MotivoNegacaoPagamentoCartao}</strong>
      </Show>
    </Card>
  )
}

function InfoPagamentoPendente() {
  const dispatch = useInformaPagamentoCartaoDispatch()
  const {
    boleto,
    loadingInfoBoleto,
    formulario: { placa },
  } = useInformaPagamentoCartaoState()
  const possuiPlaca = placa && placa.length === 7

  useEffect(() => {
    if (possuiPlaca) void getInfoBoleto(placa, dispatch)
  }, [possuiPlaca, placa])

  if (loadingInfoBoleto) return <Spin />

  if (boleto) {
    return (
      <Card title="Dados do pagamento" size="small">
        <div>
          Solicitante:{' '}
          <strong>
            {boleto.Aluno.CPF} - {boleto.Aluno.Nome}
          </strong>
        </div>
        <div>
          Valor: <strong>{boleto.Valor.toFixed(2).replace('.', ',')}</strong>
        </div>
        <div>
          Data:{' '}
          <strong>{format(addHours(parseISO(boleto.DataHora), 3), 'dd/MM/yyyy HH:mm')}</strong>
        </div>
      </Card>
    )
  }

  if (possuiPlaca) {
    return (
      <Card title="Dados do pagamento" size="small">
        Nenhum pagamento em aberto encontrado para essa placa
      </Card>
    )
  }

  return null
}

const InformaFeedback = () => {
  const { feedback } = useInformaPagamentoCartaoState()
  const dispatch = useInformaPagamentoCartaoDispatch()
  useEffect(() => {
    if (feedback) {
      notification.open({
        ...feedback,
        onClose: () => dispatch({ type: 'limpaFeedback' }),
      })
    }
  }, [feedback])
  return null
}

const MultiplosPedidosPlaca = () => {
  const { pedidos } = useInformaPagamentoCartaoState()
  const dispatch = useInformaPagamentoCartaoDispatch()
  return (
    <Modal visible={!!pedidos.length} title="Selecione o pedido" width={720}>
      <h3>Existe mais de um pedido relacionado à placa informada</h3>
      <Table
        columns={[
          {
            title: 'Data',
            render: (record) => format(parseISO(record.Boleto.DataHora), 'dd/MM/yyyy hh:mm'),
          },
          {
            title: 'Cliente',
            render: (record) => (
              <a onClick={(e) => e.preventDefault()}>{record.Boleto.Aluno.Nome}</a>
            ),
          },
          { title: 'Valor', render: (record) => record.Boleto.Valor.toFixed(2).replace('.', ',') },
          { title: 'Status', render: (record) => record.StatusPagamentoCartao },
        ]}
        onRow={(record) => {
          return {
            onClick: () => {
              dispatch({ type: 'getInfoBoletoSuccess', pedido: record })
            },
          }
        }}
        dataSource={pedidos}
      />
    </Modal>
  )
}

const Styles = {
  Container: styled('div')`
    margin: 24px auto;
    width: 600px;
    label {
      display: block;
      margin: 12px 0;
    }
  `,
}

export { InformaPagamentoCartao }
