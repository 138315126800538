import React, { useEffect } from 'react'
import Axios from 'axios'
import { notification } from 'antd'
import { Pedido } from '../../pedidos/Pedido'
import { format, parseISO } from 'date-fns'

interface feedbackType {
  type: 'error' | 'success'
  message: string
}

const initialState = {
  boleto: null as any,
  pedidos: [] as Pedido[],
  pedido: null as Pedido | null,
  loading: false,
  loadingInfoBoleto: false,
  formulario: {
    placa: '',
    autorizacao: '',
    tipo: 'DEBITO' as 'DEBITO' | 'CREDITO',
    novoValor: null as null | number,
    data: '',
  },
  feedback: null as feedbackType | null,
}

type ACTIONS =
  | { type: 'alteraFormulario'; formulario }
  | { type: 'feedback'; feedback: feedbackType }
  | { type: 'informaPagamentoLoading' }
  | { type: 'informaPagamentoSuccess' }
  | { type: 'informaPagamentoError'; errorMessage }
  | { type: 'limpaFeedback' }
  | { type: 'getInfoBoletoStarted' }
  | { type: 'getInfoBoletoSuccess'; pedido: Pedido }
  | { type: 'getInfoBoletoSuccessMultiplosPedidos'; pedidos: Pedido[] }
  | { type: 'getInfoBoletoError' }

type IState = typeof initialState
type IDispatch = (p: ACTIONS) => void

function reducerFn(state: IState, action: ACTIONS): IState {
  const data = format(new Date(), 'dd/MM/yyyy')
  switch (action.type) {
    case 'alteraFormulario': {
      console.trace()
      return {
        ...state,
        formulario: action.formulario,
      }
    }
    case 'feedback':
      return {
        ...state,
        feedback: action.feedback,
      }
    case 'informaPagamentoLoading':
      return {
        ...state,
        loading: true,
        feedback: null,
      }
    case 'informaPagamentoSuccess':
      return {
        ...initialState,
        feedback: { type: 'success', message: 'Pagamento informado com sucesso' },
      }
    case 'informaPagamentoError':
      return {
        ...state,
        loading: false,
        feedback: { type: 'error', message: action.errorMessage },
      }
    case 'getInfoBoletoSuccess':
      return {
        ...state,
        boleto: action.pedido.Boleto,
        pedido: action.pedido,
        pedidos: [],
        formulario: {
          ...state.formulario,
          tipo: action.pedido.TipoPagamentoCartao || 'DEBITO',
          autorizacao: action.pedido.AutorizacaoPagamentoCartao || '',
          novoValor: action.pedido.Boleto.Valor,
          data: action.pedido.DataPagamentoCartao
            ? format(parseISO(action.pedido.DataPagamentoCartao), 'dd/MM/yyyy')
            : data,
        },
        loadingInfoBoleto: false,
      }
    case 'getInfoBoletoSuccessMultiplosPedidos':
      return {
        ...state,
        pedidos: action.pedidos,
      }
    case 'getInfoBoletoError':
      return {
        ...state,
        loadingInfoBoleto: false,
        pedido: null,
      }
    case 'getInfoBoletoStarted':
      return {
        ...state,
        boleto: null,
        loadingInfoBoleto: true,
      }
    case 'limpaFeedback':
      return {
        ...state,
        feedback: null,
      }
    default:
      return state
  }
}

/** Funtions */

async function getInfoBoleto(placa, dispatch: IDispatch) {
  dispatch({ type: 'getInfoBoletoStarted' })
  try {
    const pedidos = await Axios.get(`/info-pagamento/${placa}`).then((x) => x?.data)
    if (!pedidos || !pedidos.length) {
      dispatch({ type: 'getInfoBoletoError' })
      return notification.error({ message: 'Nenhum pedido encontrado para essa placa' })
    }
    if (pedidos.length === 1) {
      dispatch({ type: 'getInfoBoletoSuccess', pedido: pedidos[0] })
    } else {
      dispatch({ type: 'getInfoBoletoSuccessMultiplosPedidos', pedidos })
    }
  } catch (error: any) {
    const message = error.response?.data?.message ?? 'Erro ao buscar os dados do pagamento'
    notification.error({ message })
    dispatch({ type: 'getInfoBoletoError' })
  }
}

async function informaPagamentoCartao(state: IState, dispatch: IDispatch, usuarioId) {
  const {
    boleto,
    formulario: { autorizacao, tipo, novoValor },
  } = state
  const data = new Date(state.formulario.data.split('/').reverse().join('-') + 'T00:00')
  if (!boleto) {
    return dispatch({
      type: 'feedback',
      feedback: {
        type: 'error',
        message:
          'É necessário informar uma placa vinculada a um pagamento em aberto antes de continuar',
      },
    })
  }

  dispatch({ type: 'informaPagamentoLoading' })

  try {
    await Axios.post('/efetua-baixa-cartao', {
      boletoId: boleto.Id,
      usuarioId: usuarioId,
      autorizacao,
      tipo,
      novoValor,
      data,
    })
    dispatch({ type: 'informaPagamentoSuccess' })
  } catch (error: any) {
    const errorMessage = error.response?.data?.message ?? 'Ocorreu um erro ao informar o pagamento'
    dispatch({ type: 'informaPagamentoError', errorMessage })
  }
}

/** Providers */
const StateContext = React.createContext(initialState)
const DispatchContext = React.createContext<IDispatch>(undefined as any)

function InformaPagamentoCartaoProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducerFn, initialState)

  useEffect(() => {
    // console.log({ state })
  }, [state])

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  )
}

function useInformaPagamentoCartaoState() {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error(
      'useInformaPagamentoCartaoState must be used within a InformaPagamentoCartaoProvider'
    )
  }
  return context
}

function useInformaPagamentoCartaoDispatch() {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error(
      'useInformaPagamentoCartaoDispatch must be used within a InformaPagamentoCartaoProvider'
    )
  }
  return context
}

const GetInformaPagamentoCartaoState = ({ children = (s: typeof state) => null as any }) => {
  const state = useInformaPagamentoCartaoState()
  return children(state)
}

export {
  InformaPagamentoCartaoProvider,
  GetInformaPagamentoCartaoState,
  useInformaPagamentoCartaoDispatch,
  useInformaPagamentoCartaoState,
  informaPagamentoCartao,
  getInfoBoleto,
}
