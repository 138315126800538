import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { emplacadorAtivoSelector } from '../contas/ContasStore'
import { useSelector } from 'react-redux'
import { Input, Button, notification } from 'antd'
import styled from '@emotion/styled'
import { getUsuarioLogado } from '../auth/AuthStore'
import { Redirect } from 'react-router-dom'

const ConfigurarInstaladores = () => {
  const emplacadorAtivoId = useSelector(emplacadorAtivoSelector)
  const usuarioLogado = useSelector(getUsuarioLogado)
  const [qtdInstaladores, setQtdInstaladores] = useState<any>('')
  const [{ data }] = useAxios(`/instaladores/${emplacadorAtivoId}`)
  const [{ loading }, fetchSalvar] = useAxios(
    {
      method: 'POST',
      url: `/instaladores/${emplacadorAtivoId}`,
      data: { qtdInstaladores },
    },
    {
      manual: true,
    }
  )
  useEffect(() => {
    setQtdInstaladores(data)
  }, [data])

  async function handleSalvar() {
    try {
      await fetchSalvar()
      notification.success({ message: 'Salvo com sucesso' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao salvar os instaladores'
      notification.error({ message })
    }
  }

  if (!usuarioLogado || !emplacadorAtivoId) return <Redirect to="/" />

  return (
    <Styles.Container>
      <h2>Configurar quantidade de instaladores</h2>
      <br />
      <Input
        size="large"
        type="number"
        value={qtdInstaladores}
        onChange={(e) => setQtdInstaladores(e.target.value)}
      />
      <div style={{ textAlign: 'right', marginTop: 12 }}>
        <Button size="large" loading={loading} onClick={handleSalvar}>
          Salvar
        </Button>
      </div>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled('div')`
    background: #f5f5f5;
    max-width: 600px;
    padding: 24px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    margin: 12px auto;
  `,
}

export { ConfigurarInstaladores }
