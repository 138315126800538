import axios from '../../common/axios'

import { Servico } from '../servicos/Servico'

import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { IRequest } from '../../common/IRequest'
import * as _ from 'lodash'
import { Emplacador } from './Emplacadores'
import { IStateRedux } from '../../common/ReduxStore'
import { notification } from 'antd'
import { EmplacadorServico } from './EmplacadorServico'

export interface IState {
  request: IRequest
  servicos: Servico[]
  servicoSalvo: boolean
  configurarRodizio: {
    cidade: string | null
    emplacadores: Emplacador[]
    novosValores: Array<{ emplacadorId; valor }>
    emplacadoresIdGravando: number[]
  }
}

export const initialState: IState = {
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
  servicos: [],
  servicoSalvo: false,
  configurarRodizio: {
    cidade: null,
    emplacadores: [],
    novosValores: [],
    emplacadoresIdGravando: [],
  },
}

const reducers = {
  fetchStarted(state: IState) {
    state.request.fetching = true
  },
  fetchError(state: IState, { payload }: PayloadAction<{ message }>) {
    state.request = {
      errorCode: 400,
      message: payload.message,
      fetching: false,
    }
  },
  fetchServicosSuccess(state: IState, { payload }: PayloadAction<{ servicos }>) {
    state.servicos = payload.servicos
  },
  gravarServicoSuccess(
    state: IState,
    { payload }: PayloadAction<{ emplacadorServico: EmplacadorServico }>
  ) {
    state.request.fetching = false
    state.servicoSalvo = true
    const emplacadorId = payload.emplacadorServico.Emplacador_Id
    state.configurarRodizio.emplacadoresIdGravando =
      state.configurarRodizio.emplacadoresIdGravando.filter((x) => x !== emplacadorId)
    state.configurarRodizio.novosValores = state.configurarRodizio.novosValores.filter(
      (x) => x.emplacadorId !== emplacadorId
    )
    const emplacadorAtual = state.configurarRodizio.emplacadores.find((x) => x.Id === emplacadorId)
    if (emplacadorAtual) {
      state.configurarRodizio.emplacadores = state.configurarRodizio.emplacadores.map((e) => {
        return e.Id !== emplacadorId
          ? e
          : {
              ...e,
              EmplacadoresServicos: [payload.emplacadorServico],
            }
      })
    }
  },
  clearServicoSalvoMessage(state: IState) {
    if (state.servicoSalvo) state.servicoSalvo = false
    if (state.request.message) state.request.message = ''
  },
  setCidadeRodizio(state: IState, { payload }: PayloadAction<{ cidade }>) {
    state.configurarRodizio.cidade = payload.cidade
  },
  getEmplacadoresConfigurarRodizioStarted(state: IState) {
    state.configurarRodizio.emplacadores = []
    state.configurarRodizio.novosValores = []
  },
  setEmplacadoresRodizioConfigurar(state: IState, { payload }: PayloadAction<{ emplacadores }>) {
    state.configurarRodizio.emplacadores = payload.emplacadores
  },
  setNovoValorRodizio(state: IState, { payload }: PayloadAction<{ emplacadorId; valor }>) {
    if (payload.valor > 128) {
      notification.warning({ message: 'Valor máximo é de 128' })
      payload.valor = 128
    }
    const novoValor = state.configurarRodizio.novosValores.find(
      (x) => x.emplacadorId === payload.emplacadorId
    )
    if (novoValor) novoValor.valor = payload.valor
    else state.configurarRodizio.novosValores.push(payload)
  },
  gravarServicoStarted(state: IState, { payload }: PayloadAction<{ emplacadorId; servicoId }>) {
    state.configurarRodizio.emplacadoresIdGravando.push(payload.emplacadorId)
  },
}

const { reducer: emplacadoresReducer, actions } = createSlice({
  name: 'emplacadores',
  initialState: initialState,
  reducers,
})

const emplacadoresActions = {
  ...actions,
  fetchServicos(emplacadorId: number) {
    return async (dispatch) => {
      dispatch(this.fetchStarted())
      const servicos = await axios.Servicos.precos(emplacadorId)
      dispatch(this.fetchServicosSuccess({ servicos: servicos }))
    }
  },
  gravarServico(servicoId: number, emplacadorId: number, valor: number) {
    return async (dispatch, getState) => {
      const state = getState() as IStateRedux
      dispatch(actions.gravarServicoStarted({ emplacadorId, servicoId }))
      try {
        const emplacadorServico = await axios.Servicos.gravarPreco(
          servicoId,
          emplacadorId,
          +valor.toString().replace(',', '.')
        )
        dispatch(actions.gravarServicoSuccess({ emplacadorServico }))
        if (state.emplacadores.configurarRodizio.emplacadores.length)
          this.getEmplacadoresConfigurarRodizio()
        if (state.emplacadores.servicos) this.fetchServicos(emplacadorId)
      } catch (error: any) {
        const message = _.get(error, 'response.data.message', 'Falha ao gravar o valor do serviço')
        dispatch(actions.fetchError({ message }))
      }
    }
  },
  getEmplacadoresConfigurarRodizio() {
    return async (dispatch, getState) => {
      const state = getState() as IStateRedux
      dispatch(actions.getEmplacadoresConfigurarRodizioStarted())
      try {
        const emplacadores = await axios.Emplacadores.getEmplacadoresConfigurarRodizioByCidade(
          state.emplacadores.configurarRodizio.cidade
        )
        dispatch(actions.setEmplacadoresRodizioConfigurar({ emplacadores }))
      } catch (error: any) {
        const message = _.get(
          error,
          'response.data.message',
          'Erro ao buscar os emplacadores da cidade'
        )
        dispatch(actions.fetchError({ message }))
      }
    }
  },
}

export const emplacadoresConfiguracaoRodizioSelector = createSelector(
  (s: IStateRedux) => s.emplacadores.configurarRodizio.emplacadores,
  (s: IStateRedux) => s.emplacadores.configurarRodizio.novosValores,
  (emplacadores, novosValores) => {
    return emplacadores
      .sort((a, b) => (a.ValorTotal! < b.ValorTotal! ? -1 : 1))
      .map((e) => {
        const Valor = (e?.EmplacadoresServicos?.[0]?.Valor ?? 120) + 8
        const novoValor = novosValores.find((x) => x.emplacadorId === e.Id)
        const ValorInformado = novoValor ? novoValor.valor : Valor
        return { ...e, Valor, ValorInformado }
      })
  }
)

export { emplacadoresReducer, emplacadoresActions }
