import React, { useEffect } from 'react'
import Axios from 'axios'
import { Pedido } from '../../pedidos/Pedido'
import { notification } from 'antd'

interface feedbackType {
  type: 'error' | 'success'
  message: string
}

const initialState = {
  loading: false,
  pedidos: [] as any[],
  feedback: null as feedbackType | null,
  assEletronica: '',
  aba: 'PENDENTE',
  paginacao: { pageSize: 10, total: 0, current: 1 },
  totais: {
    PENDENTE: 0,
    NEGADO: 0,
    APROVADO: 0,
  },
}

type ACTIONS =
  | { type: 'getPedidosLoading' }
  | { type: 'getPedidosSuccess'; payload: { rows: Pedido[]; count: number } }
  | { type: 'getPedidosError'; message }
  | { type: 'getAssEletronicaSuccess'; assEletronica }
  | { type: 'processaPagamentosSuccess'; pedidoId }
  | { type: 'limpaFeedback' }
  | { type: 'negaPagamentoSuccess'; pedidoId }
  | { type: 'alteraAba'; aba }
  | { type: 'alteraPaginacao'; paginacao }
  | { type: 'alteraTotais'; totais }

type IState = typeof initialState
type IDispatch = (p: ACTIONS) => void

function reducerFn(state: IState, action: ACTIONS): IState {
  switch (action.type) {
    case 'getPedidosLoading':
      return {
        ...state,
        pedidos: [],
        loading: true,
      }
    case 'getPedidosSuccess':
      return {
        ...state,
        loading: false,
        pedidos: action.payload.rows,
        paginacao: {
          ...state.paginacao,
          total: action.payload.count,
        },
      }
    case 'getPedidosError':
      return {
        ...state,
        loading: false,
        feedback: {
          message: action.message,
          type: 'error',
        },
      }
    case 'getAssEletronicaSuccess':
      return {
        ...state,
        assEletronica: action.assEletronica,
      }
    case 'processaPagamentosSuccess':
      return {
        ...state,
        feedback: {
          message: 'Pagamento processado com sucesso',
          type: 'success',
        },
        pedidos: state.pedidos.filter((p) => p.Id !== action.pedidoId),
      }
    case 'negaPagamentoSuccess':
      return {
        ...state,
        feedback: {
          message: 'Pagamento negado com sucesso',
          type: 'success',
        },
        pedidos: state.pedidos.filter((p) => p.Id !== action.pedidoId),
      }
    case 'alteraAba':
      return {
        ...state,
        aba: action.aba,
      }
    case 'alteraPaginacao':
      return {
        ...state,
        paginacao: action.paginacao,
      }
    case 'alteraTotais':
      return {
        ...state,
        totais: action.totais,
      }
    case 'limpaFeedback':
      return {
        ...state,
        feedback: null,
      }
    default:
      return state
  }
}

/** Funtions */

async function getPedidos({
  emplacadorId,
  status,
  paginacao,
  dispatch,
}: {
  emplacadorId
  status
  paginacao
  dispatch: IDispatch
}) {
  dispatch({ type: 'getPedidosLoading' })
  try {
    let limitOffset = {}
    if (status === 'APROVADO') {
      limitOffset = {
        limit: paginacao.pageSize,
        offset: paginacao.current * paginacao.pageSize - paginacao.pageSize,
      }
    }
    const responsePagamentosCartao = await Axios.get('/pagamentos-cartao', {
      params: { status, emplacadorId, ...limitOffset },
    }).then((x) => x.data)
    dispatch({ type: 'getPedidosSuccess', payload: responsePagamentosCartao })
  } catch (error: any) {
    const message =
      error.response?.body.message ?? 'Erro ao buscar os boletos esperando processamento'
    dispatch({ type: 'getPedidosError', message })
  }
}

async function getTotais({ emplacadorId, dispatch }: { emplacadorId; dispatch: IDispatch }) {
  try {
    const { data: totais } = await Axios.get('/pagamentos-cartao/totais', {
      params: { emplacadorId },
    })
    dispatch({ type: 'alteraTotais', totais })
  } catch (error: any) {}
}

async function getAssEletronica(dispatch: IDispatch, usuarioId) {
  try {
    const { assEletronica } = await Axios.get(`/ass-eletronica/${usuarioId}`).then((x) => x.data)
    dispatch({ type: 'getAssEletronicaSuccess', assEletronica })
  } catch (error: any) {}
}

async function cobrancaPdv({
  pdvId,
  meioPagamento,
  cobrancaId,
  user,
}: {
  pdvId?: number
  meioPagamento: 'CARTAO_CREDITO' | 'CARTAO_DEBITO'
  cobrancaId: string
  user: { authUsuarioId: string }
}) {
  try {
    const data = {
      pdvId,
      meioPagamento,
      cobrancaId,
      user,
    }

    await Axios.post('/pdv/criar-intencao-pagamento', data).then((res) => res.data)
  } catch (error: any) {
    const message = error.response?.data?.message ?? 'Ocorreu um erro ao gerar cobrança'
    notification.error({ message })
  }
}

async function cancelarPdv({
  cobrancaId,
  user,
}: {
  cobrancaId: string
  user: { authUsuarioId: string }
}) {
  try {
    const data = {
      cobrancaId,
      user,
    }

    await Axios.post('/pdv/cancelar-intencao-pagamento', data).then((res) => res.data)
  } catch (error: any) {
    const message = error.response?.data?.message ?? 'Ocorreu um erro ao cancelamento'
    notification.error({ message })
  }
}

/** Providers */
const StateContext = React.createContext(initialState)
const DispatchContext = React.createContext<IDispatch>(undefined as any)

function PagamentosCartaoProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducerFn, initialState)

  useEffect(() => {
    // console.log({ state })
  }, [state])

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  )
}

function usePagamentosCartaoState() {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('usePagamentosCartaoState must be used within a PagamentosCartaoProvider')
  }
  return context
}

function usePagamentosCartaoDispatch() {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('usePagamentosCartaoDispatch must be used within a PagamentosCartaoProvider')
  }
  return context
}

const GetPagamentosCartaoState = ({ children = (s: typeof state) => null as any }) => {
  const state = usePagamentosCartaoState()
  return children(state)
}

export {
  PagamentosCartaoProvider,
  GetPagamentosCartaoState,
  usePagamentosCartaoDispatch,
  usePagamentosCartaoState,
  getPedidos,
  getAssEletronica,
  getTotais,
  cobrancaPdv,
  cancelarPdv,
}
