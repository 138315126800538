import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IStateRedux } from '../../common/ReduxStore'
import { bindActionCreators } from 'redux'
import { pedidosActions, pedidosPagosSelector, pedidosPendentesSelector } from './PedidosStore'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import notification from 'antd/es/notification'
import { getUsuarioLogado } from '../auth/AuthStore'
import { Redirect } from 'react-router'
import { Badge, Tabs, Table, Button, Avatar, Popover, Modal, Tooltip } from 'antd'
import * as _ from 'lodash'
import { Show } from '../../common/Show'
import { emplacadorAtivoSelector } from '../contas/ContasStore'
import { TextFieldInput } from '../../common/TextField'
import AnimateHeightComp from 'react-animate-height'
import { InfoCircleOutlined, InfoOutlined } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/lib/table'
const AnimateHeight = AnimateHeightComp as any

let interval
export const Pedidos = () => {
  const dispatch = useDispatch()
  const usuarioLogado = useSelector(getUsuarioLogado)
  const message = useSelector((s: IStateRedux) => s.pedidos.request.message)
  const emplacadorAtivoId = useSelector(emplacadorAtivoSelector)
  const [activeTab, setActiveTab] = useState('pagos')

  const getPedidosByTab = useCallback(() => {
    console.log({ usuarioLogado })
    if (!emplacadorAtivoId) {
      if (activeTab === 'pendentes')
        dispatch(pedidosActions.getPedidos({ usuarioId: usuarioLogado.id, pagos: false }))
      if (activeTab === 'pagos')
        dispatch(pedidosActions.getPedidos({ usuarioId: usuarioLogado.id, pagos: true }))
      if (activeTab === 'estampados')
        dispatch(pedidosActions.getPedidosEstampados({ usuarioId: usuarioLogado.id }))
    } else {
      if (activeTab === 'pendentes')
        dispatch(pedidosActions.getPedidos({ emplacadorId: emplacadorAtivoId, pagos: false }))
      if (activeTab === 'pagos')
        dispatch(pedidosActions.getPedidos({ emplacadorId: emplacadorAtivoId, pagos: true }))
      if (activeTab === 'estampados')
        dispatch(pedidosActions.getPedidosEstampados({ emplacadorId: emplacadorAtivoId }))
    }
  }, [emplacadorAtivoId, activeTab, dispatch])

  useEffect(() => {
    dispatch(pedidosActions.clearPedidos())
  }, [dispatch, emplacadorAtivoId])
  useEffect(() => {
    if (activeTab !== 'estampadas') {
      getPedidosByTab()
      interval = setInterval(() => getPedidosByTab(), 1000 * 60 * 5)
      return () => clearInterval(interval)
    }
  }, [emplacadorAtivoId, activeTab, getPedidosByTab])
  useEffect(() => {
    if (message) {
      notification.error({ message })
      dispatch(pedidosActions.clearMessage())
    }
  }, [dispatch, message])

  if (!usuarioLogado) return <Redirect to="/" />

  return (
    <div>
      <Filtros />

      <Tabs defaultActiveKey="pagos" onChange={setActiveTab}>
        <Tabs.TabPane tab="Aguardando Pagamento" key="pendentes">
          <PedidosTipo tipo="pendentes" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Aguardando Estampagem" key="pagos">
          <PedidosTipo tipo="pagos" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Estampadas" key="estampados">
          <PedidosTipo tipo="estampados" />
        </Tabs.TabPane>
      </Tabs>

      <PedidosRetornoQuery />

      <OrdemServico />
    </div>
  )
}

function PedidosRetornoQuery() {
  const dispatch = useDispatch()
  const { pedidosRetornoQuery } = useSelector((s: IStateRedux) => s.pedidos)
  function handleClickPedido(pedido) {
    dispatch(pedidosActions.setPedidoExibirOrdemServicoQuery({ pedido }))
  }
  if (!pedidosRetornoQuery.length) return null
  const listaPedidos = pedidosRetornoQuery.map((pedido) => {
    return (
      <tr key={pedido.Id} onClick={() => handleClickPedido(pedido)}>
        <td>{pedido.Placa}</td>
        <td>
          {pedido.Aluno.CPF} <br />
          {pedido.Aluno.Nome}
        </td>
        <td>{format(new Date(pedido.Boleto.DataHora), 'dd/MM/yyyy HH:mm')}</td>
        <td>{pedido.Emplacador.Conta.Fantasia}</td>
        <td>{pedido.Boleto.Pago ? 'PAGO' : ''}</td>
        <td>{pedido.Data_Estampamento ? 'ESTAMPADA' : ''}</td>
      </tr>
    )
  })
  return (
    <Modal
      visible={true}
      width={800}
      title="Pedidos"
      onCancel={() => dispatch(pedidosActions.closePedidosRetornoQuery())}
      footer={null}
    >
      <Styles.Table>
        <thead>
          <tr>
            <th>Placa</th>
            <th>Proprietário</th>
            <th>Data</th>
            <th>Emplacador</th>
            <th>Pago</th>
            <th>Estampada</th>
          </tr>
        </thead>
        <tbody>{listaPedidos}</tbody>
      </Styles.Table>
    </Modal>
  )
}

function Filtros() {
  const isLoading = useSelector((s: IStateRedux) => s.pedidos.request.getPedidoByPlaca)
  const usuarioLogado = useSelector(getUsuarioLogado)
  const { contaAtiva, contasHipodromo } = useSelector((s: IStateRedux) => s.contas)
  const isUsuarioHipodromo = useSelector((s: IStateRedux) => s.auth.isUsuarioHipodromo)
  const dispatch = useDispatch()
  const [placa, setPlaca] = useState('')
  const [exibeFiltros, setExibeFiltros] = useState(false)
  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [nome, setNome] = useState('')

  function handleClickBuscar(e) {
    e.preventDefault()
    let contas = `${contaAtiva?.Id}`
    if (isUsuarioHipodromo) contas = [contaAtiva?.Id, ...contasHipodromo].join(',')
    dispatch(
      pedidosActions.queryPedidos({ placa, cpf, cnpj, nome, contas, usuarioId: usuarioLogado.Id })
    )
  }

  function handleClickMaisFiltros() {
    setExibeFiltros(!exibeFiltros)
  }

  return (
    <form onSubmit={handleClickBuscar}>
      <Styles.BuscarPlacasContainer>
        <Show condition={!exibeFiltros}>
          <Styles.TextFieldInputUpperCase
            placeholder="Busca por Placa"
            size="large"
            value={placa}
            allowClear
            hasPlaca={!!placa}
            onChange={(e) => setPlaca(e.target.value)}
            mask="aaa9*99"
          />
          <Button loading={isLoading} htmlType="submit" size="large" type="primary">
            Buscar
          </Button>
        </Show>
        <div style={{ flex: 1 }}></div>
        <Button onClick={handleClickMaisFiltros}>{!exibeFiltros ? 'Mais' : 'Menos'} filtros</Button>
      </Styles.BuscarPlacasContainer>
      <AnimateHeight height={exibeFiltros ? 'auto' : 0} duration={500}>
        <Styles.MaisFiltros>
          <div>
            <label htmlFor="">Busca por Placa</label>
            <Styles.TextFieldInputUpperCase
              placeholder="Informe a placa (Campo Opcional)"
              size="large"
              value={placa}
              allowClear
              hasPlaca={!!placa}
              onChange={(e) => setPlaca(e.target.value)}
              mask="aaa9*99"
            />
          </div>
          <div>
            <label htmlFor="">CPF</label>
            <TextFieldInput
              placeholder="Informe o CPF do proprietário (Campo Opcional)"
              size="large"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              mask="cpf"
            />
          </div>
          <div>
            <label htmlFor="">CNPJ</label>
            <TextFieldInput
              placeholder="Informe o CNPJ do proprietário (Campo Opcional)"
              size="large"
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
              mask="cnpj"
            />
          </div>
          <div>
            <label htmlFor="">Nome</label>
            <TextFieldInput
              placeholder="Informe o Nome do proprietário (Campo Opcional)"
              size="large"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div style={{ paddingTop: 12, textAlign: 'right' }}>
            <Button loading={isLoading} htmlType="submit" size="large" type="primary">
              Buscar
            </Button>
          </div>
        </Styles.MaisFiltros>
      </AnimateHeight>
    </form>
  )
}

function PedidosTipo({ tipo }: { tipo: 'pendentes' | 'pagos' | 'estampados' }) {
  const dispatch = useDispatch()
  const actions = bindActionCreators(pedidosActions, dispatch)
  const {
    request: { fetching },
    pedidoInformandoEstampamento,
    pedidosEstampados: {
      query: { limit: pageSize },
      paginaAtual,
      qtdPedidos,
      pedidos: pedidosEstampados,
    },
  } = useSelector((s: IStateRedux) => s.pedidos)
  const emplacadorAtivoId = useSelector(emplacadorAtivoSelector)
  const usuarioLogado = useSelector(getUsuarioLogado)
  const pedidosPendentes = useSelector(pedidosPendentesSelector)
  const pedidosPagos = useSelector(pedidosPagosSelector)

  const tipoAtual = {
    pendentes: pedidosPendentes,
    pagos: pedidosPagos,
    estampados: pedidosEstampados,
  }[tipo]

  function handleClickOs(pedido) {
    actions.setPedidoExibirOrdemServico({ pedido })
  }

  const columns = [
    {
      title: 'Placa',
      render: (text) => {
        const { cores, tipo } = _getCorPlaca(text)
        return (
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '1.4em', fontWeight: 'bold' }}>{text.Placa}</div>
            <div>{text.Codigo_Autorizacao}</div>
          </div>
        )
      },
    },
    {
      title: 'Origem',
      render: (text) => {
        return (
          <div>
            <div>
              {text.Aluno.CPF} - {text.Aluno.Nome}
            </div>
            {!!text.LocalEmplacamento && (
              <>
                <div style={{ fontSize: '.9em', marginTop: 4 }}> Local de Emplacamento </div>
                <div style={{ fontStyle: 'italic' }}>{text.LocalEmplacamento}</div>
              </>
            )}
          </div>
        )
      },
    },
    {
      title: 'Serviços',
      render: (text) => {
        return (
          <>
            <div style={{ fontWeight: 'bold', fontSize: '1.2em', textAlign: 'center' }}>
              {text.Servicos[0].Descricao.replace('MERCOSUL', '')}
            </div>
            <div style={{ textAlign: 'center', fontSize: '.8em' }}>
              {LABEL_CATEGORIAS[text.Categoria]}
            </div>
          </>
        )
      },
    },
    {
      title: emplacadorAtivoId ? 'Valor' : 'Estampador',
      render: (text) => (
        <>
          {emplacadorAtivoId ? (
            <div style={{ textAlign: 'right' }}>
              {' '}
              {text.Boleto.Valor.toFixed(2).replace('.', ',')}
            </div>
          ) : (
            <div> {text.Emplacador.Conta.Fantasia}</div>
          )}
        </>
      ),
    },
  ]
  if (tipo === 'pendentes') {
    columns.push({
      title: 'Emissão',
      render: (text) => (
        <div style={{ textAlign: 'center' }}>
          {format(new Date(text.Boleto.DataHora), 'dd/MM/yyyy HH:mm')}
        </div>
      ),
    })
  }

  if (tipo !== 'pendentes') {
    columns.push({
      title: 'Pagamento',
      render: (text) => (
        <div style={{ textAlign: 'center' }}>
          <Show condition={text.Boleto.BaixaOperacional_Codigo === 93}>
            <Popover
              title="Verificar Comprovante"
              content={
                <div>
                  <p> O banco confirmou o pagamento mas não houve liquidação. </p>
                  <p>
                    {' '}
                    Caso o pagamento seja via internet banking, o cliente ainda poderá estornar.{' '}
                  </p>
                </div>
              }
            >
              <InfoCircleOutlined style={{ color: 'red', fontSize: '1.4em', marginRight: 4 }} />
            </Popover>
          </Show>
          {format(new Date(text.Boleto.DataPgto), 'dd/MM/yyyy HH:mm')}
        </div>
      ),
    })
    columns.push({
      title: 'Agendamento',
      render: (text) => (
        <div style={{ textAlign: 'center' }}>
          {text.Data_Agendamento && format(new Date(text.Data_Agendamento), 'dd/MM/yyyy HH:mm')}
        </div>
      ),
    })
  }

  if (tipo === 'estampados') {
    columns.push({
      title: 'Estampagem',
      render: (text) => (
        <div style={{ textAlign: 'center' }}>
          {format(new Date(text.Data_Estampamento), 'dd/MM/yyyy HH:mm')}
        </div>
      ),
    })
  }

  columns.push({
    title: '',
    render: (pedido) => (
      <div style={{ textAlign: 'center', display: 'flex' }}>
        <Show condition={tipo === 'pagos' && emplacadorAtivoId}>
          <Button
            disabled={pedidoInformandoEstampamento != null}
            loading={pedidoInformandoEstampamento === pedido.Id}
            onClick={() => actions.informarEstampamento(pedido.Id)}
          >
            Estampar
          </Button>
        </Show>
        <Tooltip title="Ordem de Serviço" placement="left">
          <Button style={{ marginLeft: 6 }} onClick={() => handleClickOs(pedido)}>
            OS
          </Button>
        </Tooltip>
      </div>
    ),
  })

  function handleChangePaginacao(paginaAtual) {
    actions.setPaginaAtual({ paginaAtual })
    actions.getPedidosEstampados({ emplacadorId: emplacadorAtivoId, usuarioId: usuarioLogado.id })
  }

  const paginacaoConfig: TablePaginationConfig = {
    total: qtdPedidos as any,
    pageSize,
    current: paginaAtual,
    simple: true,
    onChange: handleChangePaginacao,
  }

  return (
    <>
      <Table
        size="small"
        bordered
        dataSource={tipoAtual}
        columns={columns}
        pagination={tipo === 'estampados' ? paginacaoConfig : false}
        loading={fetching}
        locale={{ emptyText: 'Nenhum resultado' }}
        rowKey={(record) => record.Id}
      />
      <Styles.Resumo>
        <table style={{ display: 'inline-table' }}>
          <tbody>
            <tr>
              <td style={{ paddingBottom: 4 }}>Quantidade total de pedidos:</td>
              <td style={{ paddingLeft: 12, paddingBottom: 4, textAlign: 'left' }}>
                <strong> {tipo === 'estampados' ? qtdPedidos : tipoAtual.length} </strong>
              </td>
            </tr>
            {/* <tr style={{ borderTop: '1px solid #ccc' }}>
              <td style={{ textAlign: 'right', paddingTop: 4 }}>Valor Total:</td>
              <td style={{ paddingLeft: 12, paddingTop: 4 }}>
                <strong>R$ {_parseValor(tipo === 'estampados' ? valorTotalPedidos : valorTotal)}</strong>
              </td>
            </tr> */}
          </tbody>
        </table>
      </Styles.Resumo>
    </>
  )
}

function DisplayFlex({ children }) {
  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>{children}</div>
}

function OrdemServico() {
  const dispatch = useDispatch()
  const actions = bindActionCreators(pedidosActions, dispatch)
  const usuarioLogado = useSelector(getUsuarioLogado)
  const contaIdAtual = useSelector((s: IStateRedux) => s.contas.contaAtiva?.Id)
  const isUsuarioHipodromo = useSelector((s: IStateRedux) => s.auth.isUsuarioHipodromo)
  const {
    pedidoExibindoOrdemServico: pedido,
    exibirStatusOrdemServico,
    pedidoInformandoEstampamento,
  } = useSelector((s: IStateRedux) => s.pedidos)
  if (!pedido) return null
  const isContaDiferenteAtual = contaIdAtual !== pedido.Emplacador.Conta_Id

  function handleCloseModal() {
    actions.setPedidoExibirOrdemServico({ pedido: null })
  }

  function handleClickImprimir() {
    window.print()
    actions.setPedidoExibirOrdemServico({ pedido: null })
  }

  const textoServico = _getTextoServico(pedido)

  let servicos: any = pedido.Servicos.map((ps) => <div key={ps.Id}>{ps.Descricao}</div>)

  if (textoServico) {
    servicos = (
      <div>
        <Show condition={pedido.Servicos.some((s) => s.Id === 1)}>
          <div>LACRE</div>
        </Show>
        <div>{textoServico}</div>
      </div>
    )
  }

  const status = (() => {
    const formatData = (data) => format(new Date(data), "dd/MM/yyyy' às 'HH:mm")
    if (pedido.Data_Estampamento) {
      return (
        <>
          <InfoOutlined type="check-circle" style={{ color: '#2196F3' }} />
          <div> Estampada </div>
          <div className="data">
            Data de estampagem:{' '}
            <strong>{format(new Date(pedido.Data_Estampamento), "dd/MM/yyyy' às 'HH:mm")}</strong>
          </div>
        </>
      )
    }

    if (pedido.Boleto.Pago) {
      return (
        <>
          <InfoOutlined type="file-done" style={{ color: '#4CAF50' }} />
          <div> Pago. Aguardando Estampagem </div>
          <div className="data">
            Data de Pagamento: <strong> {formatData(pedido.Boleto.DataPgto)}</strong>
          </div>
        </>
      )
    }

    return (
      <>
        <InfoOutlined type="hourglass" style={{ color: '#FBC02D' }} />
        <div> Aguardando Pagamento </div>
        <div className="data">
          Data de Emissão: <strong>{formatData(pedido.Boleto.DataHora)}</strong>
        </div>
      </>
    )
  })()

  return (
    <Modal
      visible={true}
      style={{ top: 20 }}
      onCancel={handleCloseModal}
      width={645}
      footer={[
        <Button key="close" onClick={handleCloseModal}>
          Fechar
        </Button>,
        <Button key="ok" type="primary" onClick={handleClickImprimir}>
          Imprimir
        </Button>,
      ]}
    >
      <Styles.OrdemServico>
        <Show condition={exibirStatusOrdemServico}>
          <Styles.StatusPedido>
            <div className="titulo"> Status </div>
            <div className="container">{status}</div>
            <Show condition={pedido.Boleto.Pago && !pedido.Data_Estampamento}>
              <div style={{ textAlign: 'center', marginTop: 12 }}>
                <Button
                  type="primary"
                  size="large"
                  loading={!!pedidoInformandoEstampamento}
                  onClick={() => actions.informarEstampamento(pedido.Id)}
                >
                  Estampar
                </Button>
              </div>
            </Show>
          </Styles.StatusPedido>
        </Show>
        <div>
          <Show condition={isUsuarioHipodromo || isContaDiferenteAtual}>
            <div className="titulo">Emplacador</div>
            <div style={isContaDiferenteAtual ? { fontSize: '1.4em', color: 'red' } : {}}>
              {pedido.Emplacador.Conta.Fantasia}
            </div>
          </Show>
          <div className="titulo"> Dados do Veículo </div>
          <DisplayFlex>
            <div>
              <div className="label"> Placa </div>
              {pedido.Placa}
            </div>
            <Show condition={!pedido.Mercosul}>
              <div>
                <div className="label"> Cor da placa </div>
                {_getCorPlaca(pedido).nomeCor}
              </div>
            </Show>
            <div>
              <div className="label"> Município </div>
              {pedido.Municipio}
            </div>
          </DisplayFlex>
          <Show condition={pedido.CodgChassi}>
            <DisplayFlex>
              <div>
                <div className="label"> Marca/Modelo </div>
                {pedido.DescMarcaModelo}
              </div>
              <div>
                <div className="label"> Chassi </div>
                {pedido.CodgChassi}
              </div>
            </DisplayFlex>
          </Show>
          <div>
            <div className="label"> Código de Autorização </div>
            {pedido.Codigo_Autorizacao}
          </div>
        </div>
        <div>
          <div className="titulo">Proprietário</div>
          <div>
            <div className="label">{pedido.Aluno.CPF.length === 14 ? 'Nome' : 'Razão Social'}</div>
            {pedido.Aluno.Nome}
          </div>
          <DisplayFlex>
            <div>
              <div className="label"> {pedido.Aluno.CPF.length === 14 ? 'CPF' : 'CNPJ'} </div>
              {pedido.Aluno.CPF}
            </div>
            <div>
              <div className="label"> Telefone </div>
              {pedido.Aluno.Telefone || '-'}
            </div>
          </DisplayFlex>
        </div>

        <Show condition={pedido.CodgDespachante}>
          <div>
            <div className="label"> Despachante </div>
            {pedido.CodgDespachante} - {pedido.NomeDespachante}
          </div>
        </Show>
        <Show condition={pedido.LocalEmplacamento}>
          <div>
            <div className="label"> Local de Emplacamento </div>
            {pedido.LocalEmplacamento}
          </div>
        </Show>
        <div>
          <div className="titulo"> Serviços </div>
          {servicos}
          <small>{LABEL_CATEGORIAS[pedido.Categoria]}</small>
        </div>
        <Show condition={usuarioLogado.Admin}>
          <div>
            <div className="titulo"> Id do Boleto </div>
            {pedido.Boleto_Id}
          </div>
        </Show>
      </Styles.OrdemServico>
    </Modal>
  )
}

function _getCorPlaca(pedido): { tipo; nomeCor; cores } {
  // COLECAO
  if (pedido.InfoColecao) {
    return {
      tipo: 'COLEÇÃO',
      nomeCor: 'PRETA COM LETRAS BRANCAS',
      cores: { fundo: 'black', texto: 'white', bordaMunicipio: '#333' },
    }
  }

  // PARTICULAR
  if (pedido.CodCategoria === 1) {
    return {
      tipo: 'PARTICULAR',
      nomeCor: 'CINZA',
      cores: { fundo: '#ddd', texto: 'black', bordaMunicipio: '#ccc' },
    }
  }

  // ALUGUEL
  if (pedido.CodCategoria === 2) {
    return {
      tipo: 'ALUGUEL',
      nomeCor: 'VERMELHA COM LETRAS BRANCAS',
      cores: { fundo: 'red', texto: 'white', bordaMunicipio: '#ccc' },
    }
  }

  // OFICIAL
  if (pedido.CodCategoria === 3) {
    return {
      tipo: 'OFICIAL',
      nomeCor: 'BRANCA',
      cores: { fundo: 'white', texto: 'black', bordaMunicipio: '#ddd' },
    }
  }

  // APRENDIZAGEM
  if (pedido.CodCategoria === 5) {
    return {
      tipo: 'APRENDIZAGEM',
      nomeCor: 'BRANCA COM LETRAS VERMELHAS',
      cores: { fundo: 'white', texto: 'red', bordaMunicipio: '#ddd' },
    }
  }

  return {
    tipo: '-',
    nomeCor: '-',
    cores: { fundo: 'purple', texto: 'white', bordaMunicipio: '#ca19ca' },
  }
}

function _getTextoServico(pedido) {
  if (!pedido) return
  const {
    PlacaDianteira,
    PlacaTraseira,
    PlacaTerceira,
    TarjetaDianteira,
    TarjetaTraseira,
    TarjetaTerceira,
    Moto,
  } = pedido
  const hasPlaca = PlacaDianteira || PlacaTraseira || PlacaTerceira
  if (Moto && TarjetaTraseira && !hasPlaca)
    return (
      <span>
        TARJETA DE <strong>MOTO</strong>
      </span>
    )
  if (Moto && PlacaTraseira)
    return (
      <span>
        PLACA DE <strong>MOTO</strong>
      </span>
    )
  if (PlacaDianteira && PlacaTraseira)
    return (
      <span>
        <strong>PAR</strong> DE PLACAS
      </span>
    )
  if (PlacaDianteira)
    return (
      <span>
        PLACA <strong>DIANTEIRA</strong>
      </span>
    )
  if (PlacaTraseira)
    return (
      <span>
        PLACA <strong>TRASEIRA</strong>
      </span>
    )
  if (PlacaTerceira)
    return (
      <span>
        PLACA <strong>TERCEIRA</strong> (Carreta/Reboque)
      </span>
    )
  if (TarjetaDianteira && TarjetaTraseira)
    return (
      <span>
        <strong>PAR</strong> DE TARJETAS
      </span>
    )
  if (TarjetaDianteira)
    return (
      <span>
        TARJETA <strong>DIANTEIRA</strong>
      </span>
    )
  if (TarjetaTraseira)
    return (
      <span>
        TARJETA <strong>TRASEIRA</strong>
      </span>
    )
  if (TarjetaTerceira)
    return (
      <span>
        TARJETA <strong>TERCEIRA</strong> (Carreta/Reboque)
      </span>
    )
}

const Styles = {
  Placa: styled('div')<{ cores: { fundo; texto; bordaMunicipio } }>`
    background: ${(props) => props.cores.fundo};
    color: ${(props) => props.cores.texto};
    padding: 2px 7px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    font-weight: bold;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    line-height: 1.1;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 4px 8px;
    .municipio {
      font-size: 0.7em;
      font-weight: normal;
      border: 1px solid ${(props) => props.cores.bordaMunicipio};
    }
  `,
  Resumo: styled('div')`
    background: #f3f3f3;
    padding: 12px;
    margin: 24px;
    border-radius: 6px;
    text-align: right;
  `,
  ContainerServicos: styled('div')`
    display: flex;
    align-items: center;
    .ant-badge {
      position: relative;
    }
    .texto-badge {
      position: absolute;
      top: 7px;
      font-size: 0.7em;
      color: white;
      width: 40px;
      text-align: center;
    }
    .servicos-texto {
      margin-left: 16px;
    }
  `,
  OrdemServico: styled('div')`
    @media print {
      position: fixed;
      background: white;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100vh;
      z-index: 100;
      & > div:last-child {
        padding-bottom: 24px;
        border-bottom: 1px dashed black;
      }
    }
    & > div {
      font-size: 1.4em;
      line-height: 1.2em;
      font-weight: bold;
      /* margin-right: 40px; */
      .titulo {
        font-size: 0.8em;
        color: #888;
        border-bottom: 1px solid #ddd;
        width: 100%;
        margin-top: 16px;
      }
      .label {
        font-size: 0.9em;
        font-weight: normal;
      }
    }
  `,
  Lacre: styled('div')`
    background: #795548;
    color: white;
    padding: 3px 6px;
    font-size: 0.8em;
    display: inline;
    border-radius: 4px;
  `,
  DisplayFlex: styled('div')``,
  BuscarPlacasContainer: styled('div')`
    display: flex;
    background: #f3f3f3;
    margin: 8px 8px 0 8px;
    padding: 6px;
    border-radius: 4px;
    button {
      margin-left: 12px;
    }
  `,
  MaisFiltros: styled('div')`
    padding: 24px;
    margin: 0 8px 8px 8px;
    background: #f3f3f3;
  `,
  TextFieldInputUpperCase: styled(TextFieldInput)(
    ({ hasPlaca }: any) => `
    input {
      text-transform: ${hasPlaca ? 'uppercase' : ''};
    }
  `
  ),
  StatusPedido: styled('div')`
    @media print {
      display: none;
    }
    .container {
      text-align: center;
    }
    .info-icon {
      font-size: 4em;
      margin: 12px;
    }
    .data {
      font-size: 0.8em;
      font-weight: normal;
      margin-top: 12px;
    }
  `,
  Table: styled('table')`
    border-collapse: collapse;
    width: 100%;
    table,
    th,
    td {
      border: 1px solid #444;
      padding: 8px;
    }
    tr:hover {
      background-color: #f3f3f3;
      cursor: pointer;
    }
    tr:nth-child(even) {
      background-color: #f7f7f7;
    }
  `,
}

const LABEL_CATEGORIAS = {
  ALUGUEL: 'ALUGUEL',
  PARTICULAR: 'PARTICULAR',
  APRENDIZAGEM: 'APRENDIZAGEM',
  OFICIAL: 'OFICIAL',
  EXPERIENCIA: 'EXPERIENCIA',
  '1': 'PARTICULAR',
  '2': 'ALUGUEL',
  '3': 'OFICIAL',
  '4': 'EXPERIENCIA',
  '5': 'APRENDIZAGEM',
  '': null,
}
