import React, { useEffect } from 'react'
import useMeasure from 'react-use-measure'
import { useSpring, animated } from 'react-spring'
import styled from '@emotion/styled'
import { ResizeObserver } from '@juggle/resize-observer'

const Wizard = ({ children, step }) => {
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver })
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    })
  }, [])
  const props = useSpring({ transform: `translate(-${bounds.width * step}px, 0px)` })
  return (
    <StylesWizard ref={ref} width={bounds.width} childrenNumber={children.length}>
      <animated.div style={props} className="container">
        {children}
      </animated.div>
    </StylesWizard>
  )
}

const StylesWizard = styled('div')<any>(
  ({ width, childrenNumber }) => `
  overflow: hidden;
  .container {
    width: ${width * childrenNumber}px;
    display: flex;
    & > * {
      width: ${width}px;
    }
  }
`
)

export { Wizard }
