import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import * as _ from 'lodash'
import styled from '@emotion/styled'

import { bindActionCreators } from 'redux'

import { emplacadoresActions, IState as IStateEmplacadores } from './EmplacadoresStore'
import { fornecedorAtivoSelector, emplacadorAtivoSelector } from '../contas/ContasStore'
import { getUsuarioLogado } from '../auth/AuthStore'
import { Redirect } from 'react-router'

import { Servico } from '../servicos/Servico'
import IntlNumberInput from 'react-intl-number-input'

export const ConfigurarValorEmplacador = () => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(emplacadoresActions, dispatch)
  const emplacadorAtualId = useSelector(emplacadorAtivoSelector)
  const fornecedorAtualId = useSelector(fornecedorAtivoSelector)

  const state: IStateEmplacadores = useSelector((s: any) => s.emplacadores)
  const {
    servicos,
    servicoSalvo,
    request: { message },
  } = state
  const usuarioLogado = useSelector(getUsuarioLogado)

  useEffect(() => {
    if (emplacadorAtualId) {
      actions.fetchServicos(emplacadorAtualId)
    }
  }, [emplacadorAtualId])

  useEffect(() => {
    if (servicoSalvo) notification.success({ message: 'Serviço salvo com sucesso' })
    if (message) notification.error({ message })
    actions.clearServicoSalvoMessage()
  }, [message, servicoSalvo])

  if (!usuarioLogado) return <Redirect to="/" />

  if (fornecedorAtualId && !emplacadorAtualId) return <Redirect to="/configurar-valor-fornecedor" />

  return (
    <Styles.Container>
      <h2>Serviços</h2>
      <br />
      <div>
        {servicos.map((s) => (
          <ServicoItem
            emplacadorId={emplacadorAtualId}
            callback={() => actions.fetchServicos(emplacadorAtualId as any)}
            key={s.Id}
            servico={s}
          />
        ))}
      </div>
    </Styles.Container>
  )
}

const ServicoItem = ({ servico, emplacadorId }: { servico: Servico; callback; emplacadorId }) => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(emplacadoresActions, dispatch)
  const descricao = servico?.Descricao ?? ''
  const valorPadrao = servico?.EstadosServicos[0]?.ValorTeto || 0
  const valor = servico?.EmplacadoresServicos?.[0]?.Valor ?? valorPadrao
  const [_valor, setValor] = useState(valor)
  const [submited, setSubmited] = useState(true)

  useEffect(() => {
    setValor(valor)
    setSubmited(true)
  }, [valor])

  const salvarServico = () => {
    actions.gravarServico(servico.Id, emplacadorId, _valor)
    setSubmited(true)
  }

  const handleChangeValor = (e) => {
    const value = +e.target.value.replace(/[R$ .]/g, '').replace(',', '.')
    setValor(value)
    setSubmited(false)
  }

  const valorInvalid = false // _valor > valorPadrao

  return (
    <Styles.Servico>
      <Styles.ServicoDescricao>
        <div style={{ flex: 1 }}>
          <Styles.Label> Descrição </Styles.Label>
          <div> {descricao} </div>
        </div>
        <div>
          <Styles.Label>Valor de Tabela</Styles.Label>
          <div> R$ {valorPadrao.toFixed(2).replace('.', ',')} </div>
        </div>
      </Styles.ServicoDescricao>
      <Styles.ServicoValor>
        <Styles.Label> Valor </Styles.Label>
        <IntlNumberInput
          locale="pt-BR"
          prefix="R$ "
          precision={2}
          size="large"
          value={_valor}
          onChange={(e) => handleChangeValor(e)}
          placeholder="Valor do serviço"
        />
      </Styles.ServicoValor>
      <Styles.ServicoAction>
        <Button
          disabled={submited || valorInvalid}
          size="large"
          type="primary"
          onClick={salvarServico}
        >
          Atualizar preço
        </Button>
      </Styles.ServicoAction>
      <ErrorMessage
        show={valorInvalid}
        message={`O valor não pode ser superior a R$${valorPadrao}.`}
      />
    </Styles.Servico>
  )
}

const ErrorMessage = ({ show, message }) =>
  show ? <Styles.ServicoErrorMessage> {message}</Styles.ServicoErrorMessage> : <div></div>

const Styles = {
  Label: styled('span')`
    font-weight: bold;
  `,
  ServicoErrorMessage: styled('span')`
    margin-top: 5px;
    font-weight: bold;
    color: red;
  `,
  Container: styled('div', { label: 'Container' })`
    background: #f3f3f3;
    max-width: 1024px;
    margin: 0 auto;
    margin-top: 24px;
    border-radius: 4px;
    padding: 24px;
  `,
  Servico: styled('div')`
    background-color: white;
    padding: 15px;
    margin-bottom: 15px;
    display: inline-block;
    width: 47%;
    margin: 10px;
  `,
  ServicoDescricao: styled('div')`
    width: 100%;
    margin-bottom: 5px;
    display: flex;
  `,
  ServicoValor: styled('div')`
    display: inline-block;
    width: 35%;
  `,
  ServicoAction: styled('div')`
    display: inline-block;
    margin-left: 30%;
    width: 25%;
  `,
}
