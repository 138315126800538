import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Select, Button, notification } from 'antd'
import { IStateRedux } from '../../common/ReduxStore'
import { getUsuarioLogado } from '../auth/AuthStore'
import { Redirect } from 'react-router'
import { fornecedorAtivoSelector, emplacadorAtivoSelector, IState } from '../contas/ContasStore'
import useAxios from 'axios-hooks'
import styled from '@emotion/styled'

const useShowErrorFromAxiosHooks = (error, defaultMessage?) => {
  useEffect(() => {
    if (!error) return
    const message = error.response?.data?.message ?? (defaultMessage || 'Ocorreu um erro')
    notification.error({ message })
  }, [error])
}
const useShowSuccessFromAxiosHooks = (data, message) => {
  useEffect(() => {
    if (!data) return
    notification.success({ message })
  }, [data])
}

export const ConfigurarFornecedor = () => {
  const emplacadorId = useSelector(emplacadorAtivoSelector)
  const fornecedorLogado = useSelector(fornecedorAtivoSelector)
  const [fornecedorId, setFornecedorId] = useState<any>(undefined)

  const [{ data: fornecedores, loading: fetchingFornecedores }] = useAxios('/fornecedores')

  const [{ data: fornecedorAtual, loading: fetchingFornecedorAtual }, refetchFornecedorAtual] =
    useAxios(`/fornecedor/${emplacadorId}`, { useCache: false, manual: !emplacadorId })

  const [{ data, loading, error }, reqAlteraFornecedor] = useAxios(
    {
      method: 'PUT',
      url: `/fornecedor/${emplacadorId}`,
      data: { fornecedorId },
    },
    { manual: true }
  )

  const [
    { data: dataRemoveFornecedor, loading: loadingRemoveFornecedor, error: errorRemoveFornecedor },
    reqRemoveFornecedor,
  ] = useAxios(
    {
      method: 'DELETE',
      url: `/fornecedor/${emplacadorId}`,
    },
    { manual: true }
  )

  const usuarioLogado = useSelector(getUsuarioLogado)
  const { contaAtiva } = useSelector((s: IStateRedux) => s.contas)

  useEffect(() => {
    setFornecedorId(fornecedorAtual?.Id)
  }, [fornecedorAtual])
  useShowErrorFromAxiosHooks(error)
  useShowSuccessFromAxiosHooks(data, 'Fornecedor alterado com sucesso')
  useShowErrorFromAxiosHooks(errorRemoveFornecedor)
  useShowSuccessFromAxiosHooks(dataRemoveFornecedor, 'Fornecedor removido com sucesso')

  if (!usuarioLogado?.Admin) return <Redirect to="/" />

  if (fornecedorLogado) return <ListaEmplacadoresPorFornecedor />

  const fornecedoresOpts = (fornecedores || []).map((f) => (
    <Select.Option key={f.Id} value={f.Id}>
      {f.Conta.Fantasia} - {f.Conta.Municipio}
    </Select.Option>
  ))
  return (
    <div>
      <h3>
        Selecione o fornecedor desejado e clique em <strong>Mudar Fornecedor</strong>
      </h3>
      <h4>
        Você está alterando o fornecedor de <strong>{contaAtiva?.Fantasia}</strong>
      </h4>
      <Select
        style={{ width: 300 }}
        value={fornecedorId}
        onChange={(e) => setFornecedorId(e)}
        loading={fetchingFornecedores || fetchingFornecedorAtual}
      >
        <Select.Option key={0} value={null as any}>
          Selecione um fornecedor
        </Select.Option>
        {fornecedoresOpts}
      </Select>
      <br />
      <Button onClick={async () => await reqAlteraFornecedor()} loading={loading}>
        Mudar Fornecedor
      </Button>
      <br />
      <br />
      <Button
        type="primary"
        loading={loadingRemoveFornecedor}
        className="ant-btn-dangerous"
        onClick={async () => await reqRemoveFornecedor().then(refetchFornecedorAtual)}
      >
        Remover Fornecedor
      </Button>
    </div>
  )
}

const ListaEmplacadoresPorFornecedor = () => {
  const fornecedorId = useSelector(fornecedorAtivoSelector)
  const [{ data }, refetch] = useAxios(`/emplacadores/fornecedor/${fornecedorId}`)
  const { contaAtiva } = useSelector((s: IStateRedux) => s.contas)
  useEffect(() => {
    if (fornecedorId) void refetch()
  }, [fornecedorId])
  return (
    <>
      <h2>
        Emplacadores que tem <strong>{contaAtiva?.Fantasia}</strong> como fornecedor
      </h2>
      <Styles.Table>
        <thead>
          <tr>
            <td>CNPJ</td>
            <td>Fantasia</td>
          </tr>
        </thead>
        <tbody>
          {(data || []).map((e) => (
            <tr key={e.Id}>
              <td>{e.Conta.CNPJ}</td>
              <td>{e.Conta.Fantasia}</td>
            </tr>
          ))}
        </tbody>
      </Styles.Table>
    </>
  )
}

const Styles = {
  Table: styled('table')`
    &,
    th,
    td {
      border: 1px solid #444;
    }
    td {
      padding: 4px;
    }
  `,
}
