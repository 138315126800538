import React, { useEffect, useState, useReducer, useCallback } from 'react'
import {
  PagamentosCartaoProvider,
  usePagamentosCartaoState,
  getPedidos,
  usePagamentosCartaoDispatch,
  getAssEletronica,
  GetPagamentosCartaoState,
  getTotais,
  cobrancaPdv,
  cancelarPdv,
} from './PagamentosCartaoStore'
import useAxios from 'axios-hooks'
import { Table, Button, notification, Modal, Input, Spin, Tabs, Badge } from 'antd'
import { useNotifyError } from '../../../common/useNofityError'
import { useSelector } from 'react-redux'
import { getUsuarioLogado } from '../../auth/AuthStore'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { format, parseISO, addHours } from 'date-fns'
import { Pedido } from '../../pedidos/Pedido'
import { Show } from '../../../common/Show'
import { emplacadorAtivoSelector } from '../../contas/ContasStore'
import { Flex } from '../../../common/Flex'

const PagamentosCartao = () => {
  const usuario = useSelector(getUsuarioLogado)
  const isAdmin = usuario?.Admin
  const possuiPermissao = useValidaPermissao()
  if (!possuiPermissao) return null

  const { Container } = PagamentosCartao

  return (
    <PagamentosCartaoProvider>
      <Container>
        <Flex>
          <h2 style={{ flex: 1 }}>Pagamentos efetuados via Cartão</h2>
          <NovoPagamento />
        </Flex>
        <AbasStatusPagamento>
          <ListaPagamentos>
            {(pedido) => (
              <GetPagamentosCartaoState>
                {({ aba }) => (
                  <>
                    <Show condition={aba === 'PENDENTE' && isAdmin}>
                      <BotaoProcessarPagamento pedido={pedido} style={{ width: 160 }} />
                      <BotaoNegarPagamento pedido={pedido} style={{ width: 160 }} />
                    </Show>
                    <Show condition={aba === 'NEGADO'}>
                      <BotaoAlterarPagamento pedido={pedido} />
                    </Show>
                  </>
                )}
              </GetPagamentosCartaoState>
            )}
          </ListaPagamentos>
        </AbasStatusPagamento>
        <>
          <Init />
          <InformaFeedback />
        </>
      </Container>
    </PagamentosCartaoProvider>
  )
}

const useValidaPermissao = () => {
  const usuario = useSelector(getUsuarioLogado)
  const history = useHistory()
  if (!usuario) {
    history.replace('/')
    notification.error({ message: 'Você não tem acesso à essa tela' })
    return false
  }
  return true
}

const AbasStatusPagamento = ({ children }) => {
  const { TabPane } = Tabs
  const dispatch = usePagamentosCartaoDispatch()
  const { aba, totais } = usePagamentosCartaoState()
  function handleChange(aba) {
    dispatch({ type: 'alteraAba', aba })
  }
  return (
    <Tabs onChange={handleChange} type="card" size="large" activeKey={aba}>
      <TabPane
        tab={
          <>
            {' '}
            Pendentes &nbsp; <Badge count={totais.PENDENTE} />{' '}
          </>
        }
        key="PENDENTE"
      >
        {children}
      </TabPane>
      <TabPane
        tab={
          <>
            {' '}
            Negados &nbsp; <Badge count={totais.NEGADO} />
          </>
        }
        key="NEGADO"
      >
        {children}
      </TabPane>
      <TabPane
        tab={
          <>
            {' '}
            Aprovados &nbsp; <Badge count={totais.APROVADO} />
          </>
        }
        key="APROVADO"
      >
        {children}
      </TabPane>
    </Tabs>
  )
}

const Init = () => {
  const dispatch = usePagamentosCartaoDispatch()
  const { aba, paginacao } = usePagamentosCartaoState()
  const emplacadorId = useSelector(emplacadorAtivoSelector)
  const usuario = useSelector(getUsuarioLogado)

  const _getPedidos = async () =>
    await getPedidos({
      status: aba,
      emplacadorId: usuario?.Admin ? undefined : emplacadorId,
      paginacao,
      dispatch,
    })

  useEffect(() => {
    void _getPedidos()
    void getTotais({
      emplacadorId: usuario?.Admin ? undefined : emplacadorId,
      dispatch,
    })
  }, [aba, emplacadorId])

  useEffect(() => {
    if (aba === 'APROVADO') void _getPedidos()
  }, [paginacao.current, paginacao.pageSize])

  useEffect(() => {
    void getAssEletronica(dispatch, usuario.Id)
  }, [])
  return null
}

const ListaPagamentos = ({ children }) => {
  const { aba, paginacao, loading, pedidos } = usePagamentosCartaoState()
  const dispatch = usePagamentosCartaoDispatch()
  function handleChangePaginacao(current, pageSize) {
    dispatch({
      type: 'alteraPaginacao',
      paginacao: {
        ...paginacao,
        current,
        pageSize,
      },
    })
  }

  const columns = [
    {
      title: 'Data Inclusão',
      render: (pedido) => <>{format(new Date(pedido.Boleto.DataHora), 'dd/MM/yyyy HH:mm')}</>,
    },
    {
      title: 'Data Pagamento',
      render: (pedido) => (
        <>
          {pedido.DataPagamentoCartao
            ? format(new Date(pedido.DataPagamentoCartao), 'dd/MM/yyyy')
            : null}
        </>
      ),
    },
    {
      title: 'Placa',
      render: (pedido) => pedido.Placa,
    },
    {
      title: 'Boleto Id',
      render: (pedido) => pedido.Boleto.Id,
    },
    {
      title: 'Solicitante',
      render: (pedido) => (
        <>
          {pedido.Boleto.Aluno.CPF} - {pedido.Boleto.Aluno.Nome}
        </>
      ),
    },
    {
      title: 'Tipo',
      render: (pedido) => <TipoPagamento pedido={pedido} />,
    },
    {
      title: 'Autorização',
      render: (pedido) => <strong>{pedido.AutorizacaoPagamentoCartao}</strong>,
    },
    {
      title: 'Conta',
      render: (pedido) => pedido.Boleto.Conta.Fantasia,
    },
    {
      title: 'Valor',
      render: (pedido) => <>R$ {pedido.Boleto.Valor.toFixed(2).replace('.', ',')}</>,
    },
    {
      render: (pedido) => children(pedido),
    },
  ]

  if (aba === 'APROVADO') {
    return (
      <Table
        rowKey={(s: any) => '' + s.Id}
        dataSource={pedidos}
        columns={columns}
        loading={loading}
        size="small"
        pagination={{
          ...paginacao,
          onChange: handleChangePaginacao,
          onShowSizeChange: handleChangePaginacao,
        }}
      />
    )
  }
  return (
    <Table
      rowKey={(s: any) => '' + s.Id}
      dataSource={pedidos}
      columns={columns}
      loading={loading}
      size="small"
      pagination={false}
    />
  )
}

const BotaoProcessarPagamento = ({
  pedido,
  onClick,
  ...props
}: { pedido: Pedido } & React.HTMLAttributes<typeof Button>) => {
  const { assEletronica } = usePagamentosCartaoState()
  const dispatch = usePagamentosCartaoDispatch()
  const [assEletronicaInformada, setAssEletronicaInformada] = useState('')
  const [showModal, setShowModal] = useState(false)

  const usuario = useSelector(getUsuarioLogado)
  const [{ loading, error }, fetch] = useAxios(
    {
      method: 'POST',
      url: '/processa-pagamento-cartao',
      data: {
        usuarioId: usuario.Id,
        boletoId: pedido?.Boleto?.Id,
      },
    },
    {
      manual: true,
    }
  )
  useNotifyError(error, 'Ocorreu um erro ao processar o pagamento')

  useEffect(() => {
    setAssEletronicaInformada('')
  }, [showModal])

  function handleClick(e) {
    if (onClick) onClick(e)
    setShowModal(true)
  }

  const [cancelar, setCancelar] = useState(false)

  async function handlePdv(meioPagamento: 'CARTAO_CREDITO' | 'CARTAO_DEBITO') {
    setCancelar(true)
    cobrancaPdv({
      cobrancaId: pedido?.Boleto?.Id,
      meioPagamento,
      user: { authUsuarioId: usuario.id },
    })
  }

  async function handleCancelar() {
    setCancelar(false)
    cancelarPdv({
      cobrancaId: pedido?.Boleto?.Id,
      user: { authUsuarioId: usuario.id },
    })
  }

  async function processarPagamento() {
    try {
      if (assEletronica?.toUpperCase() !== assEletronicaInformada.toUpperCase()) {
        return notification.error({ message: 'Assinatura eletrônica inválida' })
      }
      await fetch()
      setShowModal(false)
      dispatch({ type: 'processaPagamentosSuccess', pedidoId: pedido.Id })
    } catch (error: any) {}
  }

  return (
    <>
      <Modal
        title="Processa Pagamento"
        visible={showModal}
        onOk={processarPagamento}
        onCancel={() => setShowModal(false)}
      >
        <Spin spinning={loading}>
          <label>
            Assinatura Eletrônica
            <Input
              value={assEletronicaInformada}
              onChange={(e) => setAssEletronicaInformada(e.target.value)}
              autoFocus={true}
              onKeyDown={(e) => (e.key === 'Enter' ? processarPagamento() : null)}
              type="password"
            />
          </label>
        </Spin>
      </Modal>
      <Button onClick={handleClick} {...props} size="small" type="primary">
        Processar Pagamento
      </Button>
      <div style={{ display: 'none' }}>
        {cancelar ? (
          <Button onClick={() => handleCancelar()} {...props} size="small" type="primary">
            Cancelar
          </Button>
        ) : (
          <>
            <Button
              onClick={() => handlePdv('CARTAO_DEBITO')}
              {...props}
              size="small"
              type="primary"
              style={{ margin: 2 }}
            >
              Débito
            </Button>
            <Button
              onClick={() => handlePdv('CARTAO_CREDITO')}
              {...props}
              size="small"
              type="primary"
              style={{ margin: 2 }}
            >
              Crédito
            </Button>
          </>
        )}
      </div>
    </>
  )
}

const BotaoNegarPagamento = ({
  pedido,
  onClick,
  ...props
}: { pedido: Pedido } & React.HTMLAttributes<typeof Button>) => {
  const { assEletronica } = usePagamentosCartaoState()
  const dispatch = usePagamentosCartaoDispatch()
  const [showModal, setShowModal] = useState(false)
  const [assEletronicaInformada, setAssEletronicaInformada] = useState('')
  const [motivoNegacaoPagamento, setMotivoNegacaoPagamento] = useState('')

  const [{ loading, error }, fetch] = useAxios(
    {
      method: 'POST',
      url: '/nega-pagamento-cartao',
      data: {
        boletoId: pedido?.Boleto?.Id,
        motivo: motivoNegacaoPagamento,
      },
    },
    { manual: true }
  )
  useNotifyError(error, 'Ocorreu um erro ao processar o pagamento')

  function handleClickBotaoNegarPagamento(e) {
    if (onClick) onClick(e)
    setShowModal(true)
  }

  async function negarPagamento() {
    try {
      if (assEletronica?.toUpperCase() !== assEletronicaInformada.toUpperCase()) {
        return notification.error({ message: 'Assinatura eletrônica inválida' })
      }
      await fetch()
      setShowModal(false)
      dispatch({ type: 'negaPagamentoSuccess', pedidoId: pedido.Id })
    } catch (error: any) {}
  }

  return (
    <>
      <Modal
        title="Negar Pagamento"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={negarPagamento}
      >
        <Spin spinning={loading}>
          <label>
            Motivo da Negação
            <Input.TextArea
              value={motivoNegacaoPagamento}
              onChange={(e) => setMotivoNegacaoPagamento(e.target.value)}
              placeholder="Informe o motivo da negação"
              autoFocus={true}
            />
          </label>
          <br />
          <br />
          <label>
            Assinatura Eletrônica
            <Input
              placeholder="Informe a Assinatura Eletrônica"
              value={assEletronicaInformada}
              onChange={(e) => setAssEletronicaInformada(e.target.value)}
              onKeyDown={(e) => (e.key === 'Enter' ? negarPagamento() : null)}
            />
          </label>
        </Spin>
      </Modal>
      <Button onClick={handleClickBotaoNegarPagamento} {...props} size="small">
        Negar Pagamento
      </Button>
    </>
  )
}

const BotaoAlterarPagamento = ({
  pedido,
  onClick,
  ...props
}: { pedido: Pedido } & React.HTMLAttributes<typeof Button>) => {
  const history = useHistory()

  function handleClick(e) {
    if (onClick) onClick(e)
    history.push(`/informa-pagamento-cartao/${pedido.Placa}`)
  }

  return (
    <Button onClick={handleClick} {...props} size="small">
      Alterar Pagamento
    </Button>
  )
}

const InformaFeedback = () => {
  const { feedback } = usePagamentosCartaoState()
  const dispatch = usePagamentosCartaoDispatch()
  useEffect(() => {
    if (feedback) {
      notification.open({
        ...feedback,
        onClose: () => dispatch({ type: 'limpaFeedback' }),
      })
    }
  }, [feedback])
  return null
}

async function validateAssinaturaEletronica(assEletronica) {
  const assEletronicaInformada = prompt('Assinatura eletrônica')
  return assEletronicaInformada?.toUpperCase() === assEletronica.toUpperCase()
}

const NovoPagamento = () => {
  const history = useHistory()
  function handleClick() {
    history.push('/informa-pagamento-cartao')
  }
  return (
    <Button type="primary" onClick={handleClick}>
      Novo Pagamento
    </Button>
  )
}

function TipoPagamento({ pedido }) {
  const { Container } = TipoPagamento
  const tipo = pedido.TipoPagamentoCartao
  return <Container tipo={tipo}>{{ CREDITO: 'CRÉDITO', DEBITO: 'DÉBITO' }[tipo]}</Container>
}

TipoPagamento.Container = styled('div')<{ tipo: 'CREDITO' | 'DEBITO' }>(
  (props) => `
  font-size: 0.9em;
  background: ${props.tipo === 'CREDITO' ? '#9028bf' : '#026daa'};
  text-align: center;
  padding: 3px;
  border-radius: 4px;
  font-weight: bold;
  color: white;
`
)

PagamentosCartao.Container = styled('div')`
  margin: 48px;
  background: #f2f2f2;
  padding: 24px;
  border-radius: 4px;
`

export { PagamentosCartao }
