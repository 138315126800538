import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { IRequest } from '../../common/IRequest'
import axios from '../../common/axios'
import { Conta } from './Conta'
import { authActions, getUsuarioLogado } from '../auth/AuthStore'
import { IStateRedux } from '../../common/ReduxStore'
import * as _ from 'lodash'

const contaAtivaJson = sessionStorage.getItem('contaAtiva') || null
const contaAtiva =
  contaAtivaJson && contaAtivaJson !== 'undefined' ? JSON.parse(contaAtivaJson) : null
const cidadeAtiva = sessionStorage.getItem('cidadeAtiva') || 'GOIÂNIA'

export interface IState {
  request: IRequest & { fetchingContas }
  cidades: Array<{ Municipio; UF }>
  cidadeAtiva: string | null
  contaAtiva: Conta | null
  contas: Conta[]
  contasHipodromo: number[]
  despachantesParceirosCapitalPlacas: number[]
}

export const initialState: IState = {
  request: {
    fetching: false,
    fetchingContas: false,
    errorCode: null,
    message: '',
  },
  contaAtiva: contaAtiva,
  cidades: [],
  cidadeAtiva,
  contas: [],
  contasHipodromo: [],
  despachantesParceirosCapitalPlacas: [],
}

const reducers = {
  fetchStarted(state: IState) {
    state.request.fetching = true
  },
  fetchError(state: IState, { payload }: PayloadAction<{ message }>) {
    state.request = {
      errorCode: 400,
      message: payload.message,
      fetching: false,
      fetchingContas: false,
    }
  },
  clearMessage(state: IState) {
    state.request.message = ''
  },
  fetchCidadesSuccess(state: IState, { payload }: PayloadAction<{ cidades }>) {
    state.cidades = payload.cidades
    state.request.fetching = false
  },
  fetchContasUsuarioSuccess(state: IState, { payload }: PayloadAction<{ contas; contaAtivaId }>) {
    state.contas = payload.contas
    const contaAtiva = payload.contas?.find((x) => x.id === payload.contaAtivaId)
    state.contaAtiva = (function getContaAtiva() {
      if (contaAtiva) return contaAtiva
      if (state.contaAtiva) return state.contaAtiva
      return payload.contas[0]
    })()
    state.request.fetching = false
  },
  fetchContasByCidadeStarted(state: IState) {
    state.request.fetchingContas = true
  },
  fetchContasByCidadeSuccess(state: IState, { payload }: PayloadAction<{ contas }>) {
    state.contas = payload.contas
    if (!state.contaAtiva) state.contaAtiva = payload.contas[0]
    else {
      if (!payload.contas.some((c) => c.Id === state.contaAtiva!.Id)) {
        state.contaAtiva = payload.contas[0]
      }
    }
    state.request.fetching = false
    state.request.fetchingContas = false
  },
  setContaAtiva(state: IState, { payload }: PayloadAction<{ contaId }>) {
    const contaAtiva = state.contas.find((x) => x.Id === payload.contaId)!
    state.contaAtiva = contaAtiva
    sessionStorage.setItem('contaAtiva', JSON.stringify(contaAtiva))
    state.request.fetching = false
    state.request.fetchingContas = false
  },
  renewTokenStarted(state: IState) {
    state.request.fetching = true
    state.request.fetchingContas = true
  },
  setCidadeAtiva(state: IState, { payload }: PayloadAction<{ cidade }>) {
    state.cidadeAtiva = payload.cidade
    sessionStorage.setItem('cidadeAtiva', payload.cidade)
  },
  setContasHipodromo(state: IState, { payload }: PayloadAction<{ contasHipodromo }>) {
    state.contasHipodromo = payload.contasHipodromo
  },
  setDespachantesParceirosCapitalPlacas(
    state: IState,
    { payload }: PayloadAction<{ despachantes }>
  ) {
    state.despachantesParceirosCapitalPlacas = payload.despachantes
  },
}

const { reducer: contasReducer, actions } = createSlice({
  name: 'contas',
  initialState: initialState,
  reducers,
  extraReducers: {
    [authActions.logout.toString()]: (state) => {
      state.contaAtiva = null
      state.cidadeAtiva = 'GOIÂNIA'
      sessionStorage.removeItem('contaAtiva')
      sessionStorage.removeItem('cidadeAtiva')
    },
  },
})

const contasActions = {
  ...actions,
  fetchContasUsuario(usuarioId: number, contaAtivaId: string) {
    return async (dispatch) => {
      dispatch(actions.fetchStarted())
      try {
        const contas = await axios.Contas.getContasUsuario(usuarioId)
        dispatch(actions.fetchContasUsuarioSuccess({ contas, contaAtivaId }))
      } catch (error: any) {
        const message = _.get(error, '', 'Ocorreu um erro ao buscar as contas')
        dispatch(actions.fetchError({ message }))
      }
    }
  },
  fetchCidades() {
    return async (dispatch) => {
      dispatch(actions.fetchStarted())
      try {
        const cidades = await axios.Emplacadores.getCidades()
        dispatch(actions.fetchCidadesSuccess({ cidades }))
      } catch (error: any) {
        const message = _.get(error, '', 'Ocorreu um erro ao buscar as cidades')
        dispatch(actions.fetchError({ message }))
      }
    }
  },
  fetchContasByCidade(cidadeAtiva) {
    return async (dispatch) => {
      dispatch(actions.fetchContasByCidadeStarted())
      try {
        const contas = await axios.Contas.getContasByCidade(cidadeAtiva)
        dispatch(actions.fetchContasByCidadeSuccess({ contas }))
      } catch (error: any) {
        const message = _.get(error, '', 'Ocorreu um erro ao buscar as contas dessa cidade')
        dispatch(actions.fetchError({ message }))
      }
    }
  },
  getContasHipodromo() {
    return async (dispatch) => {
      try {
        const contasHipodromo = await axios.Contas.getContasHipodromo()
        dispatch(actions.setContasHipodromo({ contasHipodromo }))
      } catch (error: any) {}
    }
  },
  getDespachantesParceirosCapitalPlacas() {
    return async (dispatch) => {
      try {
        const despachantes = (await axios.Contas.getDespachantesParceirosCapitalPlacas()) as any
        dispatch(actions.setDespachantesParceirosCapitalPlacas({ despachantes }))
      } catch (error: any) {}
    }
  },
}

export const emplacadorAtivoSelector = createSelector(
  (s: IStateRedux) => s.contas.contaAtiva,
  getUsuarioLogado,
  (contaAtiva, usuarioLogado) => {
    if (!usuarioLogado) return null
    if (!contaAtiva || !contaAtiva.Tipo.startsWith('EMPLACADOR')) return null
    return contaAtiva?.Emplacador?.Id ?? null
  }
)

export const fornecedorAtivoSelector = createSelector(
  (s: IStateRedux) => s.contas.contaAtiva,
  getUsuarioLogado,
  (contaAtiva, usuarioLogado) => {
    if (!usuarioLogado) return null
    if (!contaAtiva || contaAtiva.Tipo !== 'FORNECEDOR DE PLACAS') return null
    return contaAtiva.Fornecedor.Id
  }
)

export { contasReducer, contasActions }
