import React, { useState, useEffect } from 'react'
import { Route, Link, Switch } from 'react-router-dom'
import { Select, notification, Modal, Menu, Drawer, Button, Popover, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'

import { bindActionCreators } from 'redux'

import { ConfigurarFornecedor } from './domains/fornecedores/ConfigurarFornecedor'
import { ConfigurarValorEmplacador } from './domains/emplacadores/ConfigurarValorEmplacador'
import { ConfigurarValorFornecedor } from './domains/fornecedores/ConfigurarValorFornecedor'
import { NovoPedido } from './domains/pedidos/NovoPedido/NovoPedido'
import { Pedidos } from './domains/pedidos/Pedidos'
import { contasActions } from './domains/contas/ContasStore'
import { getUsuarioLogado, authActions } from './domains/auth/AuthStore'
import { Rodizio } from './domains/emplacadores/Rodizio'
import { Hipodromo } from './domains/usuarios/Hipodromo'
import { Agendamento } from './domains/pedidos/Agendamento'
import { ConfigurarInstaladores } from './domains/emplacadores/ConfigurarInstaladores'
import { InformaPagamentoCartao } from './domains/pagamentos/InformaPagamentoCartao/InformaPagamentoCartao'
import { PagamentosCartao } from './domains/pagamentos/PagamentosCartao/PagamentosCartao'
import {
  ComissaoSeplac,
  PERMISSAO_VISUALIZACAO_COMISSAO,
} from './domains/fornecedores/ComissaoSeplac'
import { LockOutlined, MenuOutlined, PoweroffOutlined } from '@ant-design/icons'
import { getCurrentSite } from './common/getCurrentSite'
import { IStateRedux } from './common/ReduxStore'
import { Show } from './common/Show'
import * as _ from 'lodash'
import { Despachantes } from './domains/despachantes/Despachantes'

export const Shell = () => {
  const [drawerVisible, setDrawerVisible] = useState(false)
  return (
    <>
      <MenuContainer drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible} />
      <Nav setDrawerVisible={setDrawerVisible} />
      {/* <Mensagens /> */}
      <Switch>
        <Route path="/configurar-fornecedor" component={ConfigurarFornecedor} />
        <Route path="/configurar-valor-emplacador" component={ConfigurarValorEmplacador} />
        <Route path="/configurar-valor-fornecedor" component={ConfigurarValorFornecedor} />
        {/* <Route path="/" exact component={NovoPedido} /> */}
        <Route path="/" exact component={NovoPedido} />
        <Route path="/pedidos" component={Pedidos} />
        <Route path="/rodizio" component={Rodizio} />
        <Route path="/hipodromo" component={Hipodromo} />
        <Route path="/agendamento" component={Agendamento} />
        <Route path="/configurar-instaladores" component={ConfigurarInstaladores} />
        <Route path="/informa-pagamento-cartao/:placa?" component={InformaPagamentoCartao} />
        <Route path="/pagamentos-cartao" component={PagamentosCartao} />
        <Route path="/adm-valores" component={ComissaoSeplac} />
        <Route path="/despachantes" component={Despachantes} />
      </Switch>
    </>
  )
}

const Mensagens = () => {
  const { Text } = Mensagens
  const usuarioLogado = useSelector(getUsuarioLogado)
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    const stopShowing = localStorage.getItem('mensagem_valor_visualizada')
    if (usuarioLogado && (!stopShowing || stopShowing === 'undefined')) {
      setVisible(true)
    }
  }, [usuarioLogado])

  function handleClose() {
    localStorage.setItem('mensagem_valor_visualizada', 'true')
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      title="Atenção!"
      onCancel={handleClose}
      footer={
        <Button onClick={handleClose} type="primary">
          Fechar
        </Button>
      }
    >
      <p>Prezado usuário,</p>
      <Text>
        <p>
          Considerando os constantes aumentos de custos de serviços de nuvem, serviços de tecnologia
          em geral e tarifas bancárias e ainda visando entregar uma plataforma robusta, segura, ágil
          e inovadora...
        </p>
        <p>
          Vimos por meio deste informar que, a partir do dia <strong>04/01/2021</strong>, nossas
          tarifas serão atualizadas de R$ 5,00 <em>(cinco reais)</em> para R$ 6,00{' '}
          <em>(seis reais)</em>.
        </p>
        <p>
          À oportunidade, agradecemos a parceria firmada neste ano. Com os votos de que 2021
          proporcione a você e ao seu negócio os frutos do excelente trabalho realizado neste ano
          que se finda.
        </p>
        <p>
          Aguardamos ansiosamente para lhe apresentar as incríveis novidades que estamos trabalhando
          para seu negócio “emplacar” mais ainda em 2021! Contamos com você em mais um ano de
          parceria, andando lado a lado na construção de um mercado de excelência.
        </p>
      </Text>

      <p>Um Feliz Ano!</p>

      <p>
        Fraternalmente,
        <br />O Time Hyperpay.
      </p>
    </Modal>
  )
}
Mensagens.Text = styled.div`
  p {
    text-indent: 36px;
  }
`

function MenuContainer({ drawerVisible, setDrawerVisible }) {
  const usuarioLogado = useSelector(getUsuarioLogado)
  const isAdministradorHipodromo = useSelector((s: IStateRedux) => s.auth.isAdministradorHipodromo)
  const isUsuarioHipodromo = useSelector((s: IStateRedux) => s.auth.isUsuarioHipodromo)
  const { contaAtiva } = useSelector((s: IStateRedux) => s.contas)

  const usuarioPermissaoComissao =
    usuarioLogado?.Admin || PERMISSAO_VISUALIZACAO_COMISSAO.includes(usuarioLogado?.Id)

  if (!usuarioLogado) return null

  function handleClickMenu() {
    setDrawerVisible(false)
  }

  return (
    <Drawer placement="left" visible={drawerVisible} onClose={() => setDrawerVisible(false)}>
      <Menu mode="inline" style={{ marginTop: 24 }} onClick={handleClickMenu}>
        <Menu.Item>
          <Link to="/"> Emitir Boleto </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/pedidos"> Pedidos </Link>
        </Menu.Item>
        {contaAtiva?.Tipo.startsWith('EMPLACADOR') && (
          <Menu.Item>
            <Link to="/configurar-valor-emplacador"> Configurar Valores </Link>
          </Menu.Item>
        )}
        {contaAtiva?.Tipo === 'FORNECEDOR DE PLACAS' && (
          <Menu.Item>
            <Link to="/configurar-valor-fornecedor"> Configurar Valores </Link>
          </Menu.Item>
        )}
        {(isAdministradorHipodromo || usuarioLogado?.Admin) && (
          <Menu.Item>
            <Link to="/hipodromo">Usuários do Hipodromo</Link>
          </Menu.Item>
        )}
        {usuarioLogado?.Admin && (
          <Menu.Item>
            <Link to="/configurar-instaladores">Configurar Instaladores</Link>
          </Menu.Item>
        )}
        {usuarioLogado?.Admin && (
          <Menu.Item>
            <Link to="/configurar-fornecedor">Configurar Fornecedor</Link>
          </Menu.Item>
        )}
        {/* {
          (isUsuarioHipodromo || (usuarioLogado?.Admin)) &&
          <Menu.Item>
            <Link to="/informa-pagamento-cartao">Pagamento Via Cartão</Link>
          </Menu.Item>
        } */}
        {(isUsuarioHipodromo || usuarioLogado?.Admin) && (
          <Menu.Item>
            <Link to="/pagamentos-cartao">Pagamentos Via Cartão</Link>
          </Menu.Item>
        )}
        {usuarioPermissaoComissao && (
          <Menu.Item>
            <Link to="/adm-valores">Adm</Link>
          </Menu.Item>
        )}
      </Menu>
      <div style={{ fontSize: '.8em' }}>v 1.0.3</div>
    </Drawer>
  )
}

function Nav({ setDrawerVisible }) {
  const dispatch = useDispatch()
  const usuarioLogado = useSelector(getUsuarioLogado)
  const { cidadeAtiva } = useSelector((s: IStateRedux) => s.contas)
  const { isDespachante } = useSelector((s: IStateRedux) => s.auth)

  useEffect(() => {
    dispatch(contasActions.fetchCidades())
    dispatch(contasActions.getDespachantesParceirosCapitalPlacas())
  }, [])
  useEffect(() => {
    if (usuarioLogado) {
      dispatch(contasActions.fetchContasUsuario(usuarioLogado.Id, usuarioLogado.accId))
      dispatch(contasActions.getContasHipodromo())
      dispatch(authActions.getUsuarioHipodromo(usuarioLogado.Id))
      dispatch(contasActions.fetchCidades())
    }
  }, [usuarioLogado?.id])
  useEffect(() => {
    if (usuarioLogado?.Admin && cidadeAtiva) {
      dispatch(contasActions.fetchContasByCidade(cidadeAtiva))
    }
  }, [usuarioLogado, cidadeAtiva])

  function handleClickLogout() {
    dispatch(authActions.logout())
  }
  // if (!usuarioLogado) {
  //   return (
  //     <Styles.Nav>
  //       <div className="menu">
  //         <Logo />
  //       </div>
  //       <div className="superior-direito">
  //         <Link to="/login">
  //           Efetuar Login <LockOutlined />
  //         </Link>
  //       </div>
  //     </Styles.Nav>
  //   )
  // }

  function handleClickTermoUso(e) {
    e.preventDefault()
    window.open('/termos-e-condicoes-de-uso')
  }

  return (
    <Styles.Nav>
      <div className="menu">
        <Show condition={usuarioLogado}>
          <MenuOutlined onClick={() => setDrawerVisible(true)} />
        </Show>
        {/* <img style={{ height: 32 }} src="emplacafacil-logo-266-48px.png" /> */}
        <Logo />
      </div>
      <div className="superior-direito">
        <a onClick={handleClickTermoUso}> Termos de Uso </a>

        <Show condition={!!usuarioLogado}>
          <Show condition={!isDespachante}>
            <SelecaoConta />
          </Show>

          <Styles.InfoNav> {usuarioLogado?.Nome} </Styles.InfoNav>

          <Popover content="Deslogar" placement="bottomRight">
            <Button shape="circle" icon={<PoweroffOutlined />} onClick={handleClickLogout} />
          </Popover>
        </Show>
        <Show condition={!usuarioLogado}>
          <Link to="/login">
            Efetuar Login <LockOutlined />
          </Link>
        </Show>
      </div>
    </Styles.Nav>
  )
}

const Logo = () => {
  const site = getCurrentSite()
  return (
    <>
      <Show condition={site === 'EMPLACAFACIL'}>
        <img style={{ height: 32 }} src="emplacafacil-logo-266-48px.png" />
      </Show>
      <Show condition={site === 'EMPLACAR_GOIAS'}>
        <img style={{ height: 48 }} src="emplacargoias-logo.png" />
      </Show>
    </>
  )
}

function SelecaoConta() {
  const dispatch = useDispatch()
  const actions = bindActionCreators(contasActions, dispatch)
  const authActionsBinded = bindActionCreators(authActions, dispatch)
  const usuarioLogado = useSelector(getUsuarioLogado)
  const {
    cidadeAtiva,
    contas,
    contaAtiva,
    cidades,
    request: { message, fetching, fetchingContas },
  } = useSelector((s: IStateRedux) => s.contas)
  useEffect(() => {
    if (message) {
      notification.error({ message })
      actions.clearMessage()
    }
  }, [message])
  useEffect(() => {
    if (usuarioLogado?.accId) {
      actions.setContaAtiva({ contaId: usuarioLogado.accId })
    }
  }, [usuarioLogado?.accId])
  function handleOnChangeCidade(cidade) {
    actions.setCidadeAtiva({ cidade })
  }
  function handleOnChangeConta(contaId) {
    // actions.setContaAtiva({ contaId })
    actions.renewTokenStarted()
    authActionsBinded.renewToken(contaId)
  }
  if (fetching)
    return (
      <div>
        <Spin /> Carregando ...{' '}
      </div>
    )
  if (!contaAtiva && !fetching)
    return <div>Nenhuma conta vinculada. Entre em contato com o suporte</div>
  return (
    <>
      <Show condition={usuarioLogado.Admin}>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Escolha uma cidade"
          notFoundContent="Não encontrado"
          onChange={handleOnChangeCidade}
          value={cidadeAtiva as any}
        >
          {cidades.map((c) => (
            <Select.Option key={c.Municipio} value={c.Municipio}>
              {c.Municipio}
            </Select.Option>
          ))}
        </Select>
      </Show>
      <Select
        showSearch
        style={{ width: 300 }}
        placeholder="Escolha uma conta"
        notFoundContent="Não encontrado"
        onChange={handleOnChangeConta}
        value={contaAtiva?.Id}
        loading={fetchingContas}
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {contas.map((c: any) => {
          const numeroConta = `${('' + c.numeroConta).padStart(6, '0')}-${c.numeroContaDv}`
          return (
            <Select.Option key={c.Id} value={c.Id}>
              {~c.Tipo.indexOf('EMPLACADOR')
                ? `${numeroConta} - ${c.Fantasia}`
                : `${numeroConta} - ${c.Fantasia} (F)`}
            </Select.Option>
          )
        })}
      </Select>
    </>
  )
}

const Styles = {
  Nav: styled('div')`
    width: 100%;
    height: 48px;
    background: #f3f3f3;
    display: flex;
    justify-content: space-between;
    .superior-direito,
    .menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      & > * {
        margin-right: 12px;
      }
    }
    .menu {
      margin-left: 24px;
      font-size: 1.3em;
      font-weight: bold;
      a {
        text-decoration: underline;
      }
    }
  `,
  InfoNav: styled('div')`
    padding: 4px 12px;
    /* margin-left: 12px; */
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: white;
  `,
}
