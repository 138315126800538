import { Button, Select, notification, Checkbox, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import styled from '@emotion/styled'

import { Redirect } from 'react-router'
import { bindActionCreators } from 'redux'

import { Emplacador } from '../emplacadores/Emplacadores'
import { FornecedorServico } from './FornecedorServico'

import { IStateRedux } from '../../common/ReduxStore'
import { fornecedorAtivoSelector, emplacadorAtivoSelector } from '../contas/ContasStore'
import { fornecedoresActions } from './FornecedoresStore'
import { getUsuarioLogado } from '../auth/AuthStore'
import axios from '../../common/axios'
import Axios from 'axios'
import { Servico, FornecedorEmplacadorServico } from '../servicos/Servico'
import { createSimpleStore } from 'react-simple-reducer'
import { Show } from '../../common/Show'
import useAxios from 'axios-hooks'
import IntlNumberInput from 'react-intl-number-input'

const Store = createSimpleStore(
  {
    emplacador: undefined as Emplacador | undefined,
    cidade: '',
    servicos: [] as Servico[],
    servicosPadrao: [] as Servico[],
    loadingServicos: false,
  },
  {
    getEmplacadorWithPrecosStarted(state) {
      state.loadingServicos = true
    },
    getEmplacadorWithPrecosSuccess(state, payload: { servicos: Servico[]; valorPadrao: boolean }) {
      state.loadingServicos = false
      if (payload.valorPadrao) {
        state.servicosPadrao = payload.servicos
        return
      }
      state.servicos = payload.servicos
    },
    alteraCidade(state, cidade: string) {
      if (state.cidade !== cidade) state.emplacador = undefined
      state.cidade = cidade
    },
    alteraEmplacador(state, emplacador: Emplacador | undefined) {
      state.emplacador = emplacador
    },
    atualizaValorPadrao(state, fornecedorEmplacadorServico: FornecedorEmplacadorServico) {
      const servico = state.servicosPadrao.find(
        (x) => x.Id === fornecedorEmplacadorServico.Servico_Id
      )
      if (servico?.FornecedoresEmplacadoresServicos?.length) {
        servico.FornecedoresEmplacadoresServicos[0].Valor = fornecedorEmplacadorServico.Valor
      }
      if (servico) {
        servico.FornecedoresEmplacadoresServicos = [fornecedorEmplacadorServico]
      }
    },
  },
  {
    thunks: {
      getEmplacadorWithPrecos(emplacadorId, fornecedorId) {
        return async (dispatch) => {
          dispatch(Store.actions.getEmplacadorWithPrecosStarted())
          const servicos = await Axios.get(`/fornecedores/${fornecedorId}/servicos`, {
            params: { emplacadorId },
          }).then((x) => x.data)
          dispatch(
            Store.actions.getEmplacadorWithPrecosSuccess({ servicos, valorPadrao: !emplacadorId })
          )
        }
      },
    },
  }
)

export const ConfigurarValorFornecedor = () => {
  return (
    <Store.Provider>
      <Styles.Container>
        <h1>Serviços</h1>
        <InfoValorPadrao />
        <SelecaoEmplacador />
        <Servicos />
        <Init />
      </Styles.Container>
    </Store.Provider>
  )
}

const Init = () => {
  const dispatch = Store.useDispatch()
  const usuarioLogado = useSelector(getUsuarioLogado)
  const fornecedorId = useSelector(fornecedorAtivoSelector)
  const emplacadorId = useSelector(emplacadorAtivoSelector)
  useEffect(() => {
    if (fornecedorId) dispatch(Store.thunks.getEmplacadorWithPrecos(null, fornecedorId))
  }, [fornecedorId])
  if (emplacadorId && !fornecedorId) return <Redirect to="/configurar-valor-emplacador" />
  if (!usuarioLogado) return <Redirect to="/" />
  return null
}

const InfoValorPadrao = () => {
  const { emplacador } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleClickValorPadrao() {
    dispatch(Store.actions.alteraEmplacador(undefined))
  }

  if (!emplacador) {
    return (
      <div style={{ padding: '12px 0' }}>
        Você está editando o <strong>VALOR PADRÃO</strong> de cada serviço
      </div>
    )
  }

  return (
    <div style={{ padding: '12px 0' }}>
      Você está editando o valor de um <strong>ESTAMPADOR ESPECIFICO</strong> &nbsp;
      <Button size="small" onClick={handleClickValorPadrao}>
        Editar valor padrão
      </Button>
    </div>
  )
}
export const _ConfigurarValorFornecedor = () => {
  const usuarioLogado = useSelector(getUsuarioLogado)
  const fornecedorAtivo = useSelector(fornecedorAtivoSelector)
  const [, setEmplacador] = useState(null)
  const [, setMudarPrecoEspecifico] = useState(false)
  // const [servicos, setServicos] = useState<FornecedorServico[]>([])

  // const fetchServicos = async (fornecedor, emplacador) => {
  //   const servicos = await axios.Fornecedores.getServicos(fornecedor, emplacador)
  //   setServicos(servicos)
  // }

  // const fetchServicosPadroes = async (fornecedor) => {
  //   const servicos = await axios.Fornecedores.getServicos(fornecedor, null)
  //   setServicosPadroes(servicos)
  // }

  // useEffect(() => {
  //   if (fornecedorAtivo) {
  //     fetchServicosPadroes(fornecedorAtivo)
  //   }
  // }, [fornecedorAtivo, servicoSalvo])

  // useEffect(() => {
  //   if (fornecedorAtivo && emplacador) {
  //     fetchServicos(fornecedorAtivo, emplacador)
  //   }
  // }, [fornecedorAtivo, emplacador, servicoSalvo])

  // useEffect(() => {
  //   if (servicoSalvo) notification.success({ message: 'Serviço salvo com sucesso' })
  //   if (message) notification.error({ message })
  //   actions.clearServicoSalvoMessage()
  // }, [message, servicoSalvo])

  if (!usuarioLogado || !fornecedorAtivo) return <Redirect to="/" />

  // const _servicos = servicosPadroes.map(s => {
  //   let valor = null
  //   let ValorPadrao = _.get(s, 'FornecedorEmplacadorServico[0].Valor', 0)
  //   if (emplacador && servicos.length) {
  //     const servico = servicos.find(servico => s.Id === servico.Id)
  //     valor = _.get(servico, 'FornecedorEmplacadorServico[0].Valor', null)
  //   }

  //   return {
  //     ...s,
  //     Valor: valor || ValorPadrao,
  //     ValorPadrao,
  //   }
  // })

  // console.log({
  //   servicos,
  //   servicosPadroes,
  //   _servicos
  // })

  const mudarPrecoEspecificoChange = (e) => {
    setMudarPrecoEspecifico(e.target.checked)
    if (!e.target.checked) {
      setEmplacador(null)
    }
  }

  return (
    <Styles.Container>
      <h2>Serviços</h2>
      <Checkbox onChange={mudarPrecoEspecificoChange}>
        Alterar o valores de um emplacador específico
      </Checkbox>
      <br />
      {/* {mudarPrecoEspecifico && <SelecaoEmplacador emplacador={emplacador} setEmplacador={setEmplacador} />} */}
      <br />
      <div>
        {/* {servicos.map(s => <ServicoItem emplacadorId={emplacador} key={servicos.id} servico={s} />)} */}
      </div>
    </Styles.Container>
  )
}

const Servicos = () => {
  const { servicos, servicosPadrao, emplacador, loadingServicos } = Store.useState()
  const dispatch = Store.useDispatch()
  const fornecedorId = useSelector(fornecedorAtivoSelector)
  useEffect(() => {
    if (fornecedorId && !!emplacador) {
      dispatch(Store.thunks.getEmplacadorWithPrecos(emplacador?.Id, fornecedorId))
    }
  }, [emplacador, fornecedorId])

  const _servicos = emplacador ? servicos : servicosPadrao
  return (
    <Spin spinning={loadingServicos}>
      <Styles.Servicos>
        {_servicos.map((s) => (
          <ServicoItem servico={s} key={s.Id} />
        ))}
      </Styles.Servicos>
    </Spin>
  )
}

const ServicoItem = ({ servico }: { servico: Servico }) => {
  const { emplacador, servicosPadrao } = Store.useState()
  const dispatch = Store.useDispatch()
  const valorPadrao = _getValorPadrao()
  const [valor, setValor] = useState(servico.FornecedoresEmplacadoresServicos?.[0]?.Valor)
  const [submited, setSubmited] = useState(true)
  const fornecedorId = useSelector(fornecedorAtivoSelector)
  useEffect(() => {
    setValor(servico.FornecedoresEmplacadoresServicos?.[0]?.Valor)
  }, [servico.FornecedoresEmplacadoresServicos])

  function _getValorPadrao() {
    if (!emplacador) return 0
    return (
      servicosPadrao.find((x) => x.Id === servico.Id)?.FornecedoresEmplacadoresServicos?.[0]
        ?.Valor ?? 0
    )
  }

  const [{ loading }, reqCreateOrUpdateValor] = useAxios(
    {
      url: `/fornecedores/${fornecedorId}/servicos`,
      method: 'post',
      data: {
        emplacadorId: emplacador?.Id ?? null,
        servicoId: servico.Id,
        valor,
      },
    },
    { manual: true }
  )

  async function salvarServico() {
    try {
      const valorPadrao = await reqCreateOrUpdateValor().then((x) => x?.data)
      if (!emplacador) dispatch(Store.actions.atualizaValorPadrao(valorPadrao.servico))
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'Erro ao salvar o valor'
      notification.error({ message })
    }
    setSubmited(true)
  }

  const handleChangeValor = (value) => {
    const valor = +value.replace(/[R$ ]/g, '').replace(',', '.')
    setValor(valor)
    setSubmited(false)
  }

  return (
    <Styles.Servico>
      <Styles.ServicoDescricao>
        <div style={{ flex: 1 }}>
          <Styles.Label> Descrição </Styles.Label>
          <div> {servico.Descricao} </div>
        </div>
        <Show condition={valorPadrao}>
          <div>
            <Styles.Label>Valor Padrão</Styles.Label>
            <div> R$ {valorPadrao.toFixed(2).replace('.', ',')} </div>
          </div>
        </Show>
      </Styles.ServicoDescricao>
      <Styles.ServicoValor>
        <Styles.Label> Valor </Styles.Label>
        <IntlNumberInput
          size="large"
          value={valor}
          onChange={(e) => handleChangeValor(e.target.value)}
          placeholder="Valor do serviço"
          locale="pt-BR"
          prefix="R$ "
          precision={2}
        />
      </Styles.ServicoValor>
      <Styles.ServicoAction>
        <Button
          disabled={submited}
          loading={loading}
          size="large"
          type="primary"
          onClick={salvarServico}
        >
          Atualizar preço
        </Button>
      </Styles.ServicoAction>
      <Show condition={valorPadrao && !valor}>
        <div className="info-valor-padrao-utilizado">Valor padrão será utilizado</div>
      </Show>
    </Styles.Servico>
  )
}

const SelecaoEmplacador = () => {
  const cidades = useSelector((s: IStateRedux) => s.contas.cidades)
  const [emplacadores, setEmplacadores] = useState([] as Emplacador[])
  const dispatch = Store.useDispatch()
  const { cidade, emplacador } = Store.useState()

  const handleOnChangeCidade = async (cidade) => {
    dispatch(Store.actions.alteraCidade(cidade))
    const emplacadores = await axios.Emplacadores.getByCidade(cidade)
    setEmplacadores(emplacadores)
  }

  const handleOnChangeEmplacador = (emplacadorId) => {
    const emplacador = emplacadores.find((e) => e.Id === emplacadorId)
    dispatch(Store.actions.alteraEmplacador(emplacador))
  }

  return (
    <Styles.SelecaoEmplacador>
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Escolha uma cidade"
        notFoundContent="Não encontrado"
        onChange={handleOnChangeCidade}
        value={cidade}
      >
        <Select.Option key={0} value={''}>
          Filtre os emplacadores por cidade
        </Select.Option>
        {cidades.map((c) => (
          <Select.Option key={c.Municipio} value={c.Municipio}>
            {c.Municipio}
          </Select.Option>
        ))}
      </Select>
      <Select
        showSearch
        disabled={!emplacadores.length}
        style={{ width: 200 }}
        onChange={handleOnChangeEmplacador}
        value={emplacador?.Id}
        placeholder="Escolha um emplacador"
        notFoundContent="Não encontrado nenhum emplacador"
      >
        {emplacadores.map((c) => {
          return (
            <Select.Option key={c.Id} value={c.Id}>
              {`${c.Id} - ${c.Conta.Fantasia}`}
            </Select.Option>
          )
        })}
      </Select>
    </Styles.SelecaoEmplacador>
  )
}

const Styles = {
  Servicos: styled('div')`
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    margin-top: 12px;
    @media (max-width: 925px) {
      grid-template-columns: 1fr;
    }
  `,
  Label: styled('span')`
    font-weight: bold;
  `,
  ServicoErrorMessage: styled('span')`
    margin-top: 5px;
    font-weight: bold;
    color: red;
  `,
  Container: styled('div', { label: 'Container' })`
    background: #f3f3f3;
    max-width: 1024px;
    margin: 0 auto;
    margin-top: 24px;
    border-radius: 4px;
    padding: 24px;
  `,
  Servico: styled('div')`
    background-color: white;
    padding: 15px;
    display: inline-block;
  `,
  ServicoDescricao: styled('div')`
    width: 100%;
    margin-bottom: 5px;
    display: flex;
  `,
  ServicoValor: styled('div')`
    display: inline-block;
    width: 35%;
  `,
  ServicoAction: styled('div')`
    display: inline-block;
    margin-left: 30%;
    width: 25%;
  `,
  SelecaoEmplacador: styled('div')`
    display: flex;
    & > * {
      flex: 1;
    }
  `,
}
