import React, { useState, useEffect } from 'react'
import {
  NovoPedidoProvider,
  useNovoPedidoDispatch,
  useNovoPedidoState,
  createPedido,
  getValorMaximo,
} from './NovoPedidoStore'
import { PagamentoMercadoPago } from '../../pagamentos/Pagamento'
import styled from '@emotion/styled'
import { Wizard } from '../../../common/Wizard'
import { Input, Button, Card, Radio, Select, notification, Spin, Modal } from 'antd'
import useAxios from 'axios-hooks'
import { Show } from '../../../common/Show'
import * as yup from 'yup'
import { useFormik, FormikProvider } from 'formik'
import { TextField } from '../../../common/TextField'
import { IStateRedux } from '../../../common/ReduxStore'
import { useSelector } from 'react-redux'
import { Emplacador } from '../../emplacadores/Emplacadores'
import { Servico } from '../../servicos/Servico'
import { useNotifyError } from '../../../common/useNofityError'
import { isValid as isValidCpf } from '@fnando/cpf'
import { isValid as isValidCnpj } from '@fnando/cnpj'
import { get as _get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { getUsuarioLogado } from '../../auth/AuthStore'
import { emplacadorAtivoSelector } from '../../contas/ContasStore'
import IntlNumberInput from 'react-intl-number-input'
import { getCurrentSite } from '../../../common/getCurrentSite'
import { HyperpayCobranca } from '@hyperdev-tech/hyperpay-cobranca'
import axios from 'axios'

const estadoSigla = [
  { estado: 'Acre', sigla: 'AC' },
  { estado: 'Alagoas', sigla: 'AL' },
  { estado: 'Amapá', sigla: 'AP' },
  { estado: 'Amazonas', sigla: 'AM' },
  { estado: 'Bahia', sigla: 'BA' },
  { estado: 'Ceará', sigla: 'CE' },
  { estado: 'Distrito Federal', sigla: 'DF' },
  { estado: 'Espírito Santo', sigla: 'ES' },
  { estado: 'Goiás', sigla: 'GO' },
  { estado: 'Maranhão', sigla: 'MA' },
  { estado: 'Mato Grosso', sigla: 'MT' },
  { estado: 'Mato Grosso do Sul', sigla: 'MS' },
  { estado: 'Minas Gerais', sigla: 'MG' },
  { estado: 'Pará', sigla: 'PA' },
  { estado: 'Paraíba', sigla: 'PB' },
  { estado: 'Paraná', sigla: 'PR' },
  { estado: 'Pernambuco', sigla: 'PE' },
  { estado: 'Piauí', sigla: 'PI' },
  { estado: 'Rio de Janeiro', sigla: 'RJ' },
  { estado: 'Rio Grande do Norte', sigla: 'RN' },
  { estado: 'Rio Grande do Sul', sigla: 'RS' },
  { estado: 'Rondônia', sigla: 'RO' },
  { estado: 'Roraima', sigla: 'RR' },
  { estado: 'Santa Catarina', sigla: 'SC' },
  { estado: 'São Paulo', sigla: 'SP' },
  { estado: 'Sergipe', sigla: 'SE' },
  { estado: 'Tocantins', sigla: 'TO' },
]

const CAPITAL_PLACAS_ID = 100698

export const NovoPedido = () => {
  return (
    <NovoPedidoProvider>
      <Styles.Container>
        <PassoAPasso />

        <WizardPedido>
          <div>
            <CodigoAutorizacao>
              <DadosEmplacamento />
            </CodigoAutorizacao>
            <EfetuarAgendamento />
          </div>

          <FormSolicitante />

          <GetTipoSelecaoEmplacador>
            {(tipoSelecaoEmplacador) => (
              <>
                <Show condition={tipoSelecaoEmplacador === 'PARCEIRO_CAPITAL_PLACAS'}>
                  <CapitalPlacas />
                </Show>

                <Show condition={tipoSelecaoEmplacador === 'EMPLACADOR_LOGADO'}>
                  <ValorEmplacadorLogado />
                </Show>

                <Show condition={tipoSelecaoEmplacador === 'DESPACHANTE_APROVISIONAMENTO'}>
                  <EmplacadorSelecionadoDespachante />
                </Show>

                <Show condition={tipoSelecaoEmplacador === ''}>
                  <InformaCidade>
                    <Emplacadores />
                  </InformaCidade>
                </Show>
              </>
            )}
          </GetTipoSelecaoEmplacador>

          <PagamentoNovoPedido />
        </WizardPedido>
      </Styles.Container>
    </NovoPedidoProvider>
  )
}

const EmplacadorSelecionadoDespachante = () => {
  const state = useNovoPedidoState()
  const dispatch = useNovoPedidoDispatch()
  const { autorizacaoEmplacamento, estado, createPedidoLoading } = state
  const usuario = useSelector(getUsuarioLogado)

  const [{ loading, data: emplacamentoLiberado }, refetch] = useAxios({
    url: '/despachantes/emplacador-liberado',
    params: {
      servico: autorizacaoEmplacamento?.servicos[0].Descricao,
      municipio: state.cidade,
      despachanteId: usuario?.Id,
    },
  })
  const conta = emplacamentoLiberado?.conta
  const valor = emplacamentoLiberado?.valor ?? 0

  if (loading) return <div>Carregando...</div>

  // if (desacentua(autorizacaoEmplacamento?.municipio.municipio) !== desacentua(state.cidade))
  //   return (
  //     <div>
  //       <div>Não é possível alterar o município da autorização</div>
  //       <BotaoCancelar />
  //     </div>
  //   )

  if (!emplacamentoLiberado)
    return (
      <div>
        Ocorreu um erro ao buscar os dados para emplacamento.
        <Button onClick={() => refetch()}>Tentar novamente</Button>
      </div>
    )

  return (
    <div>
      <Spin tip="Criando pedido" spinning={createPedidoLoading}>
        Emplacamento liberado na <strong>{conta?.Fantasia}</strong>
        <br />
        Valor: R$ {valor?.toFixed(2).replace('.', ',')}
        <Button
          block
          type="primary"
          onClick={async () =>
            await createPedido(
              {
                ...state,
                emplacadorSelecionadoId: emplacamentoLiberado.emplacador.Id,
              },
              dispatch,
              {
                valor,
                usuarioId: usuario?.Id,
                despachanteLogado: true,
              }
            )
          }
          style={{ marginTop: 12 }}
        >
          Criar Pedido
        </Button>
      </Spin>
      <BotaoCancelar />
    </div>
  )
}

const CapitalPlacas = () => {
  const state = useNovoPedidoState()
  const dispatch = useNovoPedidoDispatch()
  const { autorizacaoEmplacamento, estado, createPedidoLoading } = state
  const Valor =
    autorizacaoEmplacamento?.servicos
      .map((s: Servico) => {
        return s.EstadosServicos?.find((x) => x.UF === estado)?.ValorTeto ?? 0
      })
      .reduce((prev, curr) => prev + curr, 0) ?? 0
  const usuario = useSelector(getUsuarioLogado)

  const stateCapitalSelecionada = {
    ...state,
    emplacadorSelecionadoId: CAPITAL_PLACAS_ID as any,
  }

  return (
    <div>
      <Spin tip="Criando pedido" spinning={createPedidoLoading}>
        Emplacamento autorizado na CAPITAL PLACAS
        <br />
        Valor: R$ {Valor.toFixed(2).replace('.', ',')}
        <Button
          block
          type="primary"
          onClick={async () =>
            await createPedido(stateCapitalSelecionada, dispatch, {
              valor: Valor,
              emplacadorLogado: true,
              usuarioId: usuario?.Id,
            })
          }
          style={{ marginTop: 12 }}
        >
          Criar Pedido
        </Button>
      </Spin>
      <BotaoCancelar />
    </div>
  )
}

const GetTipoSelecaoEmplacador = ({ children }) => {
  const site = getCurrentSite()

  const emplacadorSelecionadoId = useSelector(emplacadorAtivoSelector)
  const despachantesParceirosCapitalPlacas = useSelector((s: IStateRedux) => {
    return s.contas.despachantesParceirosCapitalPlacas
  })
  const isDespachante = useSelector((s: IStateRedux) => {
    return s.auth.isDespachante
  })
  const state = useNovoPedidoState()

  const codgDespachante = +state.autorizacaoEmplacamento?.proprietario.codgDespachante

  const isCidadeParticipanteAprovisionamento = ['ANÁPOLIS', 'ANAPOLIS'].includes(state.cidade ?? '')

  let tipoSelecaoEmplacador = '' as
    | 'EMPLACADOR_LOGADO'
    | 'PARCEIRO_CAPITAL_PLACAS'
    | 'DESPACHANTE_APROVISIONAMENTO'
    | ''

  if (site === 'EMPLACAR_GOIAS' && despachantesParceirosCapitalPlacas.includes(codgDespachante)) {
    tipoSelecaoEmplacador = 'PARCEIRO_CAPITAL_PLACAS'
  } else if (isDespachante && isCidadeParticipanteAprovisionamento) {
    tipoSelecaoEmplacador = 'DESPACHANTE_APROVISIONAMENTO'
  } else if (emplacadorSelecionadoId) {
    tipoSelecaoEmplacador = 'EMPLACADOR_LOGADO'
  }

  return children(tipoSelecaoEmplacador)
}

const PassoAPasso = () => {
  const site = getCurrentSite()
  const cores =
    site === 'EMPLACAR_GOIAS'
      ? { from: '#a7ffd5', to: '#00ce6b' }
      : { from: '#ffe982', to: '#ff7300' }
  return (
    <Styles.BemVindo>
      <h1 style={{ marginBottom: 3 }}>Bem Vindo!</h1>
      <h3>
        Siga o passo-a-passo abaixo
        <br />e cadastre sua solicitação de emplacamento
      </h3>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Styles.NumerosContainer cores={cores}>
          <Passo label="INSERIR" labelSecondary="CÓDIGO" numero={1} />
          <div className="divisor"></div>
          <Passo label="INFORMAR" labelSecondary="SOLICITANTE" numero={2} />
          <div className="divisor"></div>
          <Passo label="SELECIONAR" labelSecondary="EMPLACADOR" numero={3} />
          <div className="divisor"></div>
          <Passo label="EFETUAR" labelSecondary="PAGAMENTO" numero={4} />
          <div className="divisor"></div>
          <Styles.NovoPasso>
            {/* <div className="novo">Novo</div> */}
            <Passo label="AGENDAR" labelSecondary="EMPLACAMENTO" numero={5} />
          </Styles.NovoPasso>
        </Styles.NumerosContainer>
      </div>
    </Styles.BemVindo>
  )
}

const Passo = ({ label, labelSecondary, numero }) => {
  const { passoAtual } = useNovoPedidoState()
  return (
    <div className={'container-info ' + (numero <= passoAtual ? 'active' : 'inactive')}>
      <div className="numero-container">{numero}</div>
      <div className="label">{label}</div>
      <div className="label-secondary">{labelSecondary}</div>
    </div>
  )
}

const WizardPedido = ({ children }) => {
  const { passoAtual } = useNovoPedidoState()
  return <Wizard step={passoAtual - 1}>{children}</Wizard>
}

const CodigoAutorizacao = ({ children: dadosEmplacamentoComponent }) => {
  const dispatch = useNovoPedidoDispatch()
  const usuario = useSelector(getUsuarioLogado)
  const { codigoAutorizacao } = useNovoPedidoState()
  const history = useHistory()
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `/busca-dados-detran/${codigoAutorizacao.replace(/^0+/, '')}`,
      params: { usuarioId: usuario?.Id },
    },
    { manual: true }
  )
  useNotifyError(error, 'Ocorreu um erro ao buscar autorização de emplacamento')
  useEffect(() => {
    if (!data) return
    const { pedidoExistente, autorizacaoEmplacamento } = data
    if (pedidoExistente) {
      handleRetornoBuscaPedidoExistente(pedidoExistente, history, dispatch)
    } else if (autorizacaoEmplacamento) {
      dispatch({ type: 'successGetAutorizacao', autorizacaoEmplacamento })
    }
  }, [data])

  useEffect(() => {
    const _codigoAutorizacao = codigoAutorizacao.replace(/^0+/, '')
    const isCodigoMercosul =
      _codigoAutorizacao.length === 15 && _codigoAutorizacao.indexOf('2020') === 0
    const isCodigoDetran = _codigoAutorizacao.length >= 21
    if (isCodigoDetran || isCodigoMercosul) void refetch()
  }, [codigoAutorizacao])

  return (
    <Styles.ContainerSlide>
      <Styles.ContainerInputCodigoAutorizacao>
        <Input
          autoFocus
          disabled={loading}
          size="large"
          onChange={(e) =>
            dispatch({ type: 'alteraCodigoAutorizacao', codigoAutorizacao: e.target.value })
          }
          value={codigoAutorizacao}
          placeholder="Código de autorização"
        />
        <Button loading={loading} size="large" type="primary" onClick={async () => await refetch()}>
          Buscar
        </Button>
      </Styles.ContainerInputCodigoAutorizacao>
      <div style={{ fontSize: '1.3em', marginTop: 8 }}>
        Informe o <strong>Código de Autorização </strong> e clique em <strong>Buscar</strong>{' '}
      </div>
      <div>{dadosEmplacamentoComponent}</div>
    </Styles.ContainerSlide>
  )
}

const EfetuarAgendamento = () => {
  const [placa, setPlaca] = useState('')
  const [{ loading }, getInfoPagamento] = useAxios(`/info-pagamento/${placa}`, {
    manual: true,
  })
  const history = useHistory()
  const dispatch = useNovoPedidoDispatch()

  async function handleAgendar() {
    try {
      const infoPagamento = await getInfoPagamento().then((x) => x.data)
      if (!infoPagamento || !infoPagamento.length) {
        return notification.error({
          message:
            'Ainda não existe pedido criado para essa placa. Informe o código de autorização no campo acima',
        })
      }
      handleRetornoBuscaPedidoExistente(infoPagamento[0], history, dispatch)
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao buscar os dados da placa'
      notification.error({ message })
    }
  }

  return (
    <Styles.EfetuarAgendamento>
      <h3>Já efetuou o pagamento? Agende seu serviço agora</h3>
      <div className="input-button">
        <label>
          <Input
            value={placa}
            onChange={(e) => setPlaca(e.target.value)}
            placeholder="Insira a placa do seu veículo"
            size="large"
          />
        </label>
        <Button size="large" onClick={handleAgendar} loading={loading}>
          Agendar
        </Button>
      </div>
    </Styles.EfetuarAgendamento>
  )
}

const Cell = ({ label, info }) => {
  if (info == null) return null
  return (
    <Styles.Cell>
      <Styles.CellLabel>{label}:</Styles.CellLabel>
      <Styles.CellInfo>{info}</Styles.CellInfo>
    </Styles.Cell>
  )
}

const DadosEmplacamento = () => {
  const { autorizacaoEmplacamento } = useNovoPedidoState()
  const dispatch = useNovoPedidoDispatch()

  if (!autorizacaoEmplacamento) return null

  return (
    <div>
      <Card title="Dados da Autorização" size="small" style={{ marginTop: 12 }}>
        <Styles.Placa> {autorizacaoEmplacamento.placa} </Styles.Placa>
        <Styles.Servico>
          {' '}
          {autorizacaoEmplacamento.servicos.map((x) => x.Descricao).join(', ')}{' '}
        </Styles.Servico>
        <Cell label="Chassi" info={autorizacaoEmplacamento.veiculo.codgChassi} />
        <Cell label="Marca/Modelo" info={autorizacaoEmplacamento.veiculo.descMarcaModelo} />
        <Show condition={autorizacaoEmplacamento.proprietario.codgDespachante}>
          <Cell label="Despachante" info={autorizacaoEmplacamento.proprietario.nomeDespachante} />
        </Show>
      </Card>
      <Button
        block
        size="large"
        style={{ marginTop: 12 }}
        type="primary"
        onClick={() => dispatch({ type: 'alteraPasso', numero: 2 })}
      >
        Próxima etapa
      </Button>
    </div>
  )
}

const FormSolicitante = () => {
  const { dadosSolicitante } = useNovoPedidoState()
  const dispatch = useNovoPedidoDispatch()
  const formSolicitanteSchema = yup.object().shape({
    tipoPessoa: yup.string(),
    nome: yup.string().required('O nome do solicitante é obrigatório'),
    cpfCnpj: yup.string().when('tipoPessoa', {
      is: 'FISICA',
      then: yup
        .string()
        .test('cpf', 'CPF Inválido', isValidCpf as any)
        .required('O CPF do solicitante é obrigatório'),
      otherwise: yup
        .string()
        .test('cnpj', 'CNPJ Inválido', isValidCnpj as any)
        .required('O CNPJ é obrigatório'),
    }),
  })

  const formik = useFormik({
    initialValues: dadosSolicitante,
    isInitialValid: !!dadosSolicitante && !!dadosSolicitante.cpfCnpj,
    enableReinitialize: true,
    validationSchema: formSolicitanteSchema,
    onSubmit: (dadosSolicitante) => {
      dispatch({ type: 'preencheDadosSolicitante', dadosSolicitante })
    },
  })

  const cpfCnpjLabel = formik.values.tipoPessoa === 'FISICA' ? 'CPF' : 'CNPJ'
  return (
    <Styles.ContainerSlide>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <label>
              <Radio
                checked={formik.values.tipoPessoa === 'FISICA'}
                onChange={async () => await formik.setFieldValue('tipoPessoa', 'FISICA')}
              />
              Pessoa Física
            </label>
            <label style={{ marginLeft: 12 }}>
              <Radio
                checked={formik.values.tipoPessoa === 'JURIDICA'}
                onChange={async () => await formik.setFieldValue('tipoPessoa', 'JURIDICA')}
              />
              Pessoa Jurídica
            </label>
          </div>
          <label style={{ marginTop: 12 }}>
            Nome
            <TextField
              name="nome"
              type="text"
              placeholder="Informe o nome do solicitante"
              size="large"
            />
          </label>
          <label style={{ marginTop: 12 }}>
            {cpfCnpjLabel}
            <TextField
              name="cpfCnpj"
              mask={cpfCnpjLabel.toLowerCase()}
              placeholder={`Informe o ${cpfCnpjLabel}`}
              size="large"
            />
          </label>
          <label style={{ marginTop: 12 }}>
            Telefone
            <TextField
              name="telefone"
              mask="telefone"
              placeholder="Informar o telefone é opcional"
              size="large"
            />
          </label>
          <label style={{ marginTop: 12 }}>
            Local de Emplacamento
            <TextField
              name="localEmplacamento"
              placeholder="Informar o local de emplacamento é opcional"
              size="large"
            />
          </label>

          <Button
            block
            type="primary"
            size="large"
            htmlType="submit"
            disabled={!formik.isValid}
            style={{ marginTop: 12 }}
          >
            {' '}
            Próxima Etapa
          </Button>
        </form>
      </FormikProvider>
      <BotaoCancelar />
    </Styles.ContainerSlide>
  )
}

const InformaCidade = ({ children: emplacadoresComponent }) => {
  const { cidades } = useSelector((s: IStateRedux) => s.contas)
  const { estado, cidade } = useNovoPedidoState()
  const estados = estadoSigla.filter((e) => cidades.find((c) => c.UF === e.sigla))
  const cidadesDoEstado = cidades.filter((c) => c.UF === estado).map((x) => x.Municipio)
  const dispatch = useNovoPedidoDispatch()
  function alteraEstado(novoEstado) {
    const novaCidade = cidades.find((c) => c.UF === novoEstado)?.Municipio
    dispatch({ type: 'alteraEstado', payload: { cidade: novaCidade, estado: novoEstado } })
  }
  return (
    <Styles.ContainerSlide>
      <label style={{ fontSize: '1.4em' }}>Selecione o estado de emplacamento</label>
      <Select
        style={{ width: '100%', marginBottom: 16 }}
        placeholder="Selecione o estado"
        onChange={alteraEstado}
        value={estado}
      >
        {estados.map((e) => (
          <Select.Option key={e.sigla} value={e.sigla}>
            {e.estado}
          </Select.Option>
        ))}
      </Select>
      <label style={{ fontSize: '1.4em' }}>Selecione uma cidade para exibir os emplacadores</label>
      <Select
        showSearch
        style={{ width: '100%', marginBottom: 16 }}
        placeholder="Escolha a cidade"
        notFoundContent="Não encontrado"
        onChange={(cidade) => dispatch({ type: 'alteraCidade', cidade })}
        size="large"
        value={cidade}
      >
        {cidadesDoEstado.map((c) => (
          <Select.Option key={c} value={c}>
            {c}
          </Select.Option>
        ))}
      </Select>
      <div>{emplacadoresComponent}</div>
    </Styles.ContainerSlide>
  )
}

const Emplacadores = () => {
  const sistema = getCurrentSite()
  const dispatch = useNovoPedidoDispatch()
  const state = useNovoPedidoState()
  const {
    cidade,
    autorizacaoEmplacamento,
    emplacadorSelecionadoId,
    errorMessage,
    createPedidoLoading,
    estado,
  } = state

  const [
    { data: dataGetEmplacadores, loading: loadingGetEmplacadores, error: erroGetEmplacadores },
    fetch,
  ] = useAxios(
    {
      url: '/',
      method: 'GET',
      params: { cidade, sistema },
    },
    {
      manual: true,
    }
  )

  useNotifyError(erroGetEmplacadores, 'Ocorreu um erro ao buscar os emplacadores')
  useEffect(() => {
    if (errorMessage) notification.error({ message: errorMessage })
    dispatch({ type: 'limpaErro' })
  }, [errorMessage])

  useEffect(() => {
    if (cidade) void fetch()
  }, [cidade])

  const [{ data: valorFlow, error: errorFlow }] = useAxios(
    {
      url: `${process.env.REACT_APP_HYPERFLOW_URL}/flows/execute/4`,
      method: 'POST',
      data: {
        cidade: cidade?.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        servico: '' + autorizacaoEmplacamento?.servicos[0].Id,
        cnpjEmpresa: 'null',
      },
    },
    {
      useCache: false,
    }
  )

  const emplacadores = !dataGetEmplacadores
    ? []
    : dataGetEmplacadores
        .map((emplacador) => {
          const Valor =
            autorizacaoEmplacamento?.servicos
              .map((s: Servico) => {
                if (valorFlow?.valor && !errorFlow) return +valorFlow.valor

                let valorPadraoEstado =
                  s.EstadosServicos?.find((x) => x.UF === estado)?.ValorTeto ?? 0
                if (s.Id === 9 && isGoianiaAparecidaAnapolis(emplacador.Conta))
                  valorPadraoEstado = 237
                return (
                  emplacador.EmplacadoresServicos.find((x) => x.Servico_Id === s.Id)?.Valor ??
                  valorPadraoEstado
                )
              })
              .reduce((prev, curr) => prev + curr, 0) ?? 0

          return { ...emplacador, Valor }
        })
        .sort((a, b) => {
          if (a.Valor === b.Valor) return a.RandomOrder! > b.RandomOrder! ? 1 : -1
          return a.Valor > b.Valor ? 1 : -1
        })

  useEffect(() => {
    if (emplacadores.length && !emplacadorSelecionadoId && (valorFlow || errorFlow)) {
      dispatch({
        type: 'selecionaEmplacador',
        emplacadorSelecionadoId: emplacadores[0].Id,
      })
    }
  }, [emplacadores, valorFlow, errorFlow])

  function handleClickEmplacador(emplacador) {
    dispatch({ type: 'selecionaEmplacador', emplacadorSelecionadoId: emplacador.Id })
  }

  if (loadingGetEmplacadores) {
    return <div>Carregando Emplacadores ...</div>
  }

  if (!emplacadores.length) {
    return (
      <div>
        <p>Nenhum emplacador encontrado na cidade</p>
        <p>Selecione uma cidade diferente na lista acima</p>
        <BotaoCancelar />
      </div>
    )
  }

  return (
    <div>
      <div>
        Selecione um emplacador e clique em <strong>Criar Pedido</strong>
      </div>
      <Spin tip="Criando pedido" spinning={createPedidoLoading}>
        {emplacadores.map((e) => (
          <ItemListaEmplacador
            key={e.Id}
            emplacador={e}
            emplacadorSelecionadoId={emplacadorSelecionadoId}
            onClick={() => handleClickEmplacador(e)}
          />
        ))}
        <Button
          block
          type="primary"
          onClick={async () =>
            await createPedido(state, dispatch, {
              valor: !errorFlow && valorFlow?.valor ? +valorFlow.valor : undefined,
              tipo: !errorFlow && valorFlow?.valor ? 'EMPLACAFACIL_MERCADOPAGO' : undefined,
            })
          }
          style={{ marginTop: 12 }}
        >
          Criar Pedido
        </Button>
      </Spin>
      <BotaoCancelar />
    </div>
  )
}

const ValorEmplacadorLogado = () => {
  const state = useNovoPedidoState()
  const dispatch = useNovoPedidoDispatch()
  const { createPedidoLoading, autorizacaoEmplacamento } = state
  const emplacadorId = useSelector(emplacadorAtivoSelector)

  const usuario = useSelector(getUsuarioLogado)

  const [valor, setValor] = useState('')

  const [{ data, loading }] = useAxios(`/servicos/precos/${emplacadorId}`, {
    manual: !emplacadorId,
  })

  useEffect(() => {
    if (data && autorizacaoEmplacamento) {
      const servico = data.find((x) => x.Id === autorizacaoEmplacamento?.servicos[0].Id)
      const _valor =
        servico.EmplacadoresServicos[0]?.Valor ?? servico.EstadosServicos[0].ValorTeto ?? 0
      setValor(_valor)
    }
  }, [data, autorizacaoEmplacamento])

  function handleChangeValor(e) {
    setValor(e.target.value)
  }

  function _parseValor(valor) {
    if (!isNaN(valor)) return valor
    return +valor.replace(/[R$ ]/g, '').replace(',', '.')
  }

  return (
    <div>
      <Spin tip="Buscando valor padrão" spinning={loading}>
        <Spin tip="Criando pedido" spinning={createPedidoLoading}>
          <label>
            Valor
            <br />
            <IntlNumberInput
              size="large"
              value={valor}
              onChange={handleChangeValor}
              placeholder="Valor"
              locale="pt-BR"
              prefix="R$ "
              className="ant-input ant-input-lg"
              precision={2}
            />
          </label>
          <Button
            block
            type="primary"
            onClick={() => {
              dispatch({
                type: 'selecionaEmplacador',
                emplacadorSelecionadoId: emplacadorId,
              })
              setTimeout(() => {
                void createPedido(state, dispatch, {
                  valor: _parseValor(valor),
                  emplacadorLogado: true,
                  usuarioId: usuario?.Id,
                })
              }, 100)
            }}
            style={{ marginTop: 12 }}
          >
            Criar Pedido
          </Button>
        </Spin>
      </Spin>
      <BotaoCancelar />
    </div>
  )
}

function ItemListaEmplacador({
  emplacador,
  emplacadorSelecionadoId,
  onClick,
}: {
  emplacador: Emplacador
  emplacadorSelecionadoId
  onClick?
}) {
  return (
    <Styles.ItemListaEmplacador onClick={onClick}>
      <div style={{ alignSelf: 'center' }}>
        <Styles.Radio checked={emplacadorSelecionadoId === emplacador.Id} />
      </div>
      <div style={{ flex: 1 }}>
        <div className="nome-emplacador">{emplacador.Conta.Fantasia}</div>
        <div>
          {emplacador.Conta.Endereco},{emplacador.Conta.Setor},
          {emplacador.Conta.Numero || emplacador.Conta.Complemento}
        </div>
      </div>
      <div className="valor">R$ {emplacador.Valor!.toFixed(2).replace('.', ',')}</div>
    </Styles.ItemListaEmplacador>
  )
}

const PagamentoNovoPedido = () => {
  const history = useHistory()
  const { pedidoExistente, passoAtual, estado } = useNovoPedidoState()
  const dispatch = useNovoPedidoDispatch()
  const [{ data: servicos }] = useAxios('/servicos')
  const [pagamentoEfetuado, setPagamentoEfetuado] = React.useState(false)
  const valorMaximo = getValorMaximo(pedidoExistente?.PedidosServicos, servicos, estado)

  function reset() {
    dispatch({ type: 'alteraCodigoAutorizacao', codigoAutorizacao: '' })
  }

  useEffect(() => {
    if (passoAtual === 4) {
      // Modal.info({
      //   content: (
      //     <>
      //       <h2 color="carmesin">ATENÇÃO!</h2>
      //       <p>
      //         É necessário AGENDAR seu emplacamento <strong>antes</strong> de ir
      //         à emplacadora.
      //       </p>
      //       <p>
      //         <em>
      //           Após efetuar o pagamento retorne aqui para agendar seu serviço
      //         </em>
      //       </p>
      //     </>
      //   ),
      //   okText: "Li a nota e entendi",
      //   keyboard: false,
      // });
      document.scrollingElement?.scrollTo?.(0, 0)
    }
  }, [passoAtual])

  async function handleAbrirAgendamento() {
    const infoPagamento = await axios(`/info-pagamento/${pedidoExistente?.Placa}`).then(
      (x) => x.data
    )
    if (!infoPagamento || !infoPagamento.length) return
    handleRetornoBuscaPedidoExistente(infoPagamento[0], history, dispatch)
  }

  const boletoId = pedidoExistente?.Boleto?.id || pedidoExistente?.Boleto?.Id

  return (
    <Styles.ContainerSlide>
      {pedidoExistente?.Boleto.Tipo === 'EMPLACAFACIL_MERCADOPAGO' ? (
        <PagamentoMercadoPago boleto={pedidoExistente?.Boleto} />
      ) : (
        <HyperpayCobranca
          cobrancaId={boletoId}
          token="19bd9aee-fa9d-4e66-a4e3-300ad54acdd5"
          theme="EMPLACAFACIL"
          defaultOpened="PIX"
          env={process.env.REACT_APP_ENV || (process.env.NODE_ENV as any)}
          onCobrancaSuccess={async (tipoPagamento) => {
            if (tipoPagamento === 'BOLETO') return
            setPagamentoEfetuado(true)
          }}
        />
      )}

      <Show condition={pagamentoEfetuado}>
        <div style={{ textAlign: 'center' }}>
          <Button type="primary" size="large" onClick={handleAbrirAgendamento}>
            Agendar o emplacamento
          </Button>
        </div>
      </Show>

      <BotaoCancelar label="Voltar ao início" />
    </Styles.ContainerSlide>
  )
}

const BotaoCancelar = ({ label = 'Cancelar' }: { label?: string }) => {
  const dispatch = useNovoPedidoDispatch()
  return (
    <div style={{ textAlign: 'right', marginTop: 12 }}>
      <Button onClick={() => dispatch({ type: 'alteraCodigoAutorizacao', codigoAutorizacao: '' })}>
        {label}
      </Button>
    </div>
  )
}

function handleRetornoBuscaPedidoExistente(
  pedido,
  history: ReturnType<typeof useHistory>,
  dispatch: ReturnType<typeof useNovoPedidoDispatch>
) {
  if (pedido.Data_Agendamento) {
    const data = pedido.Data_Agendamento
    Modal.confirm({
      title: 'Agendamento existente',
      content: (
        <>
          <p>
            Emplacamento agendado para o dia <strong>{format(parseISO(data), 'dd/MM')}</strong> às{' '}
            <strong>{format(parseISO(data), 'hh:mm')}</strong>
          </p>
          <p>Deseja alterar a data do agendamento?</p>
        </>
      ),
      onOk: () => {
        history.push('/agendamento', { boleto: pedido.Boleto })
      },
      okText: 'Sim',
      cancelText: 'Não',
    })
  } else if (pedido.Boleto?.Pago) {
    history.push('/agendamento', { boleto: pedido.Boleto })
  } else {
    dispatch({ type: 'pedidoExistente', pedidoExistente: pedido })
  }
}

const Styles = {
  ContainerInfoDetranServicos: styled('div', {
    label: 'ContainerInfoDetranServicos',
  })`
    display: flex;
    flex-direction: column;
    & > div:first-child {
      margin: 0 0 12px 0;
    }
    & > div:nth-child(2) {
      flex: 1;
    }
    @media (min-width: 768px) {
      flex-direction: row;
      & > div:first-child {
        margin: 0 0 0 12px;
      }
    }
  `,
  ContainerInputCodigoAutorizacao: styled('div', {
    label: 'ContainerInputCodigoAutorizacao',
  })`
    display: flex;
    flex-direction: column;
    Button {
      margin: 12px 0 0 0;
    }
    @media (min-width: 425px) {
      flex-direction: row;
      Button {
        margin: 0 0 0 12px;
      }
    }
  `,
  Container: styled('div', { label: 'Container' })`
    background: #f3f3f3;
    max-width: 1024px;
    margin: 0 auto;
    margin-top: 24px;
    border-radius: 4px;
    padding: 24px;
    @media (max-width: 425px) {
      padding: 12px 0;
    }
  `,
  InfoDivider: styled('div', { label: 'InfoDivider' })`
    font-style: italic;
    text-align: center;
    margin-bottom: 24px;
  `,
  ItemListaEmplacador: styled('div', { label: 'ItemListaEmplacador' })`
    padding: 12px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &:hover {
      background: #efefef;
    }
    .nome-emplacador,
    .valor {
      font-size: 1.4em;
      font-weight: bold;
    }
  `,
  Cell: styled('div', { label: 'Cell' })`
    display: flex;
    font-size: 1.5em;
    margin-bottom: 4px;
    white-space: nowrap;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  `,
  CellLabel: styled('div', { label: 'CellLabel' })`
    width: 150px;
    text-align: right;
    margin-right: 12px;
    @media (max-width: 600px) {
      text-align: left;
    }
  `,
  CellInfo: styled('div', { label: 'CellInfo' })`
    flex: 1;
    font-weight: bold;
  `,
  ServicoItem: styled('div', { label: 'ServicoItem' })`
    margin-bottom: 12px;
    font-size: 1.4em;
    display: flex;
    align-items: center;
    i {
      color: green;
      font-size: 1.5em;
      margin-right: 12px;
    }
  `,
  ExibirTodosEmplacadores: styled('div', { label: 'ExibirTodosEmplacadores' })`
    color: blue;
    text-decoration: underline;
    font-size: 0.8em;
    cursor: pointer;
    text-align: right;
    margin-top: 24px;
  `,
  Radio: styled('div', { label: 'Radio' })<{ checked }>`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid rgb(217, 217, 217);
    background: white;
    margin-right: 12px;
    ${(props) =>
      props.checked
        ? `
      border-color: green;
      &:after {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: green;
        content: ' ';
        display: block;
        margin-top: 6px;
        margin-left: 6px;
      }
    `
        : ''}
  `,
  BemVindo: styled('div')`
    text-align: center;
    @media (max-width: 425px) {
      font-size: 0.8em;
    }
  `,
  NumerosContainer: styled('div')<{ cores }>(
    (props) => `
    display: flex;
    .container-info {
      text-align: center;
      width: 120px;
      margin: 24px 0;
    }
    .numero-container {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.2em;
      margin: 0 auto;
    }
    .label {
      font-weight: bold;
      font-size: .9em;
      line-height: 1.4;
    }
    .label-secondary {
      font-size: .8em;
      line-height: 1.4;
    }
    .divisor {
      background: #ccc;
      width: 70px;
      height: 2px;
      margin-top: 48px;
      @media (max-width: 540px) {
        width: 8px;
        margin-top: 48px;
      }
    }
    .active .numero-container {
      background-image: linear-gradient(to bottom left, ${props.cores.from}, ${props.cores.to});
    }
    .inactive .numero-container {
      background: #ccc;
    }
    @media (max-width: 425px) {
      .numero-container {
        width: 36px;
        height: 36px;
        font-size: 1.8em;
      }
      .container-info {
        width: 50px;
      }
      .label {
        font-size: .8em;
      }
      .label-secondary {
        font-size: .7em;
      }
      .divisor {
        width: 8px;
        margin-top: 48px;
      }
    }
  `
  ),
  CarregandoCentralizado: styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
  `,
  Placa: styled('div')`
    font-weight: bold;
    text-align: center;
    margin: 4px 0;
    font-size: 2.5em;
  `,
  Servico: styled('div')`
    text-align: center;
    margin: 4px 0;
    font-size: 1.5em;
  `,
  ContainerSlide: styled('div')`
    padding: 12px;
  `,
  NovoPasso: styled('div')`
    position: relative;
    .novo {
      position: absolute;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      background: orange;
      color: white;
      padding: 0 6px;
      border-radius: 4px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
      animation: novoPedido 2s infinite; // animação existente em App.css
    }
    @keyframes novoPedido {
      from {
        transform: translate3d(-50%, 0, 0);
      }
      50% {
        transform: translate3d(-50%, -20%, 0);
      }
      to {
        transform: translate3d(-50%, 0, 0);
      }
    }
  `,
  EfetuarAgendamento: styled('div')`
    padding: 100px 12px 12px 12px;
    .input-button {
      display: flex;
      label {
        margin-right: 12px;
        flex: 1;
      }
    }
    @media (max-width: 425px) {
      padding: 24px 12px;
      .input-button {
        flex-direction: column;
        label {
          margin-bottom: 12px;
          margin-right: 0;
        }
      }
    }
  `,
}

const desacentua = (str) => str.normalize('NFD').replace(/\p{Diacritic}/gu, '')
function isGoianiaAparecidaAnapolis(conta) {
  return [
    'GOIÂNIA',
    'APARECIDA DE GOIÂNIA',
    'ANÁPOLIS',
    // 'ABADIA DE GOIÁS',
    // 'ARAGOIÂNIA',
    // 'BELA VISTA DE GOIÁS',
    // 'BONFINÓPOLIS',
    // 'BRAZABRANTES',
    // 'CALDAZINHA',
    // 'CATURAÍ',
    // 'GOIANÁPOLIS',
    // 'GOIANIRA',
    // 'GUAPÓ',
    // 'HIDROLÂNDIA',
    // 'INHUMAS',
    // 'NERÓPOLIS',
    // 'NOVA VENEZA',
    // 'SANTO ANTÔNIO DE GOIÁS',
    // 'SENADOR CANEDO',
    // 'TEREZÓPOLIS DE GOIÁS',
    // 'TRINDADE'
  ].some((x) => desacentua(x) === desacentua(conta.Municipio))
}
