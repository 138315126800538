import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IRequest } from '../../common/IRequest'

export interface IState {
  request: IRequest
}

export const initialState: IState = {
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
}

const reducers = {
  fetchStarted(state: IState) {
    state.request.fetching = false
  },
  fetchError(state: IState, { payload }: PayloadAction<{ message }>) {
    state.request = {
      errorCode: 400,
      message: payload.message,
      fetching: false,
    }
  },
}

const { reducer: servicosReducer, actions } = createSlice({
  name: 'servicos',
  initialState: initialState,
  reducers,
})

const servicosActions = {
  ...actions,
}

export { servicosReducer, servicosActions }
