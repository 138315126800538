import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { getUsuarioLogado } from '../auth/AuthStore'
import { Redirect } from 'react-router'
import useAxios from 'axios-hooks'
import { ConfigProvider, DatePicker, Table } from 'antd'
import moment from 'moment'
import 'moment/locale/pt-br'
import locale from 'antd/lib/locale/pt_BR'
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns'

export const PERMISSAO_VISUALIZACAO_COMISSAO = [874, 857, 1272]

export const ComissaoSeplac = () => {
  const { Layout, getValorTotal, formatCurrency, ValorTotal } = ComissaoSeplac
  const [dataInicio, setDataInicio] = React.useState(
    format(subMonths(startOfMonth(new Date()), 1), 'yyyy-MM-dd')
  )
  const [dataFim, setDataFim] = React.useState(
    format(subMonths(endOfMonth(new Date()), 1), 'yyyy-MM-dd')
  )
  const { RangePicker } = DatePicker as any

  const usuarioLogado = useSelector(getUsuarioLogado)
  const [{ data, loading }] = useAxios(`/acerto-seplac?dataInicio=${dataInicio}&dataFim=${dataFim}`)

  if (!usuarioLogado?.Admin && !PERMISSAO_VISUALIZACAO_COMISSAO.includes(usuarioLogado?.Id))
    return <Redirect to="/" />

  function handleChangeDate(date, dateStr) {
    setDataInicio(dateStr[0])
    setDataFim(dateStr[1])
  }

  const valorTotal = loading ? 'carregando...' : getValorTotal(data)

  return (
    <Layout>
      <h2>Adm</h2>
      <label>
        <div>Período</div>
        <ConfigProvider locale={locale}>
          <RangePicker
            defaultValue={[moment(dataInicio), moment(dataFim)]}
            onChange={handleChangeDate}
          />
        </ConfigProvider>
      </label>

      <ValorTotal>
        Valor Total: <strong>R$ {valorTotal}</strong>
      </ValorTotal>

      <Table
        loading={loading}
        columns={[
          { title: 'Empresa', render: (r) => r.Conta.Fantasia },
          { title: 'Valor', render: (r) => formatCurrency(r.Valor) },
          { title: 'Total de blanks', render: (r) => r.Valor * 2 },
        ]}
        dataSource={data}
        pagination={false}
      />
    </Layout>
  )
}
ComissaoSeplac.Layout = styled.div`
  max-width: 800px;
  margin: 12px auto;
  padding-bottom: 48px;
`
ComissaoSeplac.ValorTotal = styled.div`
  font-size: 1.5em;
  margin: 12px 0;
`
ComissaoSeplac.getValorTotal = (contasValores) => {
  if (!contasValores?.length) return '-'
  const valor = contasValores.reduce((p, c) => p + c.Valor, 0)
  return ComissaoSeplac.formatCurrency(valor)
}
ComissaoSeplac.formatCurrency = (valor) => {
  return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor)
}
