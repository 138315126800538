import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { apiUrl } from '../../common/axios'
import Axios from 'axios'
import { IRequest } from '../../common/IRequest'
import jwtDecode from 'jwt-decode'
import { IStateRedux } from '../../common/ReduxStore'
import { createSelector } from 'reselect'
import * as _ from 'lodash'
import { encryptMessage } from '../functions/crypto'

export interface IState {
  request: IRequest
  token: string | null
  renewToken: string | null
  isUsuarioHipodromo: boolean
  isAdministradorHipodromo: boolean
  loadingPublicKey: boolean
  publicKey: string
  isActiveSession: boolean
  isDespachante: boolean
}

const token = sessionStorage.getItem('token') || null
const renewToken = sessionStorage.getItem('renewToken') || null
const isDespachante = sessionStorage.getItem('isDespachante') === 'true'

export const initialState: IState = {
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
  token,
  renewToken,
  isUsuarioHipodromo: false,
  isAdministradorHipodromo: false,
  loadingPublicKey: false,
  publicKey: '',
  isActiveSession: false,
  isDespachante,
}

const { reducer: authReducer, actions } = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginFetch(state) {
      state.request.fetching = true
    },
    loginSuccess(state, { payload }: PayloadAction<{ token; isDespachante; renewToken }>) {
      state.request = { ...initialState.request, fetching: false }
      state.token = payload.token
      state.renewToken = payload.renewToken
      state.isDespachante = payload.isDespachante
      sessionStorage.setItem('token', payload.token)
      sessionStorage.setItem('renewToken', payload.renewToken)
      sessionStorage.setItem('isDespachante', payload.isDespachante ? 'true' : 'false')
    },
    loginError(state, { payload }: PayloadAction<{ message; errorCode }>) {
      state.request = {
        errorCode: null,
        message: payload.message,
        fetching: false,
      }
    },
    logout(state) {
      state.token = null
      state.isDespachante = false
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('isDespachante')
    },
    clearMessage(state) {
      state.request.message = ''
    },
    setIsUsuarioHipodromo(state, { payload }: PayloadAction<{ isUsuarioHipodromo }>) {
      state.isUsuarioHipodromo = payload.isUsuarioHipodromo
    },
    setIsAdministradorHipodromo(state, { payload }: PayloadAction<{ isAdministradorHipodromo }>) {
      state.isAdministradorHipodromo = payload.isAdministradorHipodromo
    },
    getPublicKeyStarted(state) {
      state.loadingPublicKey = true
    },
    getPublicKeySuccess(state, { payload }: PayloadAction<{ publicKey }>) {
      state.loadingPublicKey = false
      state.publicKey = payload.publicKey
      state.isActiveSession = !!payload.publicKey
    },
    getPublicKeyError(state) {
      state.loadingPublicKey = false
      state.isActiveSession = false
    },
  },
})

const authActions = {
  ...actions,
  login({ cpf, pwd }: { cpf: string; pwd: string }) {
    return async (dispatch: any, getState) => {
      const { auth } = getState()
      if (!auth?.publicKey) return window.location.reload()
      const password = encryptMessage(pwd, auth?.publicKey)

      dispatch(actions.loginFetch())
      try {
        const {
          oldToken: token,
          renewToken,
          usuario: { id },
        } = await Axios.post(
          `${process.env.REACT_APP_HYPERPAY_API_URL}/usuarios/login`,
          { username: cpf, password },
          {
            withCredentials: true,
          }
        ).then((x) => x.data)
        const despachante = await Axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/emplacadores/despachantes/${id}`
        ).then((x) => x.data)
        dispatch(actions.loginSuccess({ token, isDespachante: !!despachante, renewToken }))
      } catch (error: any) {
        if (error?.response?.status === 403) return window.location.reload()
        const message = _.get(error, 'response.data.message', 'Falha ao efetuar o login')
        dispatch(actions.loginError({ errorCode: 400, message }))
      }
    }
  },
  getUsuarioHipodromo(usuarioId) {
    return async (dispatch) => {
      try {
        const { isUsuarioHipodromo, isAdministradorHipodromo } =
          await axios.Usuarios.getUsuariosHipodromo(usuarioId)
        dispatch(actions.setIsUsuarioHipodromo({ isUsuarioHipodromo }))
        dispatch(actions.setIsAdministradorHipodromo({ isAdministradorHipodromo }))
      } catch (error: any) {}
    }
  },
  getSession() {
    return async (dispatch) => {
      try {
        dispatch(actions.getPublicKeyStarted())
        const publicKey = await Axios.get(`${process.env.REACT_APP_HYPERPAY_API_URL}/crypto`, {
          withCredentials: true,
        }).then((res) => res.data)
        dispatch(actions.getPublicKeySuccess({ publicKey }))
      } catch (error) {
        dispatch(actions.getPublicKeyError())
      }
    }
  },
  renewToken(contaId: string) {
    return async (dispatch, getState) => {
      const {
        auth: { renewToken, isDespachante },
      } = getState()
      try {
        const { oldToken: token, renewToken: newRenewToken } = await Axios.post(
          `${process.env.REACT_APP_HYPERPAY_API_URL}/usuarios/renew-token`,
          { contaId, renewToken },
          {
            withCredentials: true,
          }
        ).then((x) => x.data)

        dispatch(actions.loginSuccess({ token, isDespachante, renewToken: newRenewToken }))
      } catch (error) {
        console.log(error)
      }
    }
  },
}

export const getUsuarioLogado = createSelector(
  (s: IStateRedux) => s.auth.token,
  (token) => {
    if (!token) return null
    const decodedToken = jwtDecode<any>(token)
    const now = Date.now().valueOf() / 1000
    if (!decodedToken || !decodedToken.exp || decodedToken.exp < now) return null
    return decodedToken
  }
)

export { authReducer, authActions }
