import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IStateRedux } from '../../common/ReduxStore'
import { getUsuarioLogado } from '../auth/AuthStore'
import { Redirect } from 'react-router'
import { bindActionCreators } from 'redux'
import { emplacadoresActions, emplacadoresConfiguracaoRodizioSelector } from './EmplacadoresStore'
import * as _ from 'lodash'
import Button from 'antd/es/button'
import Input from 'antd/es/input'
import Select from 'antd/es/select'

export const Rodizio = () => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(emplacadoresActions, dispatch)
  const cidades = useSelector((s: IStateRedux) => s.contas.cidades)
  const { cidade } = useSelector((s: IStateRedux) => s.emplacadores.configurarRodizio)
  const usuarioLogado = useSelector(getUsuarioLogado)
  const emplacadores = useSelector(emplacadoresConfiguracaoRodizioSelector)

  useEffect(() => {
    if (cidade) actions.getEmplacadoresConfigurarRodizio()
  }, [cidade])

  if (!usuarioLogado || !usuarioLogado.Admin) return <Redirect to="/" />

  return (
    <div>
      <form autoComplete="off">
        <label>Cidade</label>
        <br />
        <Select
          style={{ width: 300 }}
          value={cidade as any}
          showSearch
          onChange={(c) => actions.setCidadeRodizio({ cidade: c })}
        >
          <Select.Option value={null as any}>Selecione a cidade</Select.Option>
          {cidades.map((c) => (
            <Select.Option key={c.Municipio} value={c.Municipio}>
              {c.Municipio}
            </Select.Option>
          ))}
        </Select>
      </form>
      <br />
      <br />

      {/* <Emplacadores /> */}
      <table>
        <tbody>
          {emplacadores.map((e) => (
            <Emplacador emplacador={e} key={e.Id} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const Emplacador = ({ emplacador }) => {
  const dispatch = useDispatch()
  const emplacadoresIdGravando = useSelector(
    (s: IStateRedux) => s.emplacadores.configurarRodizio.emplacadoresIdGravando
  )

  function setValorEmplacador(valor) {
    dispatch(emplacadoresActions.setNovoValorRodizio({ emplacadorId: emplacador.Id, valor }))
  }

  function handleClickSalvar() {
    dispatch(emplacadoresActions.gravarServico(2, emplacador.Id, emplacador.ValorInformado - 8))
  }

  return (
    <tr key={emplacador.Id}>
      <td>{emplacador.Conta.Fantasia}</td>
      <td>{emplacador.ValorTotal}</td>
      <td>
        <Input
          value={emplacador.ValorInformado}
          onChange={(e) => setValorEmplacador(e.target.value)}
        />
      </td>
      <td>
        <Button
          disabled={emplacador.ValorInformado === emplacador.Valor}
          type="primary"
          loading={emplacadoresIdGravando.includes(emplacador.Id)}
          onClick={handleClickSalvar}
        >
          Salvar
        </Button>
      </td>
    </tr>
  )
}
