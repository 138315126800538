import React, { useEffect } from 'react'
import { useFormik, FormikProvider } from 'formik'
import styled from '@emotion/styled'
import { TextField } from '../../common/TextField'
import { Link, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import { authActions, getUsuarioLogado } from './AuthStore'
import { IStateRedux } from '../../common/ReduxStore'
import Button from 'antd/es/button'
import notification from 'antd/es/notification'
import { Show } from '../../common/Show'
import { getCurrentSite } from '../../common/getCurrentSite'

export const Login: React.FC = () => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(authActions, dispatch)
  const {
    request: { fetching, message },
  } = useSelector((s: IStateRedux) => s.auth)
  const site = getCurrentSite()
  const usuarioLogado = useSelector(getUsuarioLogado)
  useEffect(() => {
    if (message) {
      notification.error({ message })
      actions.clearMessage()
    }
  }, [message])

  useEffect(() => {
    dispatch(authActions.getSession())
  }, [])

  const formik = useFormik({
    initialValues: { usuario: '', senha: '' },
    onSubmit: (values, meta) => {
      const cpf = values.usuario
      const pwd = values.senha
      actions.login({ cpf, pwd })
    },
  })

  if (usuarioLogado) return <Redirect to={'/'} />

  return (
    <Styles.Container>
      <Styles.ContainerMaster>
        <Styles.ContainerPadrao>
          <Show condition={site === 'EMPLACAFACIL'}>
            <img src="emplacafacil-logo-266-48px.png" style={{ paddingBottom: '11px' }} />
          </Show>
          <Show condition={site === 'EMPLACAR_GOIAS'}>
            <img src="emplacargoias-logo.png" style={{ paddingBottom: '11px' }} />
          </Show>
          <Styles.TextoTitle>Informe seu login de conta REP para continuar</Styles.TextoTitle>
        </Styles.ContainerPadrao>
        <Styles.ContainerInner>
          <FormikProvider value={formik}>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                autoFocus
                mask="cpf"
                name="usuario"
                type="cel"
                label="Usuário"
                placeholder="Informe o CPF"
              />
              <TextField name="senha" type="password" label="Senha" placeholder="Informa a senha" />
              <Styles.ContainerButton>
                <Button loading={fetching} htmlType="submit">
                  Entrar
                </Button>
              </Styles.ContainerButton>
            </form>
          </FormikProvider>
        </Styles.ContainerInner>
        <Styles.LinkRecuperarSenha href="https://rep.hyperpay.com.br/#/recuperasenha">
          <Styles.EsqueceuSenha>Esqueci minha senha</Styles.EsqueceuSenha>
        </Styles.LinkRecuperarSenha>
      </Styles.ContainerMaster>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled('div', { label: 'Container' })`
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-around;
    background: none;
    box-shadow: none;
  `,
  ContainerInner: styled('div', { label: 'ContainerInner' })`
    background: #f4f4f4;
    border-radius: 10px;
    padding: 1em;
    margin: 1em;
    width: 500px;
    @media (min-width: 425px) {
      padding: 2em;
      margin: 0 auto;
    }
  `,
  EsqueceuSenha: styled('div', { label: 'EsqueceuSenha' })`
    text-align: center;
    margin-bottom: 2em;
    margin-top: 23px;
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
    color: black;
  `,
  ContainerButton: styled('div', { label: 'ContainerButton' })`
    margin-top: 12px;
    text-align: right;
  `,
  EsqueciSenha: styled('p', { label: 'EsqueciSenha' })`
    text-align: center;
  `,
  ContainerMaster: styled('div', { label: 'ContainerMaster' })`
    margin-top: 2px;
  `,
  TextoTitle: styled('h1', { label: 'TextoTitle' })`
    text-align: center;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.68);
  `,
  LinkRecuperarSenha: styled('a', { label: 'LinkRecuperarSenha' })`
    :link {
      color: black;
      text-decoration: none;
    }
    :hover {
      text-decoration: underline;
    }
  `,
  ContainerPadrao: styled('div', { label: 'ContainerPadrao' })`
    text-align: center;
  `,
}
