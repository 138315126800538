import React, { useState } from 'react'
import { Tabs, Button, notification } from 'antd'
import { FormikProvider, useFormik } from 'formik'
import { TextField } from '../../common/TextField'
import styled from '@emotion/styled'
import { apiUrl } from '../../common/axios'
import * as _ from 'lodash'
import useAxios from 'axios-hooks'
import { Show } from '../../common/Show'
import {
  BarcodeOutlined,
  CreditCardOutlined,
  IdcardOutlined,
  UserOutlined,
  CalendarOutlined,
} from '@ant-design/icons'
import { HyperpayCobranca } from '@hyperdev-tech/hyperpay-cobranca'
import { Flex } from '../../common/Flex'
import QrCode from 'qrcode.react'
declare let Iugu

const IUGU_ACCOUNT_ID = 'BCCF63E3B7FD44129BE973BA9B4BE1BD'

export const Pagamento = ({
  boleto,
  valorMaximo,
  valorTarifa,
  close = (() => null) as any,
  showCancelar = false,
}) => {
  if (boleto.Tipo === 'EMPLACAFACIL_MERCADOPAGO') {
    return <PagamentoMercadoPago boleto={boleto} />
  }

  return (
    <HyperpayCobranca
      cobrancaId={boleto?.id}
      token="19bd9aee-fa9d-4e66-a4e3-300ad54acdd5"
      theme="EMPLACAFACIL"
      defaultOpened="PIX"
      env={process.env.REACT_APP_ENV || (process.env.NODE_ENV as any)}
    />
  )
}

const Container = styled.div<{ isMercadoPago: boolean }>((props) =>
  !props.isMercadoPago
    ? ``
    : `
      .codigo-barras svg {
        display: none;
      }
      .meio-pagamento-cartao_credito {
        display: none;
      }
    `
)

export const PagamentoMercadoPago = ({ boleto }) => {
  const { Layout, Total, Heading, PagamentoVia, ComponentPix } = PagamentoMercadoPago
  const valor = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(boleto.Valor)
  return (
    <Layout>
      <div>Escolha o meio de pagamento:</div>
      <Total>
        <small>Total:</small> <span>{valor}</span>
      </Total>
      <Heading>PIX</Heading>
      <ComponentPix>
        <div>
          1. Abra o app ou banco de sua preferência. Escolha a opção pagar com código Pix “copia e
          cola”, ou código QR
          <br />
          2. Copie e cole o código, ou escaneie o código QR com a câmera do seu celular. Confira
          todas as informações e autorize o pagamento.
        </div>
        <div>
          <QrCode value={boleto?.Pix || ''} style={{ width: 155, height: 155 }} />
          <div style={{ marginTop: 16 }}>
            <Button
              onClick={() => {
                void navigator.clipboard.writeText(boleto?.Pix)
              }}
              type="primary"
              size="large"
            >
              Copiar código pix
            </Button>
          </div>
        </div>
      </ComponentPix>
      <Heading>BOLETO</Heading>
      <div>
        1. Imprima o boleto e efetue o pagamento usando o app de seu banco, nas casas lotéricas ou
        agência bancária.
        <br />
        2. O pagamento será reconhecido em até no máximo 1 dia útil
      </div>
      <div style={{ textAlign: 'center', marginTop: 24 }}>
        <Button onClick={() => window.open(boleto.Boleto_URL)} size="large" type="primary">
          Imprimir boleto
        </Button>
      </div>
      <PagamentoVia>
        Pagamento via: <img src="mercado-pago.png" style={{ width: 60 }} />
      </PagamentoVia>
    </Layout>
  )
}
PagamentoMercadoPago.Layout = styled.div`
  max-width: 648px;
  margin: 0 auto;
  background: white;
  padding: 24px;
  border: 1px solid #ccc;
`
PagamentoMercadoPago.Total = styled.div`
  text-align: center;
  font-size: 24px;
  margin-top: 12px;
  span {
    font-weight: bold;
  }
`
PagamentoMercadoPago.Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  font-weight: bold;
  font-size: 16px;
  &:after,
  &:before {
    content: ' ';
    height: 1px;
    width: 100%;
    display: block;
    background: #444444;
  }
`
PagamentoMercadoPago.PagamentoVia = styled.div`
  text-align: center;
  margin-top: 48px;
  font-size: 11px;
`
PagamentoMercadoPago.ComponentPix = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
    padding-top: 24px;
  }
`

export const PagamentoOld = ({
  boleto,
  valorMaximo,
  valorTarifa,
  close = (() => null) as any,
  showCancelar = false,
}) => {
  const [showComprovante, setShowComprovante] = useState(false)
  const [abaSelecionada, setAbaSelecionada] = useState('')

  if (!boleto) return null

  const valorTarifaBoleto = valorTarifa
  const valorTarifaCartao = valorMaximo - +boleto.Valor + valorTarifa

  if (!abaSelecionada) {
    return (
      <div>
        <InfoEscolhaCartaoBoleto
          setAbaSelecionada={setAbaSelecionada}
          valorTarifaCartao={valorTarifaCartao}
          valorTarifaBoleto={valorTarifaBoleto}
        />
        <Show condition={showCancelar}>
          <div style={{ textAlign: 'right', marginTop: 12 }}>
            <Button onClick={close}>Cancelar</Button>
          </div>
        </Show>
      </div>
    )
  }

  if (showComprovante) {
    return (
      <div>
        <iframe
          src={boleto.Boleto_URL}
          frameBorder={0}
          style={{ width: '100%', height: 500 }}
          title="iframe-comprovante"
        />
        <div style={{ textAlign: 'right' }}>
          <Button onClick={close} type="primary" size="large">
            Fechar
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div>
      <h3>Forma de pagamento:</h3>
      <Tabs activeKey={abaSelecionada} onChange={setAbaSelecionada}>
        <Tabs.TabPane
          tab={
            <>
              <BarcodeOutlined /> Boleto Bancário
            </>
          }
          key="BOLETO"
        >
          <Boleto
            close={close}
            showCancelar={showCancelar}
            boleto={boleto}
            valorTarifa={valorTarifaBoleto}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <>
              <CreditCardOutlined /> Cartão de Crédito
            </>
          }
          key="CARTAO"
        >
          <CartaoCredito
            setShowComprovante={setShowComprovante}
            showCancelar={showCancelar}
            close={close}
            boleto={boleto}
            valor={valorMaximo}
            valorTarifa={valorTarifaCartao}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

const Boleto = ({ boleto, valorTarifa, close, showCancelar }) => {
  function abrirBoleto() {
    window.open(boleto.Boleto_URL)
    close()
  }

  return (
    <>
      <Styles.ContainerButtonBoleto>
        <TabelaValores total={+boleto.Valor}>
          <TabelaValorItem descricao="Emplacamento" valor={+boleto.Valor - valorTarifa} />
          <TabelaValorItem descricao="Tarifas / Conveniência" valor={valorTarifa} />
        </TabelaValores>
        <Button onClick={abrirBoleto} type="primary">
          Emitir boleto
        </Button>
      </Styles.ContainerButtonBoleto>
      <Show condition={showCancelar}>
        <div style={{ textAlign: 'right', marginTop: 12 }}>
          <Button onClick={close} size="large">
            Cancelar
          </Button>
        </div>
      </Show>
    </>
  )
}

const InfoEscolhaCartaoBoleto = ({ valorTarifaCartao, valorTarifaBoleto, setAbaSelecionada }) => {
  return (
    <>
      <Styles.ContainerInfoBoletoOuCartao>
        <h3>Como deseja efetuar o pagamento?</h3>
        <h2 style={{ textAlign: 'center' }}>Cartão de Crédito</h2>
        <p>
          O serviço estará disponível <strong>imediatamente</strong>
        </p>
        <Show condition={valorTarifaCartao}>
          <div className="info-tarifa">
            Valor de tarifas: R$ {valorTarifaCartao.toFixed(2).replace('.', ',')}
          </div>
        </Show>
        <Button
          type="primary"
          size="large"
          onClick={() => setAbaSelecionada('CARTAO')}
          style={{ height: 50, width: '100%' }}
        >
          Pagar com Cartão de Crédito
        </Button>

        <h2 className="titulo-boleto">Boleto Bancário</h2>
        <p>
          O serviço estará disponível assim que o pagamento for reconhecido:
          <ul>
            <li>
              Até <strong>30 minutos</strong> para pagamentos na Caixa Econômica Federal e Lotéricas
            </li>
            <li>
              No <strong>próximo dia útil</strong> para pagamentos em qualquer outro local
            </li>
          </ul>
        </p>
        <Show condition={valorTarifaBoleto}>
          <div className="info-tarifa">
            Valor de tarifas: R$ {valorTarifaBoleto.toFixed(2).replace('.', ',')}
          </div>
        </Show>
        <Button
          type="primary"
          size="large"
          onClick={() => setAbaSelecionada('BOLETO')}
          style={{ height: 50, width: '100%' }}
        >
          Pagar com Boleto
        </Button>
      </Styles.ContainerInfoBoletoOuCartao>
      <p style={{ textAlign: 'right', marginTop: 24 }}>
        Você ainda poderá mudar de ideia após selecionar
      </p>
    </>
  )
}

const CartaoCredito = ({ boleto, valor, valorTarifa, close, showCancelar, setShowComprovante }) => {
  const [{ loading }, reqProcessaPagamento] = useAxios(
    {
      url: apiUrl + '/boletos/processa-pagamento-iugu',
      method: 'POST',
    },
    {
      manual: true,
    }
  )
  async function processaPagamento(values) {
    const objCartaoCredito = _createCartaoCreditoObj(values)
    if (!objCartaoCredito.valid()) {
      return notification.error({
        message: _getErroCartaoInvalido(objCartaoCredito.errors()),
      })
    }
    try {
      const token = await _createTokenIugu(objCartaoCredito)
      await reqProcessaPagamento({
        data: {
          token,
          email: values.email,
          boletoId: boleto.Id,
          valorCobranca: valor, // TODO: passar o valor da cobrança correto (caso nao exista, fazer o cálculo da porcentagem e informar)
        },
      })
      setShowComprovante(true)
    } catch (error: any) {
      console.log({ error })
      const message = _.get(error, 'response.data.message', 'Erro ao processar pagamento')
      return notification.error({ message })
    }
  }

  const formik = useFormik({
    initialValues: {
      number: '',
      verification_value: '',
      full_name: '',
      expiration: '',
    },
    onSubmit: processaPagamento,
  })

  if (boleto.DataHora < '2021-01-04T19:45') {
    return (
      <div style={{ padding: 36, fontSize: '1.3em' }}>
        Esse pedido para emplacamento não pode ser pago via <strong>Cartão de Crédito</strong>.
        <br />
        Por favor emita o <strong>Boleto Bancário</strong> ou contate o suporte e solicite uma
        reemisão.
      </div>
    )
  }
  return (
    <div>
      <FormikProvider value={formik}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <DisplayFlex marginBetween={12}>
            <InputNumeroCartao />
            <InputCodigoSeguranca />
          </DisplayFlex>
          <br />

          <DisplayFlex marginBetween={12}>
            <InputTitular />
            <InputDataExpiracao />
          </DisplayFlex>
          <br />

          <InputEmail />
          <br />

          <TabelaValores total={valor}>
            <TabelaValorItem descricao="Emplacamento" valor={valor - valorTarifa} />
            <TabelaValorItem descricao="Tarifas / Conveniência" valor={valorTarifa} />
          </TabelaValores>

          <div style={{ marginTop: 12, display: 'flex', justifyContent: 'flex-end' }}>
            <Button htmlType="submit" type="primary" size="large" loading={loading}>
              Efetuar pagamento
            </Button>
            <Show condition={showCancelar}>
              <Button onClick={close} size="large" style={{ marginLeft: 12 }} disabled={loading}>
                Cancelar
              </Button>
            </Show>
          </div>
        </form>
      </FormikProvider>
    </div>
  )
}

function InputNumeroCartao() {
  return (
    <label style={{ flex: 1 }}>
      Número do cartão
      <TextField
        size="large"
        name="number"
        placeholder="Número do Cartão"
        prefix={<CreditCardOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        mask={{
          mask: '9999 9999 9999 9999',
          placeholder: ' ',
          showMaskOnHover: false,
          showMaskOnFocus: false,
        }}
      />
    </label>
  )
}

function InputCodigoSeguranca() {
  return (
    <label>
      Código de segurança
      <TextField
        size="large"
        name="verification_value"
        prefix={<IdcardOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="CVV"
      />
    </label>
  )
}

function InputTitular() {
  const [value, setValue] = useState('')
  return (
    <label style={{ flex: 1 }}>
      Nome do titular
      <Styles.TextFieldUppercase
        size="large"
        name="full_name"
        onChange={(e) => setValue(e.target.value)}
        hasValue={!!value}
        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="Titular do Cartão"
      />
    </label>
  )
}

function InputDataExpiracao() {
  return (
    <label>
      Data de expiração
      <TextField
        size="large"
        name="expiration"
        mask="99/99"
        prefix={<CalendarOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="MM/AA"
      />
    </label>
  )
}

function InputEmail() {
  return (
    <label>
      E-mail
      <TextField
        size="large"
        name="email"
        prefix={<span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>@</span>}
        placeholder="Informe seu e-mail"
      />
    </label>
  )
}

function TabelaValores({ children, total }) {
  return (
    <div>
      <DisplayFlex>
        <div style={{ flex: 1 }}></div>
        <div style={{ textAlign: 'left' }}>
          <h3>Valor a ser pago</h3>
          <Styles.TableValor>
            <tbody>
              {children}
              <tr>
                <td>Total</td>
                <td>R$ {total.toFixed(2).replace('.', ',')}</td>
              </tr>
            </tbody>
          </Styles.TableValor>
        </div>
      </DisplayFlex>
    </div>
  )
}

function TabelaValorItem({ descricao, valor }) {
  return (
    <tr>
      <td>{descricao}</td>
      <td>R$ {valor.toFixed(2).replace('.', ',')}</td>
    </tr>
  )
}

const DisplayFlex = styled('div')<{ marginBetween? }>(
  (props) => `
  display: flex;
  >*:not(:first-child) {
    margin-left: ${props.marginBetween ? props.marginBetween + 'px' : '0'};
  }
`
)

function _createCartaoCreditoObj(values) {
  const { number, verification_value: verificationValue, full_name: fullName, expiration } = values
  const [mes, ano] = expiration.split('/')
  const [nome, ...sobrenomes] = fullName.split(' ')
  return Iugu.CreditCard(number, mes, ano, nome, sobrenomes.pop(), verificationValue)
}

async function _createTokenIugu(objCartaoCredito) {
  return await new Promise((resolve, reject) => {
    Iugu.setAccountID(IUGU_ACCOUNT_ID)
    if (process.env.NODE_ENV !== 'production') {
      // Iugu.setTestMode(true);
    }

    Iugu.createPaymentToken(objCartaoCredito, (response) => {
      if (response.errors) {
        return reject(response.errors)
      }
      resolve(response.id)
    })
  })
}

function _getErroCartaoInvalido(errors) {
  const {
    expiration,
    first_name: firstName,
    last_name: lastName,
    number,
    verification_value: verificationValue,
  } = errors
  if (number) return 'Número do cartão inválido'
  if (verificationValue) return 'Código de verificação inválido'
  if (firstName || lastName) return 'Nome do titular do cartão inválido'
  if (expiration) return 'Data de expiração inválida'
}

const Styles = {
  TableValor: styled('table')`
    tr {
      border-bottom: 1px solid #dedede;
    }
    td:last-child {
      text-align: right;
    }
  `,
  ContainerButtonBoleto: styled('div')`
    text-align: center;
    button {
      height: 69px;
      width: 350px;
      font-size: 1.5em;
    }
  `,
  TextFieldUppercase: styled(TextField)<{ hasValue }>(
    (props) => `
    ${
      props.hasValue
        ? `
      input {
        text-transform: uppercase;
      }
    `
        : ''
    }
  `
  ),
  ContainerInfoBoletoOuCartao: styled('div')`
    width: 435px;
    margin: 0 auto;
    p {
      margin-bottom: 0.5em;
    }
    .titulo-boleto {
      text-align: center;
      margin-top: 24px;
      border-top: 1px solid #ccc;
      padding-top: 12px;
    }
    .info-tarifa {
      text-align: right;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 0.5em;
    }
  `,
}
