import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { authReducer, IState as IStateAuth } from '../domains/auth/AuthStore'
import { pedidosReducer, IState as IStatePedidos } from '../domains/pedidos/PedidosStore'
import {
  fornecedoresReducer,
  IState as IStateFornecedores,
} from '../domains/fornecedores/FornecedoresStore'
import {
  emplacadoresReducer,
  IState as IStateEmplacadores,
} from '../domains/emplacadores/EmplacadoresStore'
import { servicosReducer, IState as IStateServicos } from '../domains/servicos/ServicosStore'
import { contasReducer, IState as IStateContas } from '../domains/contas/ContasStore'

export interface IStateRedux {
  auth: IStateAuth
  pedidos: IStatePedidos
  fornecedores: IStateFornecedores
  emplacadores: IStateEmplacadores
  servicos: IStateServicos
  contas: IStateContas
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    pedidos: pedidosReducer,
    fornecedores: fornecedoresReducer,
    emplacadores: emplacadoresReducer,
    servicos: servicosReducer,
    contas: contasReducer,
  },
  middleware: [...getDefaultMiddleware()],
})
