import { Form, FormikProvider, useFormik } from 'formik'
import { TextField } from '../../common/TextField'
import styled from '@emotion/styled'
import { Button, DatePicker, Input, Modal, Select, Table, Tabs, Tooltip, notification } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import axios from '../../common/axios'
import useAxios from 'axios-hooks'
import { format } from 'date-fns'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { getUsuarioLogado } from '../auth/AuthStore'
import { Redirect } from 'react-router'
import moment from 'moment'
import 'moment/locale/pt-br' // Import Portuguese locale

moment.locale('pt-br') // Set moment's locale to Portuguese globally (optional)

export function Despachantes() {
  const usuarioLogado = useSelector(getUsuarioLogado)
  if (
    !usuarioLogado ||
    ![
      '71a880da-497b-user-8dc1-d09c756f0cb8',
      '3903ca9d-77f8-user-a48a-920b197d2ce2',
      '7499c2c7-d4ea-user-a089-7d0ea32b1c21',
    ].includes(usuarioLogado.id)
  ) {
    return <Redirect to="/" />
  }

  const { TabPane } = Tabs
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Despachantes" key="1">
          <ListDespachantes />
        </TabPane>
        <TabPane tab="Novo Despachante" key="2">
          <NewDespachante />
        </TabPane>
        <TabPane tab="Pedidos" key="3">
          <ListPedidos />
        </TabPane>
        <TabPane tab="Aprovisionamento" key="4">
          <ListAprovisionamento />
        </TabPane>
      </Tabs>
    </div>
  )
}

function ListDespachantes() {
  const [{ data, loading, error }, refetchDespachantes] = useAxios('/despachantes')

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Data de criação',
      render: (row) => <>{format(new Date(row.createdAt), 'dd/MM/yyyy')}</>,
    },
    {
      title: 'Emplacador',
      render: (row) => <Emplacador despachante={row} refetchDespachantes={refetchDespachantes} />,
    },
    {
      title: 'Chave Pix',
      render: (row) => <ChavePix despachante={row} refetchDespachantes={refetchDespachantes} />,
    },
  ]

  if (loading) return <p>Carregando...</p>
  if (error) return <p>Erro ao buscar!</p>

  return (
    <>
      <Table dataSource={data} columns={columns} rowKey="id" />
    </>
  )
}

function Emplacador({ despachante, refetchDespachantes }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [emplacadoresList, setEmplacadoresList] = useState<any[]>([])
  const [{ data, loading, error }] = useAxios('/all')
  const [novoEmplacadorId, setNovoEmplacadorId] = useState(null)

  useEffect(() => {
    if (data) {
      setEmplacadoresList(data)
    }
  }, [data])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = async () => {
    try {
      await axios.Despachantes.updateEmplacador(despachante.id, novoEmplacadorId)
      refetchDespachantes()
      notification.success({ message: 'Alterado com sucesso' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Erro ao alterar o despachante'
      notification.error(message)
    } finally {
      setIsModalVisible(false)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleRemove = async () => {
    try {
      if (!confirm('Tem certeza que seja remover emplacador vinculado ao despachante?')) return
      await axios.Despachantes.updateEmplacador(despachante.id, null)
      refetchDespachantes()
      notification.success({ message: 'Removido com sucesso' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Erro ao remover o despachante'
      notification.error(message)
    } finally {
      setIsModalVisible(false)
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <div>{despachante.emplacador?.Conta.Fantasia}</div>
        <Tooltip title="Escolher estampador">
          <Button icon={<EditOutlined />} onClick={showModal} />
        </Tooltip>
        {despachante.emplacador && (
          <Tooltip title="Remover estampador">
            <Button icon={<DeleteOutlined />} onClick={handleRemove} />
          </Tooltip>
        )}
      </div>

      <Modal
        title="Update Emplacador"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          style={{ width: '100%' }}
          loading={loading}
          showSearch
          filterOption={(input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={novoEmplacadorId}
          onChange={(value) => setNovoEmplacadorId(value)}
        >
          {emplacadoresList.map((emplacador) => (
            <Select.Option key={emplacador.Id} value={emplacador.Id}>
              {`${emplacador.Conta.Fantasia} - ${emplacador.Conta.Municipio}`}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  )
}

function ChavePix({ despachante, refetchDespachantes }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [chavePix, setChavePix] = useState(despachante.chavePix || '')
  const [cpfCnpjChavePix, setCpfCnpjChavePix] = useState(despachante.cpfCnpjChavePix || '')

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = async () => {
    try {
      await axios.Despachantes.updateChavePix({
        despachanteId: despachante.id,
        chavePix,
        cpfCnpjChavePix,
      })
      refetchDespachantes()
      notification.success({ message: 'Chave Pix atualizada com sucesso' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Erro ao atualizar a Chave Pix'
      notification.error({ message })
    } finally {
      setIsModalVisible(false)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleRemove = async () => {
    try {
      if (!confirm('Tem certeza que seja remover os dados do PIX?')) return
      await axios.Despachantes.updateChavePix({
        despachanteId: despachante.id,
        chavePix: '',
        cpfCnpjChavePix: '',
      })
      setChavePix('')
      setCpfCnpjChavePix('')
      refetchDespachantes()
      notification.success({ message: 'Chave Pix removida com sucesso' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Erro ao remover a Chave Pix'
      notification.error({ message })
    } finally {
      setIsModalVisible(false)
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <div>{despachante.chavePix}</div>
        <Tooltip title="Editar Chave Pix">
          <Button icon={<EditOutlined />} onClick={showModal} />
        </Tooltip>
        {despachante.chavePix && (
          <Tooltip title="Remover Chave Pix">
            <Button icon={<DeleteOutlined />} onClick={handleRemove} />
          </Tooltip>
        )}
      </div>

      <Modal
        title="Atualizar Chave Pix"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label htmlFor="">
          Chave Pix
          <Input
            placeholder="Insira a nova Chave Pix"
            value={chavePix}
            onChange={(e) => setChavePix(e.target.value)}
          />
        </label>
        <br />
        <label htmlFor="">
          CPF/CNPJ
          <Input
            placeholder="Insira o CPF/CNPJ"
            value={cpfCnpjChavePix}
            onChange={(e) => setCpfCnpjChavePix(e.target.value)}
          />
        </label>
      </Modal>
    </div>
  )
}

function ListPedidos() {
  const RangePicker = DatePicker.RangePicker as any
  const { TabPane } = Tabs

  const startOfWeek = moment().startOf('week')
  const endOfWeek = moment().endOf('week')

  const [currentDespachante, setCurrentDespachante] = useState('')
  const [timeRange, setTimeRange] = useState([startOfWeek, endOfWeek])
  const [{ data, loading }] = useAxios(
    `/despachantes/lista-pedidos?dataInicio=${timeRange[0].format(
      'YYYY-MM-DD'
    )}&dataFim=${timeRange[1].format('YYYY-MM-DD')}`
  )

  const sinteticoColumns = [
    {
      title: 'Nome do Despachante',
      dataIndex: 'nomeDespachante',
      key: 'nomeDespachante',
    },
    {
      title: 'Chave Pix',
      dataIndex: 'chavePix',
      key: 'chavePix',
    },
    {
      title: 'Valor Total',
      dataIndex: 'valorTotal',
      key: 'valorTotal',
      render: (valor) =>
        `R$ ${Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(valor)}`,
    },
    {
      title: 'Qtd Serviços',
      dataIndex: 'qtdServicos',
      key: 'qtdServicos',
    },
  ]
  const analiticoColumns = [
    {
      title: 'Data de Pagamento',
      dataIndex: 'dataPagamento',
      key: 'dataPagamento',
      render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
      render: (valor) =>
        `R$ ${Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(valor)}`,
    },
    {
      title: 'Serviço',
      dataIndex: ['boleto', 'Template_Data', 'servico'],
      render: (servico) => servico.replace(' MERCOSUL', ''),
      key: 'servico',
    },
    {
      title: 'Placa',
      dataIndex: ['boleto', 'Template_Data', 'placa'],
      key: 'placa',
    },
    {
      title: 'Estampador',
      dataIndex: ['boleto', 'Conta', 'Fantasia'],
      key: 'fantasia',
    },
  ]
  if (!currentDespachante) {
    analiticoColumns.push({
      title: 'Nome do Despachante',
      dataIndex: ['despachante', 'nome'],
      key: 'nomeDespachante',
    })
  }

  const onTimeRangeChange = (dates) => {
    setTimeRange(dates)
  }

  const sinteticoData = useMemo<any[]>(() => aggregateDataByDespachante(data), [data])

  const despachantes = useMemo<any[]>(() => {
    return Array.from(new Set(data?.map?.((x) => x.despachante.nome) ?? []))
  }, [data])

  const analiticData = useMemo(() => {
    if (!currentDespachante) return data
    return data.filter((x) => x.despachante.nome === currentDespachante)
  }, [data, currentDespachante])

  return (
    <div>
      <div style={{ display: 'flex', gap: 24 }}>
        <RangePicker defaultValue={timeRange} onChange={onTimeRangeChange} format="DD/MM/YYYY" />
        <Select style={{ width: 400 }} onChange={(d) => setCurrentDespachante(d)}>
          <Select.Option key={''} value={''}>
            {''}
          </Select.Option>
          {despachantes.map((d) => (
            <Select.Option key={d} value={d}>
              {d}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Sintético" key="1">
          <Table
            dataSource={sinteticoData}
            columns={sinteticoColumns}
            rowKey="id"
            pagination={false}
            loading={loading}
          />
        </TabPane>
        <TabPane tab="Analítico" key="2">
          <Table
            dataSource={analiticData}
            columns={analiticoColumns}
            rowKey="id"
            pagination={false}
            loading={loading}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}

function ListAprovisionamento() {
  const RangePicker = DatePicker.RangePicker as any
  const startOfWeek = moment().startOf('week')
  const endOfWeek = moment().endOf('week')
  const [currentMunicipio, setCurrentMunicipio] = useState('')
  const [timeRange, setTimeRange] = useState([startOfWeek, endOfWeek])
  const onTimeRangeChange = (dates) => {
    setTimeRange(dates)
  }
  const [{ data, loading }] = useAxios(
    `/aprovisionamento/lista-pedidos/${currentMunicipio}?dataInicio=${timeRange[0].format(
      'YYYY-MM-DD'
    )}&dataFim=${timeRange[1].format('YYYY-MM-DD')}`
  )
  const aggregatedData = useMemo(() => {
    if (!data?.length) return []
    const result = new Map()

    data.forEach((curr) => {
      const key = curr.boleto.Conta_Id
      if (result.has(key)) {
        const existing = result.get(key)
        result.set(key, {
          valor: existing.valor + curr.valor,
          count: existing.count + 1,
          fantasia: curr.boleto.Conta.Fantasia,
        })
      } else {
        result.set(key, {
          valor: curr.valor,
          count: 1,
          fantasia: curr.boleto.Conta.Fantasia,
        })
      }
    })
    return Array.from(result, ([Conta_Id, data]) => ({ Conta_Id, ...data }))
  }, [data])

  const media = aggregatedData.reduce((prev, curr) => prev + curr.valor, 0) / aggregatedData.length

  const aggregatedColumns = [
    {
      title: 'Empresa',
      dataIndex: 'fantasia',
      key: 'fantasia',
      sorter: (a, b) => a.fantasia.localeCompare(b.fantasia),
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
      render: (valor) => (
        <div>
          <span>
            R${' '}
            {Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(valor)}
          </span>{' '}
          <span style={{ color: media < valor ? 'green' : 'red', fontSize: '.7em' }}>
            R${' '}
            {Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(Math.abs(media - valor))}{' '}
            {media < valor ? 'acima da média' : 'abaixo da média'}
          </span>
        </div>
      ),
      sorter: (a, b) => a.valor - b.valor,
    },
  ]

  return (
    <div>
      <div style={{ display: 'flex', gap: 24 }}>
        <RangePicker defaultValue={timeRange} onChange={onTimeRangeChange} format="DD/MM/YYYY" />
        <Select style={{ width: 400 }} onChange={(d) => setCurrentMunicipio(d)}>
          <Select.Option key={''} value={''}>
            {''}
          </Select.Option>
          <Select.Option key="ANÁPOLIS" value="ANÁPOLIS">
            ANÁPOLIS
          </Select.Option>
          <Select.Option key="TRINDADE" value="TRINDADE">
            TRINDADE
          </Select.Option>
        </Select>
        <div style={{ flex: '1' }} />
        <div
          style={{
            paddingRight: 24,
            fontSize: '1.7em',
            fontWeight: 'bold',
            lineHeight: 1,
            textAlign: 'center',
          }}
        >
          {Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(media)}
          <div>
            <small style={{ fontWeight: 'normal' }}>Média</small>
          </div>
        </div>
      </div>

      <Table
        dataSource={aggregatedData}
        columns={aggregatedColumns}
        rowKey="id"
        pagination={false}
        loading={loading}
      />
    </div>
  )
}

const aggregateDataByDespachante = (data) => {
  const groupedData = data?.reduce((acc, curr) => {
    const despachanteKey = curr.despachante.nome

    if (!acc[despachanteKey]) {
      acc[despachanteKey] = {
        nomeDespachante: curr.despachante.nome,
        chavePix: curr.despachante.chavePix,
        cpfCnpjChavePix: curr.despachante.cpfCnpjChavePix,
        valorTotal: 0,
        qtdServicos: 0,
      }
    }

    acc[despachanteKey].valorTotal += curr.valor
    acc[despachanteKey].qtdServicos += 1

    return acc
  }, {})

  if (!groupedData) return []

  return Object.values(groupedData)
}

function NewDespachante() {
  const { Container } = NewDespachante
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: { nome: '', telefone: '', cpf: '', email: '' },
    onSubmit: async (values) => {
      setLoading(true)
      try {
        await axios.Despachantes.createDespachante(values)
        notification.success({ message: 'Despachante criado com sucesso' })
      } catch (error: any) {
        const message = error.response?.data?.message ?? 'Ocorreu um erro ao criar o usuário'
        notification.error({ message })
      } finally {
        setLoading(false)
      }
    },
  })

  function handleCancelar() {}

  return (
    <Container>
      <FormikProvider value={formik}>
        <Form>
          <TextField label="Nome" name="nome" />
          <TextField label="Telefone" name="telefone" mask="telefone" />
          <TextField label="Cpf" name="cpf" mask="cpf" />
          <TextField label="Email" name="email" />
          <div style={{ display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
            <Button htmlType="submit" type="primary" loading={loading}>
              Salvar
            </Button>
            <Button htmlType="button" onClick={handleCancelar}>
              Cancelar
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </Container>
  )
}
NewDespachante.Container = styled.div`
  padding: 12px;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
