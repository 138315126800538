import React, { useEffect } from 'react'
import { notification } from 'antd'

const useNotifyError = (error, defaultMessage = 'Ocorreu um erro ao executar a operação') => {
  useEffect(() => {
    if (!error) return
    const message = error.response?.data.message ?? defaultMessage
    notification.error({ message })
  }, [error])
}

export { useNotifyError }
