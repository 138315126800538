import React, { useState, createContext, useContext, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { startOfWeek, addDays, format, setHours, setMinutes, parseISO, addMinutes } from 'date-fns'
import useAxios from 'axios-hooks'
import { Modal, Button } from 'antd'
import Axios from 'axios'

const DURACAO_ATENDIMENTO = 15
const HORARIOS = _getHorarios()

const ctxAgendamentos = createContext({ agendamentos: [], qtdInstaladores: 0 })

const Agendamento = () => {
  const location = useLocation()
  const history = useHistory()
  const boleto = (location.state as any)?.boleto
  const [{ data: agendamentos }] = useAxios(`/agendamentos?contaIdEmplacador=${boleto?.Conta_Id}`, {
    useCache: false,
  })

  const providedValue = useMemo(() => {
    if (!agendamentos) return { agendamentos: [], qtdInstaladores: 0 }
    return {
      agendamentos: agendamentos.agendamentos.map((x) => format(parseISO(x), 'yyyy-MM-dd HH:mm')),
      qtdInstaladores: agendamentos.qtdInstaladores,
    }
  }, [agendamentos])

  if (!boleto) {
    history.replace('/')
    return null
  }
  return (
    <ctxAgendamentos.Provider value={providedValue}>
      <Styles.Container>
        <div className="interno">
          <div className="titulo">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <h1>Agendamento de emplacamento</h1>
                <p>Clique em um horário para efetuar o agendamento de seu emplacamento.</p>
                <p>Esteja presente no local do emplacamento no dia e horário selecionado.</p>
              </div>
              <Button color="danger" onClick={() => history.goBack()}>
                Cancelar
              </Button>
            </div>
          </div>
          <Calendario />
        </div>
      </Styles.Container>
    </ctxAgendamentos.Provider>
  )
}

const Calendario = () => {
  const preencheSemana = (data) =>
    Array(7)
      .fill(null)
      .map((_, i) => {
        return addDays(startOfWeek(data), i)
      })
  const [semanaAtual, setSemanaAtual] = useState(preencheSemana(new Date()))
  return (
    <Styles.Calendario>
      <Styles.SeletorSemana>
        <Button onClick={() => setSemanaAtual(preencheSemana(addDays(semanaAtual[0], -7)))}>
          {' '}
          <strong>&lt;&lt;</strong> Semana anterior{' '}
        </Button>
        &nbsp; &nbsp;
        <Button onClick={() => setSemanaAtual(preencheSemana(addDays(semanaAtual[0], 7)))}>
          {' '}
          Próxima semana <strong>&gt;&gt;</strong>{' '}
        </Button>
      </Styles.SeletorSemana>
      <div className="semanas-container">
        {semanaAtual.map((diaSemana, i) => (
          <Semana diaSemana={diaSemana} key={i} />
        ))}
      </div>
    </Styles.Calendario>
  )
}

const Semana = ({ diaSemana }) => {
  return (
    <div className="semana">
      <Styles.DiaSemanaContainer>{format(diaSemana, 'dd/MM')}</Styles.DiaSemanaContainer>
      {HORARIOS.map((h) => (
        <Horario key={h} diaSemana={diaSemana} horario={h} />
      ))}
    </div>
  )
}

const Horario = ({ diaSemana, horario }) => {
  const { agendamentos, qtdInstaladores } = useContext(ctxAgendamentos)
  const history = useHistory()
  const indisponivel = !_isDisponivel(diaSemana, horario, agendamentos, qtdInstaladores)
  const location = useLocation()
  const boleto = (location.state as any)?.boleto

  const diaSemanaExtenso = ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'][
    diaSemana.getDay()
  ]
  const diaMes = diaSemana.getDate()

  function handleClick() {
    if (indisponivel) return // setShowModal(true)
    Modal.confirm({
      title: 'Agendamento',
      content: (
        <>
          Confirma agendamento para <strong>{diaSemanaExtenso}</strong>, dia{' '}
          <strong>{diaMes}</strong> às <strong>{horario}</strong>
        </>
      ),
      onOk() {
        void agendar()
      },
    })
  }

  async function agendar() {
    try {
      const [hora, minuto] = horario.split(':')
      const dataAgendamento = setHours(setMinutes(diaSemana, minuto), hora)
      await Axios.post('/agendamento', { dataAgendamento, boletoId: boleto.Id }).then((x) => x.data)
      Modal.success({
        content:
          'Agendamento efetuado com sucesso. Dirija-se ao local de emplacamento na data e horário escolhidos',
        onOk: () => {
          history.goBack()
        },
      })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao efetuar o agendamento'
      Modal.error({ content: message })
    }
  }

  if (diaSemanaExtenso === 'domingo') return null
  if (diaSemanaExtenso === 'sábado') return null

  return (
    <Styles.Horario indisponivel={indisponivel} onClick={handleClick}>
      {horario}
    </Styles.Horario>
  )
}

function _isDisponivel(diaSemana, horario, agendamentos, qtdInstaladores) {
  if (format(diaSemana, 'yyyy-MM-dd') < format(new Date(), 'yyyy-MM-dd')) return false
  if (
    format(diaSemana, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd') &&
    horario < format(new Date(), 'HH:mm')
  )
    return false
  const data = format(diaSemana, 'yyyy-MM-dd') + ' ' + horario
  const qtdAgendamentosHorario = agendamentos.filter((x) => x === data).length
  if (qtdAgendamentosHorario >= qtdInstaladores) return false
  return true
}

function _getHorarios() {
  let horarioAtual = setMinutes(setHours(new Date(), 8), 0)
  const horarioFinal = setMinutes(setHours(new Date(), 17), 45)

  const horarios: string[] = []
  let i = 0
  while (horarioAtual <= horarioFinal) {
    i++
    if (i > 1000) return horarios
    horarios.push(format(horarioAtual, 'HH:mm'))
    horarioAtual = addMinutes(horarioAtual, DURACAO_ATENDIMENTO)
  }
  return horarios
}

const Styles = {
  Calendario: styled('div')`
    .semanas-container {
      display: flex;
    }
    .semana {
      flex: 1;
      &:not(:last-child) {
        border-right: 1px solid #ccc;
      }
    }
  `,
  Horario: styled('div')<{ indisponivel }>(
    (props) => `
    padding: 4px;
    border: 1px solid #1B5E20;
    border-radius: 4px;
    color: white;
    background: ${props.indisponivel ? '#bbb' : '#43A047'};
    text-align: center;
    margin: 2px 8px;
    ${
      !props.indisponivel
        ? `:hover {
        background: #81C784;
          cursor: pointer;
        }`
        : ''
    }
  `
  ),
  Container: styled('div')`
    padding-top: 4px;
    margin-bottom: 48px;
    .interno {
      background: #efefef;
      max-width: 1028px;
      margin: 0 auto;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      padding-bottom: 12px;
    }
    .titulo {
      padding: 12px;
    }
  `,
  DiaSemanaContainer: styled('div')`
    text-align: center;
    font-weight: bold;
  `,
  SeletorSemana: styled('div')`
    text-align: center;
    margin-bottom: 9px;
  `,
}

export { Agendamento }
