import axios from 'axios'

import { Cliente } from '../domains/clientes/Cliente'
import { Conta } from '../domains/contas/Conta'
import { Emplacador } from '../domains/emplacadores/Emplacadores'
import { Fornecedor } from '../domains/fornecedores/Fornecedor'
import { FornecedorServico } from '../domains/fornecedores/FornecedorServico'
import { Pedido } from '../domains/pedidos/Pedido'
import { Servico } from '../domains/servicos/Servico'
import { EmplacadorServico } from '../domains/emplacadores/EmplacadorServico'
import { getCurrentSite } from './getCurrentSite'

export const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3502'
const hyperpayApiUrl =
  process.env.REACT_APP_HYPERPAY_API_URL || 'https://hyperpay-api.azurewebsites.net'

const sistema = getCurrentSite()
axios.defaults.baseURL = apiUrl + '/emplacadores'
axios.defaults.headers.common = { 'x-api-token': 'fd51a982-e4a0-11e9-81b4-2a2ae2dbcce4', sistema }

export interface IQueryParams {
  order?: 'ASC' | 'DESC'
  orderBy?: string
  orderByEntity?: string
  offset?: number
  limit?: number
}

export interface ICounter<T> {
  count: number
  rows: T[]
}

const responseBody = (res: any) => {
  return res.data
}

const Usuarios = {
  login: async (usuario: string, senha: string): Promise<{ token: string }> =>
    await axios
      .post(`${hyperpayApiUrl}/usuarios/login`, {
        usuario,
        senha,
        cacheToken: '6419273d-ab40-4da7-982d-b81984f36a20',
      })
      .then((x) => x.data),
  getEmplacadores: async (usuarioId: number) =>
    await axios.get(`/usuarios/emplacadores/${usuarioId}`).then((x) => x.data),
  getUsuariosHipodromo: async (
    usuarioId: number
  ): Promise<{ isUsuarioHipodromo; isAdministradorHipodromo }> =>
    await axios.get(`/usuarios/hipodromo/${usuarioId}`).then((x) => x.data),
}

const Clientes = {
  create: async (dadosCliente): Promise<Cliente> =>
    await axios.post('/clientes', dadosCliente).then((x) => x.data),
}

const Servicos = {
  getAll: async (): Promise<Servico[]> => await axios.get('/servicos').then((x) => x.data),
  precos: async (emplacadorId: number): Promise<Servico[]> =>
    await axios.get(`/servicos/precos/${emplacadorId}`).then((x) => x.data),
  gravarPreco: async (
    servicoId: number,
    emplacadorId: number,
    valor: number
  ): Promise<EmplacadorServico> =>
    await axios.post('/servicos/precos/', { servicoId, emplacadorId, valor }).then((x) => x.data),
}

const Emplacadores = {
  getCidades: async (): Promise<string[]> => await axios.get('/cidades').then((x) => x.data),
  getByCidade: async (cidade): Promise<Emplacador[]> =>
    await axios.get('/', { params: { cidade } }).then((x) => x.data),
  updateFornecedor: async (emplacadorId, fornecedorId): Promise<Emplacador> =>
    await axios.put(`/fornecedor/${emplacadorId}`, { fornecedorId }).then((x) => x.data),
  getEmplacadoresConfigurarRodizioByCidade: async (cidade): Promise<Emplacador[]> =>
    await axios.get('/rodizio/configurar', { params: { cidade } }).then((x) => x.data),
  getCidadesRedirecionamento: async (): Promise<Array<{ origem; destino }>> =>
    await axios.get('/cidades-redirecionamento').then((x) => x.data),
  updateCidadesRedirecionamento: async (valor): Promise<any> =>
    await axios.put('/cidades-redirecionamento', { valor }).then((x) => x.data),
}

const Pedidos = {
  createPedido: async (props: {
    emplacadorId
    codigoAutorizacao
    dadosDetran
    dadosCliente
    servicos
    emplacadorLogado
    desconto
    vencimento
    localEmplacamento
  }): Promise<any> => await axios.post('/pedido', props).then((x) => x.data),
  getDadosDetranCodigoAutorizacao: async (
    codigoAutorizacao
  ): Promise<{ dadosDetran; pedidoExistente }> =>
    await axios.get(`/busca-dados-detran/${codigoAutorizacao}`).then((x) => x.data),
  getPedidos: async (emplacadorId, pagos): Promise<Pedido[]> =>
    await axios.get(`/pedidos/${emplacadorId}`, { params: { pagos } }).then((x) => x.data),
  getPedidosDespachantes: async (usuarioId, pagos): Promise<Pedido[]> =>
    await axios
      .get(`/pedidos/despachantes/${usuarioId}`, { params: { pagos } })
      .then((x) => x.data),
  getPedidosEstampados: async (
    emplacadorId,
    query
  ): Promise<{ rows: Pedido[]; total: number; count: number }> =>
    await axios.get(`/pedidos/${emplacadorId}/estampados`, { params: query }).then((x) => x.data),
  getPedidosEstampadorDespachantes: async (
    usuarioId,
    query
  ): Promise<{ rows: Pedido[]; total: number; count: number }> =>
    await axios
      .get(`/pedidos/despachantes/${usuarioId}/estampados`, { params: query })
      .then((x) => x.data),
  putDataEstampamento: async (pedidoId): Promise<any> =>
    await axios.put('/data-estampamento', { pedidoId }).then((x) => x.data),
  queryPedidos: async (props): Promise<Pedido[]> =>
    await axios.get('/pedidos/query', { params: props }).then((x) => x.data),
}

const Fornecedores = {
  getFornecedores: async (): Promise<Fornecedor[]> =>
    await axios.get('/fornecedores').then((x) => x.data),
  gravarServico: (
    servicoId: number,
    fornecedorId: number,
    valor: number,
    emplacadorId?: number
  ): void => {
    void axios
      .post(`/fornecedores/${fornecedorId}/servicos`, {
        servicoId,
        valor,
        emplacadorId,
      })
      .then((x) => x.data)
  },
  getServicos: async (fornecedorId, emplacadorId): Promise<FornecedorServico[]> =>
    await axios
      .get(`/fornecedores/${fornecedorId}/servicos`, { params: { emplacadorId } })
      .then((x) => x.data),
}

const Contas = {
  getContasUsuario: async (usuarioId): Promise<Conta[]> =>
    await axios
      .get(`/contas/usuario/${usuarioId}`, {
        headers: { authorization: `Bearer ${sessionStorage.getItem('token')}` },
      })
      .then((x) => x.data),
  getContasByCidade: async (cidade): Promise<Conta[]> =>
    await axios.get('/contas/cidade', { params: { cidade } }).then((x) => x.data),
  getContasHipodromo: async (): Promise<number[]> =>
    await axios.get('/contas/hipodromo').then((x) => x.data),
  getDespachantesParceirosCapitalPlacas: async (): Promise<number[]> =>
    await axios.get('/despachantes-parceiros-capital-placas').then((x) => x.data),
}

const Despachantes = {
  getDespachantes: async () => await axios.get('/despachantes').then((x) => x.data),
  createDespachante: async (despachante) =>
    await axios.post('/despachantes', despachante).then((x) => x.data),
  updateEmplacador: async (despachanteId, emplacadorId) =>
    await axios
      .put('/despachantes/emplacador', { despachanteId, emplacadorId })
      .then((x) => x.data),
  updateChavePix: async ({ despachanteId, chavePix, cpfCnpjChavePix }) =>
    await axios
      .put('/despachantes/chave-pix', { despachanteId, chavePix, cpfCnpjChavePix })
      .then((x) => x.data),
}

export default {
  Usuarios,
  Clientes,
  Emplacadores,
  Servicos,
  Pedidos,
  Fornecedores,
  Contas,
  Despachantes,
}
