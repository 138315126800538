import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../common/axios'
import { IRequest } from '../../common/IRequest'
import * as _ from 'lodash'
import { Fornecedor } from './Fornecedor'

export interface IState {
  request: IRequest
  fornecedorSelecionadoId: number | null
  emplacadorSelecionadoId: number | null
  fornecedores: Fornecedor[]
  atualizadoSucesso: boolean
  servicoSalvo: boolean
}

export const initialState: IState = {
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
  fornecedorSelecionadoId: null,
  emplacadorSelecionadoId: null,
  fornecedores: [],
  atualizadoSucesso: false,
  servicoSalvo: false,
}

const reducers = {
  requestStarted(state: IState) {
    state.request.fetching = true
  },
  requestErro(state: IState, { payload }: PayloadAction<{ message }>) {
    state.request = {
      errorCode: 400,
      message: payload.message,
      fetching: false,
    }
  },
  setMessage(state: IState, { payload }: PayloadAction<{ message }>) {
    state.request.message = payload.message
  },
  setFornecedorSelecionadoId(state: IState, { payload }: PayloadAction<{ fornecedorId }>) {
    state.fornecedorSelecionadoId = payload.fornecedorId
  },
  getFornecedoresSuccess(state: IState, { payload }: PayloadAction<{ fornecedores }>) {
    state.fornecedores = payload.fornecedores
    state.request.fetching = false
  },
  updateFornecedorSuccess(state: IState) {
    state.request.fetching = false
    state.atualizadoSucesso = true
  },
  resetAtualizadoSucesso(state: IState) {
    state.atualizadoSucesso = false
  },
  servicoSalvo(state: IState) {
    state.servicoSalvo = true
  },
  clearServicoSalvoMessage(state: IState) {
    state.request = {
      errorCode: 0,
      message: '',
      fetching: false,
    }
    state.servicoSalvo = false
  },
}

const { reducer: fornecedoresReducer, actions } = createSlice({
  name: 'fornecedores',
  initialState: initialState,
  reducers,
})

const fornecedoresActions = {
  ...actions,
  gravarServico(servicoId, fornecedorId, valor, emplacadorId?) {
    return async (dispatch) => {
      dispatch(actions.requestStarted())
      try {
        await axios.Fornecedores.gravarServico(servicoId, fornecedorId, valor, emplacadorId)
        dispatch(actions.servicoSalvo())
      } catch (error: any) {
        const message = _.get(error, 'response.data.message', 'Falha ao gravar o serviço')
        dispatch(actions.requestErro({ message }))
      }
    }
  },
  getFornecedores() {
    return async (dispatch) => {
      dispatch(actions.requestStarted())
      try {
        const fornecedores = await axios.Fornecedores.getFornecedores()
        dispatch(actions.getFornecedoresSuccess({ fornecedores }))
      } catch (error: any) {
        const message = _.get(error, 'response.data.message', 'Falha ao buscar os fornecedores')
        dispatch(actions.requestErro({ message }))
      }
    }
  },
  updateFornecedor() {
    return async () => {
      // DEIXA O CODIGO, TEM UMA ATIVIDADE PRA ISSO
      // const state = getState() as IStateRedux
      // const { emplacadorAtual } = state.auth
      // if (!emplacadorAtual) return dispatch(actions.setMessage({
      //   message: 'Emplacador não configurado para a conta, entre em contato com o suporte'
      // }))
      // dispatch(actions.requestStarted())
      // try {
      //   const emplacadorAtualizado = await axios.Emplacadores.updateFornecedor(emplacadorAtual.Id, fornecedorId)
      //   dispatch(actions.updateFornecedorSuccess())
      //   dispatch(authActions.setEmplacador({ emplacador: emplacadorAtualizado }))
      // } catch (error: any) {
      //   const message = _.get(error, 'response.data.message', 'Falha ao alterar o fornecedor')
      //   dispatch(actions.requestErro({ message }))
      // }
    }
  },
}

export { fornecedoresReducer, fornecedoresActions }
