import React from 'react'
import Axios from 'axios'
import { Pedido, PedidoServico } from '../Pedido'
import { Servico } from '../../servicos/Servico'
import { format as formatCpf } from '@fnando/cpf'
import { format as formatCnpj } from '@fnando/cnpj'
import { notification } from 'antd'

interface AutorizacaoEmplacamento {
  placa
  veiculo: {
    moto
    categoria?
    codMarcaModelo?
    descMarcaModelo?
    codgChassi?
    especieVeiculo?
    tipoVeiculo?
    tipoOficial?
    codCategoria?
    infoColecao?
    lacre?
    placaDianteira?
    placaTraseira?
    placaTerceira?
    tarjetaDianteira?
    tarjetaTraseira?
    tarjetaTerceira?
  }
  proprietario: {
    nome?
    cpfCnpj?
    pessoaFisica?
    codgDespachante?
    nomeDespachante?
  }
  servicos
  municipio: {
    codMunicipio?
    municipio?
    uf?
  }
  mercosul: boolean
  motivoEstampagem
  consultaOriginal
}

const initialState = {
  passoAtual: 1,
  autorizacaoEmplacamento: null as AutorizacaoEmplacamento | null,
  pedidoExistente: null as Pedido | null,
  dadosSolicitante: {
    tipoPessoa: 'FISICA',
    nome: '',
    cpfCnpj: '',
    telefone: '',
    localEmplacamento: '',
  },
  codigoAutorizacao: '',
  estado: 'GO',
  cidade: undefined as string | undefined,
  emplacadorSelecionadoId: null,
  createPedidoLoading: false,
  boleto: null,
  errorMessage: '',
}

type ACTIONS =
  | { type: 'alteraCodigoAutorizacao'; codigoAutorizacao }
  | { type: 'alteraPasso'; numero }
  | { type: 'successGetAutorizacao'; autorizacaoEmplacamento }
  | { type: 'pedidoExistente'; pedidoExistente }
  | { type: 'preencheDadosSolicitante'; dadosSolicitante }
  | { type: 'alteraEstado'; payload: { estado; cidade } }
  | { type: 'alteraCidade'; cidade }
  | { type: 'selecionaEmplacador'; emplacadorSelecionadoId }
  | { type: 'createPedidoStarted' }
  | { type: 'createPedidoErro'; error }
  | { type: 'createPedidoSuccess'; pedido }
  | { type: 'limpaErro' }

type IState = typeof initialState
type IDispatch = (p: ACTIONS) => void

function reducerFn(state: IState, action: ACTIONS): IState {
  switch (action.type) {
    case 'alteraCodigoAutorizacao':
      return {
        ...initialState,
        codigoAutorizacao: action.codigoAutorizacao,
      }
    case 'successGetAutorizacao': {
      const { proprietario, municipio } = action.autorizacaoEmplacamento

      const newState = {
        ...state,
        autorizacaoEmplacamento: action.autorizacaoEmplacamento,
      }

      if (proprietario?.cpfCnpj) {
        newState.dadosSolicitante = {
          cpfCnpj: formatCpfCnpj(proprietario.cpfCnpj),
          nome: proprietario.nome,
          telefone: '',
          localEmplacamento: '',
          tipoPessoa: proprietario.pessoaFisica ? 'FISICA' : 'JURIDICA',
        }
      }

      if (municipio?.municipio) {
        newState.cidade = municipio.municipio
      }
      return newState
    }
    case 'alteraPasso':
      return {
        ...state,
        passoAtual: action.numero,
      }
    case 'pedidoExistente':
      return {
        ...state,
        pedidoExistente: action.pedidoExistente,
        boleto: action.pedidoExistente.Boleto,
        passoAtual: 4,
      }
    case 'preencheDadosSolicitante':
      return {
        ...state,
        dadosSolicitante: action.dadosSolicitante,
        passoAtual: 3,
      }
    case 'alteraCidade':
      return {
        ...state,
        cidade: action.cidade,
        emplacadorSelecionadoId: null,
      }
    case 'alteraEstado':
      return {
        ...state,
        estado: action.payload.estado,
        cidade: action.payload.cidade,
        emplacadorSelecionadoId: null,
      }
    case 'selecionaEmplacador':
      return {
        ...state,
        emplacadorSelecionadoId: action.emplacadorSelecionadoId,
      }
    case 'createPedidoStarted':
      return {
        ...state,
        createPedidoLoading: true,
      }
    case 'createPedidoErro':
      return {
        ...state,
        createPedidoLoading: false,
        errorMessage: action.error,
      }
    case 'createPedidoSuccess':
      return {
        ...state,
        createPedidoLoading: false,
        pedidoExistente: action.pedido,
        passoAtual: 4,
      }
    case 'limpaErro':
      return {
        ...state,
        errorMessage: '',
      }
    default:
      return state
  }
}

/** Funtions */
async function createPedido(
  state: IState,
  dispatch: IDispatch,
  optionalParams: {
    valor?: number
    emplacadorLogado?: boolean
    despachanteLogado?: boolean
    usuarioId?: number
    tipo?: string
  }
) {
  const {
    emplacadorSelecionadoId,
    autorizacaoEmplacamento,
    codigoAutorizacao,
    cidade,
    dadosSolicitante,
  } = state

  const { valor, emplacadorLogado, despachanteLogado, usuarioId, tipo } =
    optionalParams ?? ({} as any)

  const dispatchError = (error) => dispatch({ type: 'createPedidoErro', error })
  if (!autorizacaoEmplacamento) return dispatchError('Autorização ainda não foi buscada')
  if (!emplacadorSelecionadoId) return dispatchError('Selecione um emplacador')

  try {
    dispatch({ type: 'createPedidoStarted' })
    const retornoCreatePedido = await Axios.post(
      '/pedido',
      {
        codigoAutorizacao,
        emplacadorId: emplacadorSelecionadoId,
        cidade,
        solicitante: dadosSolicitante,
        emplacadorLogado: !!emplacadorLogado,
        valor,
        usuarioId,
        tipo,
        despachanteLogado: !!despachanteLogado,
      },
      {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.token,
        },
      }
    ).then((x) => x.data)
    dispatch({
      type: 'createPedidoSuccess',
      pedido: retornoCreatePedido,
    })
  } catch (error: any) {
    const message = error.response?.data.message ?? 'Ocorreu um erro ao criar o Pedido'
    dispatch({ type: 'createPedidoErro', error: message })
    notification.error({ message })
  }
}

function getValorMaximo(pedidoServicos: PedidoServico[] | undefined, servicos: Servico[], estado) {
  if (!pedidoServicos || !servicos) return null
  const servico = servicos.find((s) => s.Id === pedidoServicos[0].Servico_Id)
  return servico?.EstadosServicos.find((e) => e.UF === estado)?.ValorTeto
  // const servicosPresentes = servicos.filter(servico => {
  //   return pedidoServicos.some(pedidoServico => pedidoServico.Servico_Id === servico.Id)
  // })
  // // return servicosPresentes.reduce((prev, curr) => prev + curr.ValorPadrao, 0) // + 5
  // return servicosPresentes.reduce((prev, curr) => prev + 1000000, 0) // + 5
}

/** Providers */
const StateContext = React.createContext(initialState)
const DispatchContext = React.createContext<IDispatch>(undefined as any)

function NovoPedidoProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducerFn, initialState)

  // useEffect(() => {
  //   console.log({ state })
  // }, [state])

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  )
}
function useNovoPedidoState() {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('useNovoPedidoState must be used within a NovoPedidoProvider')
  }
  return context
}
function useNovoPedidoDispatch() {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('useNovoPedidoDispatch must be used within a NovoPedidoProvider')
  }
  return context
}

const GetState = ({ children }: { children: (state) => JSX.Element }) => {
  const s = useNovoPedidoState()
  return children(s)
}

export {
  NovoPedidoProvider,
  useNovoPedidoDispatch,
  useNovoPedidoState,
  createPedido,
  getValorMaximo,
  GetState,
}

function formatCpfCnpj(cpfCnpj: string) {
  if (!cpfCnpj) return cpfCnpj
  const _cpfCnpj = ('' + cpfCnpj).replace(/[/.-]/g, '')
  if (_cpfCnpj.length === 11) return formatCpf(_cpfCnpj)
  if (_cpfCnpj.length === 14) return formatCnpj(_cpfCnpj)
  return cpfCnpj
}
