import React, { useState } from 'react'
import useAxios from 'axios-hooks'
import styled from '@emotion/styled'
import Axios from 'axios'
import { Button, Popconfirm, notification } from 'antd'
import { TextFieldInput } from '../../common/TextField'
import { IStateRedux } from '../../common/ReduxStore'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { getUsuarioLogado } from '../auth/AuthStore'

export const Hipodromo = () => {
  const isAdministradorHipodromo = useSelector((s: IStateRedux) => s.auth.isAdministradorHipodromo)
  const usuarioLogado = useSelector(getUsuarioLogado)
  const [, setLoadingFull] = useState(false)
  const [cpf, setCpf] = useState('')
  const [{ data, loading, error }, refetch] = useAxios('/usuarios/hipodromo')

  async function handleDelete(usuarioId) {
    setLoadingFull(true)
    await Axios.delete('/usuarios/hipodromo', { data: { usuarioId } })
    setLoadingFull(true)
    void refetch()
  }

  async function handleClickAdicionarUsuario() {
    setLoadingFull(true)
    try {
      const retorno = await Axios.post('/usuarios/hipodromo', { cpf }).then((x) => x.data)
      if (retorno.success) {
        setLoadingFull(true)
        void refetch()
        setCpf('')
        return notification.success({ message: 'Usuário criado com sucesso' })
      }
      setLoadingFull(true)
      return notification.success({ message: retorno.message })
    } catch (error: any) {
      notification.error({ message: 'Ocorreu um erro ao criar o usuário' })
    }
  }

  if (!isAdministradorHipodromo && (!usuarioLogado || !usuarioLogado.Admin)) {
    notification.error({ message: 'Você não tem permissão para acessar essa tela' })
    return <Redirect to="/" />
  }

  return (
    <div style={{ margin: '0 auto', maxWidth: 1024, paddingTop: 12 }}>
      <div style={{ display: 'flex', marginBottom: 12 }}>
        <div style={{ flex: 1, marginRight: 12 }}>
          <TextFieldInput
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            mask="cpf"
            size="large"
            placeholder="Informe o CPF do usuário"
          />
        </div>
        <Button size="large" type="primary" onClick={handleClickAdicionarUsuario}>
          Adicionar Usuário
        </Button>
      </div>

      <Lista loading={loading} error={error} data={data} onDelete={handleDelete} />
    </div>
  )
}

function Lista({ loading, error, data, onDelete }) {
  if (loading) return <div>Carregando ...</div>
  if (error) return <div>Ocorreu um erro ao buscar os dados</div>
  const usuariosLista = data.map((usuario) => {
    return (
      <tr key={usuario.Id}>
        <td>{usuario.Id}</td>
        <td>{usuario.Nome}</td>
        <td>{usuario.CPF}</td>
        <td>
          <Popconfirm
            title="Tem certeza que deseja remover o usuário?"
            onConfirm={() => onDelete(usuario.Id)}
            okText="Sim"
            cancelText="Não"
            placement="leftTop"
          >
            <Button icon="delete" />
          </Popconfirm>
        </td>
      </tr>
    )
  })
  return (
    <Styles.Table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Nome</th>
          <th>CPF</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{usuariosLista}</tbody>
    </Styles.Table>
  )
}

const Styles = {
  Table: styled('table')`
    border-collapse: collapse;
    width: 100%;
    table,
    th,
    td {
      border: 1px solid #444;
      padding: 8px;
    }
    tr:hover {
      background-color: #f3f3f3;
      cursor: pointer;
    }
    tr:nth-child(even) {
      background-color: #f7f7f7;
    }
  `,
}
