import React from 'react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { IQueryParams } from '../../common/axios'
import { IRequest } from '../../common/IRequest'
import { Servico } from '../servicos/Servico'
import { createSelector } from 'reselect'
import { IStateRedux } from '../../common/ReduxStore'
import { Emplacador } from '../emplacadores/Emplacadores'
import notification from 'antd/es/notification'
import * as _ from 'lodash'
import { Pedido } from './Pedido'
import { emplacadorAtivoSelector } from '../contas/ContasStore'
import moment from 'moment'
import { format } from 'date-fns'

export interface IState {
  request: IRequest & {
    fetchingServicos
    fetchingEmplacadores
    creatingPedido
    fetchingDadosAutorizacaoDetran
    getPedidoByPlaca
  }
  codigoAutorizacao: string
  cidadeOriginal: string | undefined
  cidadeSelecionada: string | undefined
  dadosDetran: any
  servicos: Servico[]
  servicosMarcados: number[]
  emplacadores: Emplacador[]
  pedidoCriado: Pedido
  resetState: number
  emplacadorSelecionadoId: number | null
  emplacadorLogadoId: number | null
  desconto: number | null
  pedidos: Pedido[]
  pedidoInformandoEstampamento: number | null
  vencimento: string
  telefone: string
  localEmplacamento: string
  pedidoExibindoOrdemServico: Pedido | null
  pedidoExistente: Pedido | null
  cidadeOriginalPossuiEmplacadores: boolean
  exibirStatusOrdemServico: boolean
  pedidosRetornoQuery: Pedido[]
  pedidosEstampados: {
    pedidos: Pedido[]
    qtdPedidos: number | null
    valorTotalPedidos: number | null
    paginaAtual: number
    query: IQueryParams
  }
  currentStep:
    | ''
    | 'CARREGANDO-EMPLACADORES'
    | 'SELECIONA-EMPLACADOR'
    | 'CRIANDO-PEDIDO'
    | 'EFETUA-PAGAMENTO'
    | 'PEDIDO-PAGO'
    | 'PEDIDO-EXISTENTE'
    | 'ERRO'
}

export const initialState: IState = {
  request: {
    fetching: false,
    errorCode: null,
    message: '',
    creatingPedido: false,
    fetchingDadosAutorizacaoDetran: false,
    fetchingEmplacadores: false,
    fetchingServicos: false,
    getPedidoByPlaca: false,
  },
  codigoAutorizacao: '',
  dadosDetran: null,
  cidadeOriginal: undefined,
  cidadeSelecionada: undefined,
  servicos: [],
  servicosMarcados: [],
  emplacadores: [],
  pedidoCriado: {} as any,
  resetState: 0,
  emplacadorSelecionadoId: null,
  emplacadorLogadoId: null,
  desconto: null,
  pedidos: [],
  pedidoInformandoEstampamento: null,
  vencimento: moment().add(7, 'days').format('YYYY-MM-DD'),
  telefone: '',
  localEmplacamento: '',
  pedidoExibindoOrdemServico: null,
  pedidoExistente: null,
  cidadeOriginalPossuiEmplacadores: false,
  exibirStatusOrdemServico: false,
  pedidosRetornoQuery: [],
  pedidosEstampados: {
    pedidos: [],
    qtdPedidos: null,
    valorTotalPedidos: null,
    paginaAtual: 1,
    query: { order: 'DESC', orderBy: 'Data_Estampamento', limit: 15, offset: 0 },
  },
  currentStep: '',
}

const reducers = {
  requestStarted(state: IState, { payload }: PayloadAction<{ type? }>) {
    state.request[payload.type || 'fetching'] = true
  },
  requestError(state: IState, { payload }: PayloadAction<{ message; type? }>) {
    state.request[payload.type || 'fetching'] = false
    state.request.message = payload.message
  },
  setErrorMessage(state: IState, { payload }: PayloadAction<{ message }>) {
    state.request.message = payload.message
  },
  clearMessage(state: IState) {
    state.request.message = ''
  },
  setCodigoAutorizacao(state: IState, { payload }: PayloadAction<{ codigoAutorizacao }>) {
    return {
      ...initialState,
      servicos: state.servicos,
      codigoAutorizacao: payload.codigoAutorizacao,
    }
  },
  fetchServicosSuccess(state: IState, { payload }: PayloadAction<{ servicos }>) {
    state.servicos = payload.servicos
  },
  fetchEmplacadoresStarted(state: IState) {
    state.currentStep = 'CARREGANDO-EMPLACADORES'
  },
  fetchEmplacadoresError(state: IState, { payload }: PayloadAction<{ message }>) {
    state.request.message = payload.message
    state.currentStep = 'ERRO'
  },
  fetchEmplacadoresSuccess(state: IState, { payload }: PayloadAction<{ emplacadores }>) {
    state.emplacadores = payload.emplacadores
    state.currentStep = 'SELECIONA-EMPLACADOR'
    const emplacadoresOrdenados = emplacadoresSelector({ pedidos: state } as any)
    if (emplacadoresOrdenados.length) state.emplacadorSelecionadoId = emplacadoresOrdenados[0].Id
  },
  createPedidoStarted(state: IState) {
    state.currentStep = 'CRIANDO-PEDIDO'
    // state.request.creatingPedido = true
  },
  createPedidoError(state: IState, { payload }: PayloadAction<{ message }>) {
    state.currentStep = 'ERRO'
  },
  createPedidoSuccess(state: IState, { payload }: PayloadAction<{ pedido }>) {
    state.currentStep = 'EFETUA-PAGAMENTO'
    state.pedidoCriado = payload.pedido
  },
  resetState(state: IState): IState {
    return { ...initialState, servicos: state.servicos, resetState: Math.random(), currentStep: '' }
  },
  pagamentoFinalizado(state: IState): IState {
    return { ...initialState, servicos: state.servicos, resetState: Math.random(), currentStep: '' }
  },
  fetchDadosAutorizacaoDetranStarted(state: IState) {
    const request = { ...state.request, fetchingDadosAutorizacaoDetran: true }
    return {
      ...initialState,
      servicos: state.servicos,
      request,
      codigoAutorizacao: state.codigoAutorizacao,
    }
  },
  fetchDadosAutorizacaoDetranSuccess(state: IState, { payload }: PayloadAction<{ dadosDetran }>) {
    state.request.fetchingDadosAutorizacaoDetran = false
    state.dadosDetran = payload.dadosDetran
    state.servicosMarcados = payload.dadosDetran.servicosMarcados
    state.cidadeOriginal = payload.dadosDetran.municipio
    const _cidadeRedir = (cidadesRedirecionamento || []).find(
      (x) => x.origem === payload.dadosDetran.municipio
    )
    state.cidadeSelecionada = _cidadeRedir ? _cidadeRedir.destino : payload.dadosDetran.municipio
  },
  fetchDadosAutorizacaoDetranError(state: IState, { payload }: PayloadAction<{ message }>) {
    state.request.fetchingDadosAutorizacaoDetran = false
    // state.currentStep = 'ERRO'
    state.request.message = payload.message
  },
  selectEmplacador(state: IState, { payload }: PayloadAction<{ emplacadorId }>) {
    state.emplacadorSelecionadoId = payload.emplacadorId
  },
  setDesconto(state: IState, { payload }: PayloadAction<{ desconto }>) {
    state.desconto = payload.desconto
  },
  getPedidosSuccess(state: IState, { payload }: PayloadAction<{ pedidos }>) {
    const pedidosNovos = payload.pedidos.map((x) => x.Id)
    state.pedidos = [
      ...payload.pedidos,
      ...state.pedidos.filter((x) => !pedidosNovos.includes(x.Id)),
    ]
    state.request.fetching = false
  },
  getPedidosEmplacadosSuccess(state: IState, { payload }: PayloadAction<{ rows; total; count }>) {
    state.pedidosEstampados.pedidos = payload.rows
    state.pedidosEstampados.qtdPedidos = payload.count
    state.pedidosEstampados.valorTotalPedidos = payload.total
    state.request.fetching = false
  },
  informarEstampamentoStarted(state: IState, { payload }: PayloadAction<{ pedidoId }>) {
    state.pedidoInformandoEstampamento = payload.pedidoId
  },
  informarEstampamentoSuccess(state: IState, { payload }: PayloadAction<{ pedidoId }>) {
    state.pedidoInformandoEstampamento = null
    if (state.pedidoExibindoOrdemServico) {
      state.pedidoExibindoOrdemServico.Data_Estampamento = format(new Date(), 'yyyy-MM-dd hh:mm')
    } else {
      state.pedidoExibindoOrdemServico = state.pedidos.find((x) => x.Id === payload.pedidoId)!
    }
    state.pedidos = state.pedidos.filter((x) => x.Id !== payload.pedidoId)
  },
  informarEstampamentoError(state: IState, { payload }: PayloadAction<{ pedidoId; message }>) {
    state.pedidoInformandoEstampamento = null
    state.request.message = payload.message
  },
  getPedidosStarted(state: IState) {
    state.request.fetching = true
  },
  setPedidoExibirOrdemServico(state: IState, { payload }: PayloadAction<{ pedido }>) {
    state.pedidoExibindoOrdemServico = payload.pedido
    state.exibirStatusOrdemServico = false
  },
  setPedidoExibirOrdemServicoQuery(state: IState, { payload }: PayloadAction<{ pedido }>) {
    state.pedidoExibindoOrdemServico = payload.pedido
    state.exibirStatusOrdemServico = true
    state.pedidosRetornoQuery = []
  },
  setTelefone(state: IState, { payload }: PayloadAction<{ telefone }>) {
    state.telefone = payload.telefone
  },
  setVencimento(state: IState, { payload }: PayloadAction<{ vencimento }>) {
    state.vencimento = payload.vencimento
  },
  setLocalEmplacamento(state: IState, { payload }: PayloadAction<{ localEmplacamento }>) {
    state.localEmplacamento = payload.localEmplacamento
  },
  setPedidoExistente(state: IState, { payload }: PayloadAction<{ pedidoExistente }>) {
    state.pedidoExistente = payload.pedidoExistente
    state.pedidoCriado = payload.pedidoExistente
    state.currentStep = payload.pedidoExistente.Boleto.Pago ? 'PEDIDO-PAGO' : 'PEDIDO-EXISTENTE'
    state.request.fetchingDadosAutorizacaoDetran = false
  },
  getPedidoByPlacaSuccess(state: IState, { payload }: PayloadAction<{ pedido }>) {
    state.pedidoExibindoOrdemServico = payload.pedido
    state.exibirStatusOrdemServico = true
    state.request.getPedidoByPlaca = false
  },
  setCidadeOriginalPossuiEmplacadores(
    state: IState,
    { payload }: PayloadAction<{ possuiEmplacadores }>
  ) {
    state.cidadeOriginalPossuiEmplacadores = payload.possuiEmplacadores
  },
  clearPedidos(state: IState) {
    state.pedidos = []
  },
  setPaginaAtual(state: IState, { payload }: PayloadAction<{ paginaAtual }>) {
    state.pedidosEstampados.paginaAtual = payload.paginaAtual
    state.pedidosEstampados.query.offset =
      (payload.paginaAtual - 1) * state.pedidosEstampados.query.limit!
  },
  queryPedidosSuccessSigle(state: IState, { payload }: PayloadAction<{ pedido }>) {
    state.pedidoExibindoOrdemServico = payload.pedido
    state.request.getPedidoByPlaca = false
    state.exibirStatusOrdemServico = true
  },
  queryPedidosSuccessMultiple(state: IState, { payload }: PayloadAction<{ pedidos }>) {
    state.pedidosRetornoQuery = payload.pedidos
    state.request.getPedidoByPlaca = false
  },
  closePedidosRetornoQuery(state: IState) {
    state.pedidosRetornoQuery = []
  },
  encerraCadastroPedido(state: IState) {
    state.currentStep = ''
    state.codigoAutorizacao = ''
    state.dadosDetran = null
  },
  setCidadeSelecionada(state: IState, { payload }: PayloadAction<{ cidade }>) {
    state.cidadeSelecionada = payload.cidade
  },
  changeCidadeDadosDetran(state: IState, { payload }: PayloadAction<{ cidade }>) {
    state.dadosDetran.municipio = payload.cidade
    state.cidadeSelecionada = payload.cidade
  },
}

const { reducer: pedidosReducer, actions } = createSlice({
  name: 'pedidos',
  initialState: initialState,
  reducers,
})

const pedidosActions = {
  ...actions,
  getServicos() {
    return async (dispatch) => {
      try {
        const servicos = await axios.Servicos.getAll()
        dispatch(actions.fetchServicosSuccess({ servicos }))
      } catch (error: any) {}
    }
  },
  getEmplacadores() {
    return async (dispatch, getState) => {
      const state = getState() as IStateRedux
      dispatch(actions.fetchEmplacadoresStarted())
      const cidade = state.pedidos.cidadeSelecionada
      try {
        const emplacadores = await axios.Emplacadores.getByCidade(cidade)
        emplacadores.forEach((x) => {
          x.RandomOrder = Math.random()
        })
        dispatch(actions.fetchEmplacadoresSuccess({ emplacadores }))
        if (state.pedidos.dadosDetran.municipio === cidade) {
          dispatch(
            actions.setCidadeOriginalPossuiEmplacadores({ possuiEmplacadores: emplacadores.length })
          )
        }
      } catch (error: any) {
        const message = _.get(error, 'response.data.message', 'Falha ao buscar os emplacadores')
        dispatch(actions.fetchEmplacadoresError({ message }))
      }
    }
  },
  createPedido() {
    return async (dispatch, getState) => {
      const state = getState() as IStateRedux
      const {
        emplacadores,
        emplacadorSelecionadoId,
        codigoAutorizacao,
        dadosDetran,
        servicosMarcados: servicos,
        desconto,
        telefone,
        vencimento,
        localEmplacamento,
      } = state.pedidos

      let emplacadorId
      const emplacadorAtivoId = emplacadorAtivoSelector(state)
      if (emplacadorAtivoId) {
        emplacadorId = emplacadorAtivoId
      } else {
        if (!emplacadores || !emplacadores.length)
          return notification.open({ message: 'Atenção', description: 'Emplacador não informado' })
        const emplacador = emplacadores.find((x) => x.Id === emplacadorSelecionadoId)
        emplacadorId = emplacador ? emplacador.Id : null
      }

      if (!emplacadorId)
        return notification.open({ message: 'Atenção', description: 'Emplacador não informado' })

      dispatch(actions.createPedidoStarted())
      try {
        const dadosCliente = {
          cpfCnpj: dadosDetran.cpfCnpjProprietario,
          nomeFantasia: dadosDetran.nomeProprietario,
          telefone,
        }
        const pedido = await axios.Pedidos.createPedido({
          emplacadorId,
          codigoAutorizacao,
          dadosDetran,
          dadosCliente,
          servicos,
          emplacadorLogado: !!emplacadorAtivoId,
          desconto,
          vencimento,
          localEmplacamento,
        })
        dispatch(actions.createPedidoSuccess({ pedido }))
      } catch (error: any) {
        const message = _.get(error, 'response.data.message', 'Falha ao tentar cadastrar o pedido')
        dispatch(actions.createPedidoError({ message }))
      }
    }
  },
  getDadosAutorizacaoDetran(codigoAutorizacao) {
    return async (dispatch, getState) => {
      const message = 'Informe o código de autorização'
      if (!codigoAutorizacao) return dispatch(actions.setErrorMessage({ message }))
      dispatch(actions.fetchDadosAutorizacaoDetranStarted())
      try {
        const { dadosDetran, pedidoExistente } =
          await axios.Pedidos.getDadosDetranCodigoAutorizacao(codigoAutorizacao)
        if (pedidoExistente) return dispatch(actions.setPedidoExistente({ pedidoExistente }))
        return dispatch(actions.fetchDadosAutorizacaoDetranSuccess({ dadosDetran }))
      } catch (error: any) {
        const message = _.get(
          error,
          'response.data.message',
          'Falha ao buscar os dados junto ao Detran'
        )
        dispatch(actions.fetchDadosAutorizacaoDetranError({ message }))
      }
    }
  },
  getPedidos({
    emplacadorId,
    usuarioId,
    pagos,
  }: {
    emplacadorId?: number
    usuarioId?: string
    pagos: boolean
  }) {
    return async (dispatch) => {
      dispatch(actions.getPedidosStarted())
      try {
        const pedidos = emplacadorId
          ? await axios.Pedidos.getPedidos(emplacadorId, pagos)
          : await axios.Pedidos.getPedidosDespachantes(usuarioId, pagos)
        dispatch(actions.getPedidosSuccess({ pedidos }))
      } catch (error: any) {
        const message = _.get(error, 'response.data.message', 'Falha ao buscar pedidos')
        dispatch(actions.setErrorMessage({ message }))
      }
    }
  },
  getPedidosEstampados({ emplacadorId, usuarioId }: { emplacadorId?; usuarioId? }) {
    return async (dispatch, getState) => {
      const state = getState() as IStateRedux
      const query = state.pedidos.pedidosEstampados.query
      dispatch(actions.requestStarted({}))
      try {
        const retorno = emplacadorId
          ? await axios.Pedidos.getPedidosEstampados(emplacadorId, query)
          : await axios.Pedidos.getPedidosEstampadorDespachantes(usuarioId, query)
        dispatch(actions.getPedidosEmplacadosSuccess(retorno))
      } catch (error: any) {
        const message = _.get(error, 'response.data.message', 'Falha ao buscar pedidos')
        dispatch(actions.setErrorMessage({ message }))
      }
    }
  },
  informarEstampamento(pedidoId) {
    return async (dispatch) => {
      dispatch(actions.informarEstampamentoStarted({ pedidoId }))
      try {
        await axios.Pedidos.putDataEstampamento(pedidoId)
        dispatch(actions.informarEstampamentoSuccess({ pedidoId }))
      } catch (error: any) {
        const message = _.get(error, 'response.data.message', 'Falha ao informar estampamento')
        dispatch(actions.informarEstampamentoError({ pedidoId, message }))
      }
    }
  },
  queryPedidos(queryData) {
    return async (dispatch, getState) => {
      dispatch(actions.requestStarted({ type: 'getPedidoByPlaca' }))
      try {
        const pedidos = await axios.Pedidos.queryPedidos(queryData)
        if (!pedidos || !pedidos.length) {
          return dispatch(
            actions.requestError({
              message: 'Nenhum pedido encontrado com os parâmetros informados',
              type: 'getPedidoByPlaca',
            })
          )
        }

        if (pedidos.length === 1)
          return dispatch(actions.queryPedidosSuccessSigle({ pedido: pedidos[0] }))
        return dispatch(actions.queryPedidosSuccessMultiple({ pedidos }))
      } catch (error: any) {
        const message = _.get(error, 'response.data.message', 'Falha ao informar estampamento')
        dispatch(actions.requestError({ message, type: 'getPedidoByPlaca' }))
      }
    }
  },
}

export const servicosMarcadosSelector = createSelector(
  (s: IStateRedux) => s.pedidos.servicos,
  (s: IStateRedux) => s.pedidos.servicosMarcados,
  (servicos, servicosMarcados) => servicos.filter((s) => servicosMarcados.includes(s.Id))
)

export const valorTotalSelector = createSelector(
  servicosMarcadosSelector,
  (s: IStateRedux) => s.pedidos.desconto,
  (servicos, desconto) => {
    // const valorTotal = servicos.reduce((prev, curr) => prev + curr.ValorPadrao, 0)
    const valorTotal = servicos.reduce((prev, curr) => prev + 10, 0)
    if (!desconto) return valorTotal
    return valorTotal - (valorTotal * desconto) / 100
  }
)

export const emplacadoresSelector = createSelector(
  (s: IStateRedux) => s.pedidos,
  ({ servicos, servicosMarcados, emplacadores }) => {
    return emplacadores
      .map((x) => {
        const valor = x.EmplacadoresServicos.filter((es) =>
          servicosMarcados.includes(es.Servico_Id)
        ).reduce((prev, curr) => prev + curr.Valor, 0)

        // servicos marcados que nao tem na emplacadores servicos
        const valorServicosNaoConfiguradosValor = servicos
          .filter((s) => servicosMarcados.includes(s.Id))
          .filter((s) => !x.EmplacadoresServicos.map((es) => es.Servico_Id).includes(s.Id))
          // .reduce((prev, curr) => prev + curr.ValorPadrao, 0)
          .reduce((prev, curr) => prev + 100000000, 0)

        return { ...x, Valor: valor + valorServicosNaoConfiguradosValor }
      })
      .sort((a, b) => {
        if (a.Valor === b.Valor) return a.RandomOrder! > b.RandomOrder! ? 1 : -1
        return a.Valor > b.Valor ? 1 : -1
      })
  }
)

export const pedidosPagosSelector = createSelector(
  (s: IStateRedux) => s.pedidos.pedidos,
  (pedidos) =>
    pedidos
      .filter((x) => x.Boleto.Pago && !x.Data_Estampamento)
      .sort((a, b) => {
        if (a.Data_Agendamento || b.Data_Agendamento) {
          return a.Data_Agendamento > b.Data_Agendamento ? 1 : -1
        }
        return a.Boleto.DataPgto < b.Boleto.DataPgto ? 1 : -1
      })
)

export const pedidosPendentesSelector = createSelector(
  (s: IStateRedux) => s.pedidos.pedidos,
  (pedidos) =>
    pedidos
      .filter((x) => !x.Boleto.Pago)
      .sort((a, b) => (a.Boleto.DataHora > b.Boleto.DataHora ? 1 : -1))
)

export const pedidosEstampadosSelector = createSelector(
  (s: IStateRedux) => s.pedidos.pedidos,
  (pedidos) =>
    pedidos
      .filter((x) => x.Data_Estampamento)
      .sort((a, b) => (a.Data_Estampamento < b.Data_Estampamento ? 1 : -1))
)

export const selectCidade = createSelector(
  (s: IStateRedux) => s.pedidos,
  (pedidos) => {
    const cidadeOriginalRedirecionamento = cidadesRedirecionamento.find(
      (x) => x.origem === pedidos.cidadeOriginal
    )
    const cidadeSelecionadaRedirecionamento = cidadesRedirecionamento.find(
      (x) => x.origem === pedidos.cidadeSelecionada
    )
    const cidadeOriginal = cidadeOriginalRedirecionamento
      ? cidadeOriginalRedirecionamento.destino
      : pedidos.cidadeOriginal
    const cidadeSelecionada = cidadeSelecionadaRedirecionamento
      ? cidadeSelecionadaRedirecionamento.destino
      : pedidos.cidadeSelecionada
    return { cidadeOriginal, cidadeSelecionada }
  }
)

let cidadesRedirecionamento: Array<{ origem; destino }>
void (async () => {
  cidadesRedirecionamento = await axios.Emplacadores.getCidadesRedirecionamento()
  localStorage.setItem('cidadesRedirecionamento', JSON.stringify(cidadesRedirecionamento))
})()

export { pedidosReducer, pedidosActions }
